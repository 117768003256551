// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from 'immutable'
import { assign } from 'lodash'
import { INIT } from '../../constants/phase'

import * as api from './api'

/***********************************
 * Action Types
 ***********/

export const GET_ORDER_DETAIL = 'ringc/order/GET_ORDER_DETAIL'

export const RETURN_REQUEST = 'ringc/order/RETURN_REQUEST'

export const REPAIR_REQUEST = 'ringc/order/REPAIR_REQUEST'

export const EXCHANGE_REQUEST = 'ringc/order/EXCHANGE_REQUEST'

export const UPLOAD_IMAGE = 'ringc/order/UPLOAD_IMAGE'

export const UPDATE_SHIPPING_ADDRESS = 'ringc/order/UPDATE_SHIPPING_ADDRESS'

export const GENERATE_ID = 'ringc/order/GENERATE_ID'

export const ADD_PRODUCT = 'ringc/order/ADD_PRODUCT'

export const ADD_PAYMENT_METHOD = 'ringc/order/ADD_PAYMENT_METHOD'

export const ADD_PAYMENT = 'ringc/order/ADD_PAYMENT'

export const CALCULATE_REFUND = 'ringc/order/CALCULATE_REFUND'

export const LOGIN = 'ringc/order/LOGIN'

export const PACKING_SLIP = 'ringc/order/PACKING_SLIP'

export const MERGE_SLIP_LABEL = 'ringc/order/MERGE_SLIP_LABEL'

export const GET_PRODUCT_DETAILS = 'ringc/order/GET_PRODUCT_DETAILS'

export const CHECK_INVENTORY_TRACK = 'ringc/order/CHECK_INVENTORY_TRACK'

export const CALCULATE_TAX = 'ringc/order/CALCULATE_TAX'

export const CONFIRM_SIZE = 'ringc/order/CONFIRM_SIZE'

export const  CHECK_ADDRESS_VALIDATION = 'ringc/order/CHECK_ADDRESS_VALIDATION'

 /***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  token: null,
  phase: INIT,
  userPhase: INIT,
  user: {},
  error: null,
  message: null,
}

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = '' // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues))
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function (state = new InitialState(), action = {}) {
  switch (action.type) {

    default: {
      return state
    }
  }
}


/***********************************
 * Action Creators
 ***********/

export const getOrderDetail = (data) => ({
  type: GET_ORDER_DETAIL,
  payload: api.getOrderDetail(data)
})

export const returnRequest = (data) => ({
  type: RETURN_REQUEST,
  payload: api.returnRequest(data)
})

export const exchangeRequest = (data) => ({
  type: EXCHANGE_REQUEST,
  payload: api.repairRequest(data)
})

export const repairRequest = (data) => ({
  type: REPAIR_REQUEST,
  payload: api.repairRequest(data)
})

export const uploadImage = (data) => ({
  type: UPLOAD_IMAGE,
  payload: api.uploadImage(data)
})

export const updateShippingAddress = (data) => ({
  type: UPDATE_SHIPPING_ADDRESS,
  payload: api.updateShippingAddress(data)
})

export const generateId = (data) => ({
  type: GENERATE_ID,
  payload: api.generateId(data)
})

export const addProduct = (data) => ({
  type: ADD_PRODUCT,
  payload: api.addProduct(data)
})

export const addPaymentMethod = (data) => ({
  type: ADD_PAYMENT_METHOD,
  payload: api.addPaymentMethod(data)
})

export const addPayment = (data) => ({
  type: ADD_PAYMENT,
  payload: api.addPayment(data)
})

export const calculaterefund=(id, itemIds) => ({
  type: CALCULATE_REFUND,
  payload: api.calculaterefund(id, itemIds)
})

export const login = (data) => ({
  type: LOGIN,
  payload: api.login(data)
})
export const getPrintSlipShip = (data) => ({
  type: PACKING_SLIP,
  payload: api.getPrintSlipShip(data)
})

export const getMergeSlipLabel = (data) => ({
  type: MERGE_SLIP_LABEL,
  payload: api.getMergeSlipLabel(data)
})

export const fetchProductDetails = (id) => ({
  type: GET_PRODUCT_DETAILS,
  payload: api.fetchProductDetails(id),
})

export const checkInventoryTrack = (data) => ({
  type: CHECK_INVENTORY_TRACK,
  payload: api.checkInventoryTrack(data)
})

export const calculateTax = (data) => ({
  type: CALCULATE_TAX,
  payload: api.calculateTax(data)
})

export const confirmSize = (data) => ({
  type: CONFIRM_SIZE,
  payload: api.confirmSize(data)
})

export const checkAddressValidation = (data) => ({
  type: CHECK_ADDRESS_VALIDATION,
  payload: api.checkAddressValidation(data)
})