import React from "react"
import get from "lodash/get"
import { Link } from "react-router-dom"
import { BackIcon } from "../../../components/icons"

export default function ProductImage({ orderNumber, image }) {
  return (
    <>
      <div className="head-wrap head-wrap-space">
        <div className="head-wrap-back">
          <button type="button" className="btn btn-sm btn-back">
            <Link to={`/detail/${orderNumber}`}>
              <BackIcon />
            </Link>
          </button>
        </div>
        <div className="head-wrap-title">Select Action</div>
      </div>
      <div className="product-thumbnail">
        {get(image, "[0].image", "/default.webp") !== "" && (
          <img src={get(image, "[0].image", "/default.webp")} alt={""} />
        )}
      </div>
    </>
  )
}
