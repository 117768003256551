import { connect } from 'react-redux'
import { getOrderDetail, fetchProductDetails } from "../../store/order/duck"
import SelectActionComponent from './component'

const SelectActionContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
  	getOrderDetail,
  	fetchProductDetails
  }
)(SelectActionComponent)

export default SelectActionContainer
