import React, { PureComponent } from 'react'
import './styles.scss'
import _ from 'lodash'
import Loader from '../../components/loader'
import TopNavigation from '../TopNavigation/component'
import Cookies from 'js-cookie'

export default class HTOKLabelComponent extends PureComponent {

  static propTypes = {
    // PropTypes go here
  }

  constructor(props){
    super(props)
    this.state = {
      orderDetail: {},
      product: {},
      userName: '',
      isLoader: true
    }
  }

  componentDidMount = async() => {
    const data ={
      orderId: Cookies.get('orderId'),
      customerId: Cookies.get('customerId')
    }
    const { value } = await this.props.getOrderDetail(data)
    if(_.get(value,'status',false)){
      this.setState({
        orderDetail: _.get(value, 'data', {}),
        product: _.get(value, 'lItem', {}),
        userName: _.get(value, 'userName', {}),
        isLoader: false
      })
    } else{
      this.props.history.push('/no-page-found')
    }
  }

  openDownloadLink(trackings) {
    if(trackings.printSlipLink) {
      window.open(trackings.printSlipLink, '_newtab')
    }
  }

  render() {
    const { orderDetail, userName, product, isLoader } = this.state
    const image = _.filter(_.get(orderDetail, "images", []), filter => {
      return String(filter.id) === String(product.id);
    })
    const returnLabel = _.filter(_.get(orderDetail, "trackings", []), filter => {
      return _.includes(['outbound','shiporder','shipped'], filter.trackingStatus)
    })
    return (
      <>
        <div>
        {isLoader ? (
          <Loader />
        ) : (
          <div>
            <TopNavigation userName={userName} orderDetail={orderDetail} {...this.props}/>
            <div className="mini-container-wrapper">
              <div className="mini-container">
                <div className="label-heading">
                  <h5 className="label-heading-title">How to send us Your Home Try-On</h5>
                </div>
                <div className="mini-container-inner mb-4">
                {_.get(returnLabel,'[0].printSlipLink','') !== '' ?
                  <div className="label-content-information">
                    <h5>Your Label is Ready to Print</h5>
                    <p>This label was also sent to your email</p>
                    <button className="btn btn-dark w-100" onClick={this.openDownloadLink.bind(this, returnLabel[0])}>Download Return Label</button>
                  </div>
                : 
                  <div className="label-content-information">
                    <h5>Your Tracking Number</h5>
                    <p>{_.get(returnLabel,'[0].trackingNumber','')}</p>
                  </div>
                }
                  <ul className="label-options-list">
                    <li>
                      1. Find some secure packaging to place your Home try-on kit in. Ideally you can use the same package we shipped it to you in.
                    </li>
                    <li>
                      2. Remove any pre-existing labels from the packaging, print the above return label and attach it to the package
                    </li>
                    <li>
                      3. Drop off the package at your nearest FedEx location within 7 days of receiving the Home Try-On
                    </li>
                    <li>
                      4. Your credit is issued as soon as we receive your Home Try-On kit and ensure it is in it’s original state. Store credit is available to use immediately when it’s issued.
                    </li>
                  </ul>
                </div>
                <div className="label-heading">
                  <h5 className="label-heading-title">Home Try-On Your Sending Us</h5>
                </div>
                <div className="horizontal-product">
                  <div className="product-thumbnail">
                    {_.get(image, "[0].image", "/default.webp") !== "" && (
                      <img
                        src={_.get(image, "[0].image", "/default.webp")}
                        alt={""}
                      />
                    )}
                  </div>
                  <div>
                    <h4 className="product-name">{_.get(product,'title','')}</h4>
                    {/*<p className="product-shape-color">Emerald/ 14K Yellow Gold/ Hidden Halo </p>*/}
                  </div>
                </div>
              </div>
            </div>
        </div>
      )}
      </div>
      </>
    )
  }
}
