import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { BackIcon, CheckmarkIcon } from '../../../components/icons'
import _ from 'lodash'
import Loader from '../../../components/loader'
import TopNavigation from '../../TopNavigation/component'
import Cookies from 'js-cookie'


export default class CustomSizeConfirmationComponent extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      exampleModal: false,
      orderDetail: '',
      userName: '',
      isLoader: true,
      RCFJOrder: [],
      productId:'',
      variantTitle: ''
    }
  }

  async componentDidMount(){
    const data ={
      orderId: Cookies.get('orderId'),
      customerId: Cookies.get('customerId')
    }
    let productId = Cookies.get('productId')
    let variantTitle = Cookies.get('variantTitle')
    let RCFJOrder = []
    const { value } = await this.props.getOrderDetail(data)
    if(_.get(value,'status',false)){
      if(!_.includes([null,undefined,'null',''], productId)){
        let orderDetail = _.get(value, 'data', {})
        RCFJOrder = _.find(_.get(orderDetail, "order.line_items", []), filter => {
          return String(filter.id) === String(productId);
        })
      }
      this.setState({ 
        orderDetail: _.get(value, 'data', {}), 
        userName: _.get(value, 'userName', {}), 
        isLoader: false, 
        RCFJOrder,
        productId,
        variantTitle
      })
    } else{
      this.props.history.push('/no-page-found')
    }
  }

  openDownloadLink(trackings){
    if(_.get(trackings,'printSlipLink','')) {
      window.open(trackings.printSlipLink, '_newtab')
    }
  }

  render() {
    const { orderDetail, userName, isLoader, RCFJOrder, productId, variantTitle } = this.state
    let image = _.filter(_.get(orderDetail, "images", []), filter => {
      return String(filter.id) === String(productId);
    })
    return (
      <>
        {isLoader ? (
          <Loader />
        ) : (
          <>
        <TopNavigation userName={userName} orderDetail={orderDetail} {...this.props}/>
        <div className="mini-container-wrapper">
          <div className="mini-container">
            <div className="head-wrap head-wrap-space">
              <div className="head-wrap-back">
                <button type="button" className="btn btn-sm btn-back" >
                  <Link to={`/detail/${_.get(orderDetail, 'order.order_number', '')}`}>
                    <BackIcon/>
                  </Link>
                </button>
              </div>
              <div className="head-wrap-title">
                Request
              </div>
            </div>
            <div className="mini-container-inner mb-4">
            <div className="overview-wrap">
                <h4>Your Order has been Placed successfully!</h4>
                <h6 className="product-generated-id">Order Number: {_.get(orderDetail,'order.order_number','')}</h6>
                <CheckmarkIcon className="overview-icon"/>
                <p>We have sent you an email with this info to <br/>{_.get(orderDetail,'order.email','')}</p>
              </div>
            </div>
            <div className="label-heading">
              <h5 className="label-heading-title">We’re Sending You </h5>
            </div>
            <div className="horizontal-product">
              <div className="product-thumbnail">
                {_.get(image, "[0].image", "/default.webp") !== "" && (
                  <img
                    src={_.get(image, "[0].image", "/default.webp")}
                    alt={""}
                  />
                )}
              </div>
              <div>
                <h4 className="product-name">{_.get(RCFJOrder,'title','')}</h4>
                <p className="product-shape-color">{variantTitle}</p>
              </div>
            </div>

          </div>
        </div>
      </>
        )}
      </>
    )
  }
}
