import React from "react"
import get from "lodash/get"
import { Link } from "react-router-dom"
import { CaretRightIcon } from "../../../components/icons"

export default function ProductAction({
  productType,
  openChat,
  orderDetail,
  orderNumber,
  product,
  title,
  repairActive,
  exchangeActive,
  returnActive,
  lostMyRingDisabled,
}) {
  return (
    <div>
      <h4 className="product-name">{get(product, "title", "")}</h4>
      <p className="product-shape-color">{get(title, "[1]", "")}</p>
      {productType !== "Lab Grown Engagement Rings" ? (
        <ul className="select-action-list">
          {get(orderDetail, "fulfillmentStatus", "") === "repairinitiated" ||
          get(orderDetail, "fulfillmentStatus", "") === "repairreceived" ? (
            <li>
              <Link to={`/repair/request-confirmation/${orderNumber}`}>
                <span className="select-action-title">Repair/Resize</span>{" "}
                <CaretRightIcon className="caret-icon" />
              </Link>
            </li>
          ) : (
            <li>
              <Link
                className={repairActive === false ? "disabled-link" : ""}
                to={`/repair/${orderNumber}`}
              >
                <span className="select-action-title">Repair/Resize</span>{" "}
                <CaretRightIcon className="caret-icon" />
              </Link>
            </li>
          )}
          {get(orderDetail, "fulfillmentStatus", "") === "returninitiated" ||
          get(orderDetail, "fulfillmentStatus", "") === "returnreceived" ? (
            <li>
              <Link
                className={exchangeActive === false ? "disabled-link" : ""}
                to={`/exchange/request-confirmation/${orderNumber}`}
              >
                <span className="select-action-title">Exchange</span>{" "}
                <CaretRightIcon className="caret-icon" />
              </Link>
            </li>
          ) : (
            <li>
              <Link
                className={exchangeActive === false ? "disabled-link" : ""}
                to={`/exchange/${orderNumber}`}
              >
                <span className="select-action-title">Exchange</span>{" "}
                <CaretRightIcon className="caret-icon" />
              </Link>
            </li>
          )}
          {get(orderDetail, "fulfillmentStatus", "") === "returninitiated" ||
          get(orderDetail, "fulfillmentStatus", "") === "returnreceived" ? (
            <li>
              <Link
                className={returnActive === false ? "disabled-link" : ""}
                to={`/return/request-confirmation/${orderNumber}`}
              >
                <span className="select-action-title">Return</span>{" "}
                <CaretRightIcon className="caret-icon" />
              </Link>
            </li>
          ) : (
            <li>
              <Link
                className={returnActive === false ? "disabled-link" : ""}
                to={`/return/${orderNumber}`}
              >
                <span className="select-action-title">Return</span>{" "}
                <CaretRightIcon className="caret-icon" />
              </Link>
            </li>
          )}
          <li>
            <Link
              className={lostMyRingDisabled === false ? "disabled-link" : ""}
              to={`/lost-ring/${orderNumber}`}
            >
              <span className="select-action-title">I lost my ring</span>{" "}
              <CaretRightIcon className="caret-icon" />
            </Link>
          </li>
          <li>
            <Link to="#" onClick={() => openChat()}>
              <span className="select-action-title">Other</span>{" "}
              <CaretRightIcon className="caret-icon" />
            </Link>
          </li>
        </ul>
      ) : (
        <ul className="select-action-list">
          <li>
            <Link onClick={() => openChat()}>
              <span className="select-action-title">Speak with a Specialist</span>{" "}
              <CaretRightIcon className="caret-icon" />
            </Link>
          </li>
        </ul>
      )}
    </div>
  )
}
