import React, { PureComponent } from 'react'
import { BackIcon } from '../../components/icons'
import './styles.scss'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import Loader from '../../components/loader'
// import config from '../../config/index'
import TopNavigation from '../TopNavigation/component'
import Cookies from 'js-cookie'
import './styles.scss'

export default class RcfjCustomSizeComponent extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      exampleModal: false,
      orderDetail: '',
      userName: '',
      isLoader: true,
      RCFJOrder:[],
      productId:'',
      newRingSize:'',
      variantOptions: [],
      count: true,
      variantName: '',
      inventoryItemId: '',
      variantId: '', 
      inventoryComment: '',
      totalQuantity: 0,
      selectedQuantity: 0,
    }
  }

  async componentDidMount(){
    const checkValidation = Cookies.get('checkValidation')
    if(checkValidation){
      const data ={
        orderId: Cookies.get('orderId'),
        customerId: Cookies.get('customerId')
      }
      let productId = Cookies.get('productId')
      let RCFJOrder = []; let variantOptions = [];
      let defaultSizeOption = []
      let variantName = '';
      let inventoryItemId = ''
      let newRingSize = ''
      const { value } = await this.props.getOrderDetail(data)
      if(_.get(value,'status',false)){
        if(!_.includes([null,undefined,'null',''], productId)){
          let orderDetail = _.get(value, 'data', {})
          RCFJOrder = _.find(_.get(orderDetail, "order.line_items", []), filter => {
            return String(filter.id) === String(productId);
          })
          let productID = _.get(RCFJOrder,'product_id','')
          const productDetail = await this.props.fetchProductDetails(productID)
          variantOptions = _.get(productDetail,'value.data.product.variants',[])
          defaultSizeOption = _.get(productDetail,'value.option.sizes',{})
          _.map(variantOptions, variant =>{
            if(_.get(variant,'title','') === _.get(RCFJOrder,'variant_title','')){
              inventoryItemId = _.get(variant,'inventory_item_id','')
              variantName = String(_.get(variant,'option1',''))
            }
          })
        }
        const properties = _.get(RCFJOrder, 'properties', [])
        // get all variant properties
        const sizeItem = _.find(properties, property => {
          return _.includes(['size'], `${_.get(property, 'name', '')}`.toLowerCase())
          && `${_.get(property, 'value', '')}`.toLowerCase().indexOf('i don\'t know my size; send me a ring sizing gauge') !== -1
        }) 
        // check properties type for man and women to show option
        const sizeType = _.find(properties, property => {
          return _.includes(['womensize_v_id', 'mensize_v_id'], `${_.get(property, 'name', '')}`.toLowerCase())
        })
        let sizes = []
        let newSizeType = ''
        if (sizeItem && sizeType) {
          // check size type and show option based on size type man and women
          if (_.get(sizeType, 'name', '') === 'womensize_v_id') newSizeType = 'womenSizes'
          else newSizeType = 'menSizes'
          // get size options to show size dropdown 
          sizes = _.get(defaultSizeOption , `${newSizeType}.product.variants`, [])
        }
        newRingSize = _.get(RCFJOrder, 'variant_id', '')
        // disable i don't know my size option from confirm size
        if (`${_.get(RCFJOrder, 'variant_title', '')}`.toLowerCase().indexOf('i don\'t know my size; send me a ring sizing gauge') !== -1) {
          newRingSize = ''
        }
        this.setState({ 
          orderDetail: _.get(value, 'data', {}), 
          userName: _.get(value, 'userName', {}), 
          isLoader: false, 
          RCFJOrder,
          productId,
          variantOptions,
          variantName,
          inventoryItemId,
          totalQuantity: _.get(RCFJOrder, 'quantity', 0),
          selectedQuantity: _.get(RCFJOrder, 'quantity', 0),
          sizes,
          defaultSizeOption,
          newSizeType,
          variantId: _.get(RCFJOrder, 'variant_id', ''),
          newRingSize
        })
      } else{
        this.props.history.push('/no-page-found')
      }
    } else{
      this.props.history.push('/no-page-found')
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.location.state && this.state.count){
      this.setState({
        RCFJOrder : _.get(prevProps,'location.state.detail.RCFJOrder',''),
        variantOptions : _.get(prevProps,'location.state.detail.variantOptions',[]),
        newRingSize : _.get(prevProps,'location.state.detail.newRingSize',''),
        variantName : _.get(prevProps,'location.state.detail.variantName',''),
        inventoryItemId : _.get(prevProps,'location.state.detail.inventoryItemId',''),
        variantId : _.get(prevProps,'location.state.detail.variantId',''),
        selectedQuantity : _.get(prevProps,'location.state.detail.selectedQuantity', 0),
        count:false
      })
      this.checkInventoryTrack(_.get(prevProps,'location.state.detail.variantId',''))
    }
  }

  async handleOnChange(e){
    this.checkInventoryTrack(e.target.value)
  }

  async checkInventoryTrack(newSize){
    const { variantOptions } = this.state
    this.setState({ inventoryComment: '' })
    let size = ''
    let variantDetail = ''
    _.map(variantOptions, variant =>{
      if(String(variant.id) === String(newSize)){
        size = variant.title
        variantDetail = variant
      }
    })
    this.setState({ 
      variantId: newSize,
      newRingSize: size  
    })
    const { value } = await this.props.checkInventoryTrack({ variantDetail })
    if(_.get(value,'status', false)){
      this.setState({ inventoryComment: _.get(value,'data', '') })
    }
  } 

  continueWithRequest(){
    const { orderDetail, defaultSize } = this.state
    const data = {
      RCFJOrder: this.state.RCFJOrder,
      newRingSize: this.state.newRingSize,
      variantOptions: this.state.variantOptions,
      variantName: this.state.variantName,
      inventoryItemId: this.state.inventoryItemId,
      variantId:this.state.variantId,
      selectedQuantity:this.state.selectedQuantity,
      defaultSize
    }
    this.props.history.push({
      pathname : `/confirm-size/overview/${_.get(orderDetail, 'order.order_number', '')}`,
      state : { detail : data }
    })
  }

  handleChangeQuantity(event){
    this.setState({ selectedQuantity: event.target.value })

  }

  changeDefaultSizeOption = (event, type) => {
    const { sizes } = this.state
    const { value } = event.target
    const size = _.find(sizes, variant => String(variant.id) === String(value))
    this.setState({
      defaultSize: { variantId: parseInt(value), type, size: _.get(size, 'title', '') }
    })
  } 

  render() {
    const { orderDetail, userName, isLoader, RCFJOrder, productId, variantOptions, inventoryComment, totalQuantity, sizes, defaultSize, newSizeType } = this.state
    let image = _.filter(_.get(orderDetail, "images", []), filter => {
      return String(filter.id) === String(productId);
    })
    let quantityOptions = []
    for (let i = 1; i <= totalQuantity; i++) { quantityOptions.push(i) }

    let variantIDs = []
    const alreadyExistsSize = []
    _.map(_.get(orderDetail, 'order.line_items', []), item => {
      if(_.get(item,'variant_id', 0)){
        variantIDs.push(_.get(item, 'variant_id', 0))
      }
      // create array to disable already exists size
      const size = _.find(_.get(item,'properties',''), property => {
        return ['size', 'Size'].includes(property.name)
      })
      if (size) {
        alreadyExistsSize.push(size.value)
      }
    })
    _.map(variantOptions, variant => {
      if (`${variant.title}`.toLowerCase().indexOf("i don't know my size; send me a ring sizing gauge!") !== -1) variantIDs.push(variant.id)
    })
    const variantIds = _.uniq(_.compact(variantIDs))

    // if size option unable then need to select size for the new variant
    const sizeSelected = _.size(sizes) ? String(_.get(defaultSize, 'variantId', '0')) === "0" ? false : true : true
    // disable button when required fields are blank
    const disabledSubmit = this.state.newRingSize !== "" ? sizeSelected ? false : true : true

    return (
      <>
        {isLoader ? (
          <Loader />
        ) : (
          <>
        <TopNavigation userName={userName} orderDetail={orderDetail} {...this.props}/>
        <div className="mini-container-wrapper">
          <div className="mini-container">
            <div className="head-wrap head-wrap-space">
              <div className="head-wrap-back">
                <button type="button" className="btn btn-sm btn-back">
                  <Link
                    to={`/select-action/${_.get(
                      orderDetail,
                      "order.order_number",
                      ""
                    )}`}
                  >
                    <BackIcon />
                  </Link>
                </button>
              </div>
              <div className="head-wrap-title">Custom Size</div>
            </div>
            <div className="horizontal-product">
              <div className="product-thumbnail">
                {_.get(image, "[0].image", "/default.webp") !== "" && (
                  <img src={_.get(image, "[0].image", "/default.webp")} alt={""} />
                )}
              </div>
              <div>
                <h4 className="product-name">{_.get(RCFJOrder, "title", "")}</h4>
                <p className="product-shape-color">
                  {_.get(RCFJOrder, "variant_title", "")}
                </p>
              </div>
            </div>
            <div className="mini-container-inner">
              <h5 className="section-title">Tell us what Size you need </h5>
              <div className="form-group">
                <label className="form-label">
                  {_.size(sizes) ? 'Select your Item type:': 'Select your New Size:'} 
                </label>
                <select
                  type="select"
                  className="form-control custom-select"
                  name="variantId"
                  value={this.state.variantId}
                  onChange={this.handleOnChange.bind(this)}
                >
                  <option value="">Select</option>
                  {_.map(variantOptions, (variant, i) => {
                    if(_.includes(variantIds, _.get(variant, 'id', '')) && !_.size(sizes)){
                      return(
                        <option key={i} value={variant.id} disabled={true}>{variant.title} </option>
                      )
                    } else{
                      return(
                        <option key={i} value={variant.id} >{variant.title}</option>
                      )
                    }
                  })}
                </select>
              </div>
             {_.size(sizes) ?  <div className="form-group">
                <label className="form-label">Select your New Size:</label>
                <select
                  type="select"
                  className="form-control custom-select"
                  name="size"
                  value={_.get(defaultSize, 'variantId', '0')}
                  onChange={(event) => this.changeDefaultSizeOption(event, newSizeType)}
                >
                  <option value="">Select</option>
                  {_.map(sizes, (size, i) => {
                    return (
                      <option
                        key={i}
                        value={size.id}
                        disabled={alreadyExistsSize.includes(size.title)}>
                        {size.title}{' '}
                      </option>
                    )
                  })}
                </select>
              </div> : '' }

              {totalQuantity > 1 ? (
                <div className="form-group">
                  <label className="form-label">Select your Quantity:</label>
                  <select
                    type="select"
                    className="form-control custom-select"
                    name="selectedQuantity"
                    value={this.state.selectedQuantity}
                    onChange={this.handleChangeQuantity.bind(this)}
                  >
                    {_.map(quantityOptions, (quantity, i) => {
                      return (
                        <option key={i} value={quantity}>
                          {quantity}
                        </option>
                      )
                    })}
                  </select>
                </div>
              ) : (
                ""
              )}
              {inventoryComment ? <p>{inventoryComment}</p> : ""}
              <button
                disabled={disabledSubmit}
                type="button"
                className="btn btn-dark w-100"
                onClick={this.continueWithRequest.bind(this)}
              >
                Continue with REQUEST
              </button>
            </div>
          </div>
        </div>
      </>
        )}
      </>
    )
  }
}
