import { connect } from 'react-redux'
import { getOrderDetail, fetchProductDetails, checkInventoryTrack } from "../../store/order/duck"
import RcfjCustomSizeComponent from './component'

const RcfjCustomSizeContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
  	getOrderDetail,
  	fetchProductDetails,
  	checkInventoryTrack
  }
)(RcfjCustomSizeComponent)

export default RcfjCustomSizeContainer
