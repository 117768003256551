import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { BackIcon, CheckmarkIcon } from '../../../components/icons'
import _ from 'lodash'
import Loader from '../../../components/loader'
import TopNavigation from '../../TopNavigation/component'
import Cookies from 'js-cookie'

export default class RepairConfirmationComponent extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      exampleModal: false,
      orderDetail: '',
      userName: '',
      isLoader: true,
      RCFJOrder: [],
      productId:'',
      shippingMethodType:'byMail'
    }
  }

  async componentDidMount(){
    const data ={
      orderId: Cookies.get('orderId'),
      customerId: Cookies.get('customerId')
    }
    let productId = Cookies.get('productId')
    let RCFJOrder = []
    const { value } = await this.props.getOrderDetail(data)
    if(_.get(value,'status',false)){
      if(!_.includes([null, undefined, 'null', ''], productId)){
        let orderDetail = _.get(value, 'data', {})
        RCFJOrder = _.find(_.get(orderDetail, "order.line_items", []), filter => {
          return String(filter.id) === String(productId);
        })
        // let RCFJItems = _.get(value, 'data.RCFJItems', [])
        // let variant_1 = _.get(RCFJOrder,'variant_title','').split(' / ')
        // if(confirmSize === _.get(variant_1,'[1]','') && RCFJItems && RCFJItems.length !== 0){
        //   RCFJOrder = _.get(RCFJItems,'[0]','')
        // }
      }
      this.setState({ 
        orderDetail: _.get(value, 'data', {}), 
        userName: _.get(value, 'userName', {}), 
        isLoader: false, 
        RCFJOrder,
        productId,
      })
    } else{
      this.props.history.push('/no-page-found')
    }
  }

  async openDownloadLink(trackings) {
    const { orderDetail } = this.state
    let lineItems =  _.get(trackings,'rcfjSelectedItems',[])
    if(lineItems && lineItems.length !== 0){
      this.setState({ isLoader: true })
      const { value } = await this.props.getPrintSlipShip({ values: 'repairLabel', id: orderDetail._id, orderId: _.get(orderDetail, 'order.id', ''), lineItems })
      // window.open(value.data, '_newtab')
      let urls = [_.get(value,'data',''),_.get(trackings,'printSlipLink','')]
      if(urls.length === 1){
        window.open(_.get(urls, '[0]', ''))
      } else{
        let URL = urls.reverse()
        let arrURL = []
        _.map(URL, (pdf,i) => {
          arrURL.push({ url:pdf, index:i })
        })
        const { value } = await this.props.getMergeSlipLabel({ urls: arrURL, id: orderDetail._id, orderId: _.get(orderDetail, 'order.id', '') })
        window.open(_.get(value, 'data', ''))
      }
      this.setState({ isLoader: false })
    } else {
      if(_.get(trackings,'printSlipLink','')) {
        window.open(trackings.printSlipLink, '_newtab')
      }
    }
  }

  render() {
    const { orderDetail, userName, isLoader, RCFJOrder, productId } = this.state
    let image = _.filter(_.get(orderDetail, "images", []), filter => {
      return String(filter.id) === String(productId);
    })
    const returnLabel = _.filter(_.get(orderDetail, "trackings", []), filter => {
      return filter.trackingStatus === "initiaterepair"
    })
    const ReturnLabel = returnLabel[returnLabel.length - 1]
    const getrepairReturnExchangeObj = _.find(_.get(orderDetail, "repairReturnExchangeIds", []), filter => {
      return String(filter.Id) === String(ReturnLabel.repairReturnExchangeId)
    })
    this.setState({shippingMethodType: _.get(getrepairReturnExchangeObj, "shippingMethodType", 'byMail')})
    return (
      <>
        {isLoader ? (
          <Loader />
        ) : (
          <>
        <TopNavigation userName={userName} orderDetail={orderDetail} {...this.props}/>
        {!_.includes(['byMail', ''], this.state.shippingMethodType) ?
          <div className="mini-container-wrapper">
            <div className="mini-container">
              <div className="head-wrap head-wrap-space">
                <div className="head-wrap-back">
                  <button type="button" className="btn btn-sm btn-back" >
                    <Link to={`/detail/${_.get(orderDetail, 'order.order_number', '')}`}>
                      <BackIcon/>
                    </Link>
                  </button>
                </div>
                <div className="head-wrap-title">
                  Request
                </div>
              </div>
              <div className="mini-container-inner mb-4">
              <div className="overview-wrap">
                  <h4>Your Repair Request has been submitted successfully!</h4>
                  <h6 className="product-generated-id">Repair ID: {_.get(ReturnLabel,'repairReturnExchangeId','')}</h6>
                  <CheckmarkIcon className="overview-icon"/>
                  <p>Your confirmation email has been sent to <br/>{_.get(orderDetail,'order.email','')}</p>
                  </div>
              </div>
              <div className="label-heading">
                <h5 className="label-heading-title">OUR ADDRESS</h5>
              </div>
              {/* eslint-disable-next-line */}
              {this.state.shippingMethodType === 'madisonStore' ? 
              <>
                <div style={{width: '480'}}><iframe title="frame-1" style={{border: '0'}} width="100%" height="300" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=946%20Madison%20Ave,%20New%20York,%20NY%2010021+(Ring%20Concierge)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">area maps</a></iframe></div>
                  <div className="map-address-wrap">
                    <h5 className="map-address">946 Madison Ave, New York, NY 10021</h5>
                  </div>
                <div className="mini-container-inner mb-4">
                  <div className="label-content-information">
                    <h5>Instructions for Store Drop Off</h5>
                  </div>
                  <ul className="label-options-list">
                    <li>
                      1. Pack your piece in the original packaging.
                    </li>
                    <li>
                      2. Print your attached paperwork and bring it to your selected boutique within 7 days of submitting your repair. Please make sure to bring your paperwork with you or the boutique will not be able to accept your repair.
                    </li>
                    <li>
                      3. Once you drop off your repair, the boutique will send you an email confirmation that your repair has been received and we will start working on your piece.
                    </li>
                    <li>
                      4. Please allow 3-5 weeks to receive your repair back to your selected shipping address. Once your piece is completed you will be sent a separate email with your FedEx tracking number. As a reminder, all Ring Concierge packages require signature upon delivery.
                    </li>
                    <li className="note-text text-left">
                    We reserve the right to reject pieces that are not in their original state or that are dropped off after the 7 day window. If your piece is found to be damaged beyond repair or we are unable to complete the service requested, we will ship it back to you and refund you the cost of your resize/repair less shipping.
                    </li>
                  </ul>
               </div>
              </>
              :
              <>
                <div style={{width: '480'}}><iframe title="frame-2" style={{border: '0'}} width="100%" height="300" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Ring%20Concierge%20400%20Bleecker%20St,%20New%20York,%20NY%2010014+(Ring%20Concierge)&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">area maps</a></iframe></div>
                  <div className="map-address-wrap">
                      <h5 className="map-address">400 Bleecker St, New York, NY 10014</h5>
                  </div>
                  <div className="mini-container-inner mb-4">
                  <div className="label-content-information">
                    <h5>Instructions for Store Drop Off</h5>
                  </div>
                  <ul className="label-options-list">
                    <li>
                      1. Pack your piece in the original packaging.
                    </li>
                    <li>
                      2. Print your attached paperwork and bring it to your selected boutique within 7 days of submitting your repair. Please make sure to bring your paperwork with you or the boutique will not be able to accept your repair.
                    </li>
                    <li>
                      3. Once you drop off your repair, the boutique will send you an email confirmation that your repair has been received and we will start working on your piece.
                    </li>
                    <li>
                      4. Please allow 3-5 weeks to receive your repair back to your selected shipping address. Once your piece is completed you will be sent a separate email with your FedEx tracking number. As a reminder, all Ring Concierge packages require signature upon delivery.
                    </li>
                    <li className="note-text text-left">
                    We reserve the right to reject pieces that are not in their original state or that are dropped off after the 7 day window. If your piece is found to be damaged beyond repair or we are unable to complete the service requested, we will ship it back to you and refund you the cost of your resize/repair less shipping.
                    </li>
                  </ul>
               </div>
              </>
              }
              <div className="label-heading">
                <h5 className="label-heading-title">YOUR BRINGING US</h5>
              </div>
              <div className="horizontal-product">
                <div className="product-thumbnail">
                  {_.get(image, "[0].image", "/default.webp") !== "" && (
                    <img
                      src={_.get(image, "[0].image", "/default.webp")}
                      alt={""}
                    />
                  )}
                </div>
                <div>
                  <h4 className="product-name">{_.get(RCFJOrder,'title','')}</h4>
                  <p className="product-shape-color">{_.get(RCFJOrder,'variant_title','')}</p>
                </div>
              </div>

            </div>
          </div> : 
          <div className="mini-container-wrapper">
            <div className="mini-container">
              <div className="head-wrap head-wrap-space">
                <div className="head-wrap-back">
                  <button type="button" className="btn btn-sm btn-back" >
                    <Link to={`/detail/${_.get(orderDetail, 'order.order_number', '')}`}>
                      <BackIcon/>
                    </Link>
                  </button>
                </div>
                <div className="head-wrap-title">
                  Request
                </div>
              </div>
              <div className="mini-container-inner mb-4">
              <div className="overview-wrap">
                  <h4>Your Repair Request has been submitted successfully!</h4>
                  <h6 className="product-generated-id">Repair ID: {_.get(ReturnLabel,'repairReturnExchangeId','')}</h6>
                  <CheckmarkIcon className="overview-icon"/>
                  <p>Your confirmation email has been sent to <br/>{_.get(orderDetail,'order.email','')}</p>

                </div>
                <div className="note-text text-left">
                  If your piece is found to be damaged beyond repair or we are unable to complete the service requested, we will ship it back to you and refund you the cost of your resize/repair less shipping.
                </div>
              </div>

              <div className="label-heading">
                <h5 className="label-heading-title">How to send us Your Product</h5>
              </div>
              <div className="mini-container-inner mb-4">
                {_.get(ReturnLabel,'printSlipLink','') !== '' ?  
                  <div className="label-content-information">
                    <h5>Your Label is Ready to Print </h5>
                    <p>This label was also sent to your email</p>
                    <button className="btn btn-dark w-100" onClick={this.openDownloadLink.bind(this, ReturnLabel )}>Download Shipping Label</button>
                  </div>
                :
                  <div className="label-content-information">
                    <h5>Your Tracking Number</h5>
                    <p>{_.get(ReturnLabel,'trackingNumber','')}</p>
                  </div>
                }
                <ul className="label-options-list">
                  <li>
                    1. Package your piece in its original packaging or a sturdy shipping box and seal securely.
                  </li>
                  <li>
                    2. Print the above return label and attach it to the package. Make sure any pre-existing labels are removed or covered up.
                  </li>
                  <li>
                    3. Drop off your package at your nearest FedEx location within 1 week of receiving this email. Drop boxes are not acceptable as the package needs to be scanned!
                  </li>
                  <li>
                    4. Once your package has been received and checked in by our team, you will receive an email containing any next steps.
                  </li>
                  <li className="note-text text-left">
                  We reserve the right to reject pieces that are not in their original state or that are sent outside the return/exchange period.
                  </li>
                </ul>

              </div>
              <div className="label-heading">
                <h5 className="label-heading-title">YOUR SENDING US</h5>
              </div>
              <div className="horizontal-product">
                <div className="product-thumbnail">
                  {_.get(image, "[0].image", "/default.webp") !== "" && (
                    <img
                      src={_.get(image, "[0].image", "/default.webp")}
                      alt={""}
                    />
                  )}
                </div>
                <div>
                  <h4 className="product-name">{_.get(RCFJOrder,'title','')}</h4>
                  <p className="product-shape-color">{_.get(RCFJOrder,'variant_title','')}</p>
                </div>
              </div>

            </div>
          </div>
        }
      </>
        )}
      </>
    )
  }
}
