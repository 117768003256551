import { connect } from 'react-redux'
import { getOrderDetail, returnRequest } from "../../store/order/duck"
import ReturnComponent from './component'

const ReturnContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
  	getOrderDetail,
  	returnRequest
  }
)(ReturnComponent)

export default ReturnContainer
