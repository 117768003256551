import { connect } from 'react-redux'

import NotPageFoundComponent from './component'

const NotPageFoundContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(NotPageFoundComponent)

export default NotPageFoundContainer
