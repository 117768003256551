import { connect } from 'react-redux'
import { getOrderDetail, updateShippingAddress, confirmSize, addPaymentMethod } from "../../../store/order/duck"
import CustomSizeOverviewComponent from './component'

const CustomSizeOverviewContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
  	getOrderDetail,
  	updateShippingAddress,
  	confirmSize,
  	addPaymentMethod
  }
)(CustomSizeOverviewComponent)

export default CustomSizeOverviewContainer
