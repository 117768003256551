import React, { PureComponent } from 'react'
import { Tooltip, message } from 'antd'
import _ from 'lodash'
// import Slider from 'react-slick'
import InputMask from "react-input-mask"
import ReactModal from 'react-modal'
import Cookies from 'js-cookie'
import Loader from '../../../components/loader'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import TopNavigation from '../../TopNavigation/component'
import { BackIcon, CloseIcon, QuestionIcon, LockIcon } from '../../../components/icons'
import { getOrderEmail } from '../../../constants/common'
import creditCardType from 'credit-card-type'
import config from '../../../config/index'
import load from 'load-stripe'
import VisaIcon from "../../../images/Visa.svg"
import AmericanExpressIcon from "../../../images/AmericanExpress.svg"
import DinersClubIcon from "../../../images/DinersClub.svg"
import DiscoverIcon from "../../../images/Discover.svg"
import MaestroIcon from "../../../images/Maestro.svg"
import MasterCardIcon from "../../../images/MasterCard.svg"
import UnionPayIcon from "../../../images/UnionPay.svg"
import { getCode, getData } from 'country-list'
import { CountryDropdown, RegionDropdown } from "react-country-region-selector"
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

// const confirmSize = config.confirmSize

const randomFixedInteger = function (length) {
  return Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1))
}

export default class ResizeOverviewComponent extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      exampleModal: false,
      orderDetail: '',
      userName: '',
      isLoader: true,
      shippingAddress: {},
      valAddress: {},
      quantity: 0,
      originalQty: 0,
      productId: 0,
      chatOpen: 0,
      RCFJOrder: '',
      count: true,
      currentRingSize: '',
      newRingSize: '',
      necklace: false,
      cardholderName:'',
      cardExpireDate:'',
      cardNumber:'',
      cvvCode:'',
      cvvLength: 3,
      customerStripeId:'',
      cardBox:false,
      paymentMethods:{},
      addNewCard: false,
      removeCard: false,
      resizeFee: '',
      totalFee: '',
      rcfjReasonCharges: [],
      TaxFee: 0,
      errorMessage: [],
      phoneNumberCountry: 'US',
      invalidAddress: false,
      taxSummary: []
    }
  }
  
  closeModal() {
    const { valAddress } = this.state
    this.setState({ editAddressModal: false, errSup: {}, shippingAddress: valAddress, errorMessage: [] })
  }
  
  goBack(){
    const { orderDetail } = this.state
    const data = {
      rcfjResize: this.state.rcfjResize,
      quantity: this.state.quantity,
      originalQty: this.state.originalQty,
      RCFJOrder: this.state.RCFJOrder,
      currentRingSize: this.state.currentRingSize,
      newRingSize: this.state.newRingSize,
      necklace: this.state.necklace,
    }
    this.props.history.push({
      pathname : `/rcfj-resize/${_.get(orderDetail, 'order.order_number', '')}`,
      state : { detail : data }
    })
  }
  
  async componentDidMount(){
    const data ={
      orderId: Cookies.get('orderId'),
      customerId: Cookies.get('customerId')
    }
    let productId = Cookies.get('productId')
    let RCFJOrder = []
    const { value } = await this.props.getOrderDetail(data)
    if(_.get(value,'status',false)){
      if(!_.includes([null, undefined, 'null', ''], productId)){
        let orderDetail = _.get(value, 'data', {})
        RCFJOrder = _.find(_.get(orderDetail, "order.line_items", []), filter => {
          return String(filter.id) === String(productId);
        })
      }
      this.setState({
        orderDetail: _.get(value, 'data', {}),
        userName: _.get(value, 'userName', {}),
        shippingAddress: _.get(value, 'data.order.shipping_address', {}) === null ? {} : _.get(value, 'data.order.shipping_address', {}),
        // isLoader: false,
        paymentMethods: _.get(value, 'data.paymentMethods', {}),
        productId,
        RCFJOrder
      })
      await this.checkAddressValidation(_.get(value, 'data.order.shipping_address', {}))
    } else{
      this.props.history.push('/no-page-found')
    }
  }
  
  componentDidUpdate(prevProps, prevState){
    if(prevProps.location.state && this.state.count){
      let newRingSize = _.get(prevProps,'location.state.detail.newRingSize','')
      let necklace = _.get(prevProps,'location.state.detail.necklace',false)
      let quantity = _.get(prevProps,'location.state.detail.quantity',1)
      let RCFJOrder = _.get(prevProps,'location.state.detail.RCFJOrder','')
      this.setState({
        rcfjResize : _.get(prevProps,'location.state.detail.rcfjResize',''),
        quantity : _.get(prevProps,'location.state.detail.quantity',0),
        originalQty : _.get(prevProps,'location.state.detail.originalQty',0),
        RCFJOrder : _.get(prevProps,'location.state.detail.RCFJOrder',''),
        currentRingSize : _.get(prevProps,'location.state.detail.currentRingSize',''),
        newRingSize : _.get(prevProps,'location.state.detail.newRingSize',''),
        necklace : _.get(prevProps,'location.state.detail.necklace',''),
        count: false
      })
      const charges = this.getTotalAmount(necklace, quantity, newRingSize, RCFJOrder)
      this.setState({resizeFee : charges })
    }
  }

  async submit(){
    let { orderDetail, cardholderName, cardNumber, cardExpireDate, cvvCode, customerStripeId, paymentMethods, addNewCard, cardBox, productId, RCFJOrder, totalFee, rcfjReasonCharges, taxFee } = this.state
    let err = {}
    let newRing = {
      id: productId,
      resizingSecondaryOption:'',
      resizingOptionValue: ''
    }
    if(this.state.necklace){
      newRing.resizingSecondaryOption = this.state.newRingSize
    } else {
      newRing.resizingOptionValue = this.state.newRingSize
    }
    const obj = {
      orderId: _.get(orderDetail, '_id', ''),
      Id: randomFixedInteger(12),
      reason: 'Repair',
      action:'rcfjresize',
      rcfjRingSize :[newRing],
      rcfjReasonCharges,
      shippingFee: 30,
      subTotalFee: parseFloat(this.state.resizeFee),
      totalFee,
      taxFee: parseFloat(taxFee),
      isChargeCustomer: true
    }
    let objDetail = {
      orderId: _.get(orderDetail,'order.order_number',''),
      tags: ['RCFJ'],
      isOnlyRCFJ: true,
      action:'RCLoop',
      shippingFee: 30,
      subTotalFee: parseFloat(this.state.resizeFee),
      totalFee,
      taxFee: parseFloat(taxFee),
      rcfjReasonCharges,
      taxSummary: this.state.taxSummary
    }
    if(!_.includes([null,undefined,'null',''], productId)){
      let rcfj_fulfilmentstatus = []; let x = 0
      rcfj_fulfilmentstatus = _.filter(_.get(orderDetail, "fulfillmentStatusRCFJ", []), v => {
        return (String(v.id) === String(productId))
      })
      if (_.includes([undefined,null,''],rcfj_fulfilmentstatus) || rcfj_fulfilmentstatus.length === 0) {
        RCFJOrder.quantities = [{ type: 'initiaterepair', id: RCFJOrder.id, quantity: parseInt(this.state.quantity) }]
      } else {
        _.map(rcfj_fulfilmentstatus, (items,i) =>{
          if(_.get(items,'quantities',[]).length !== 0){
            _.map(_.get(items,'quantities',[]), (qunty,j) =>{
              if(qunty.type === 'initiaterepair' && x === 0){
                RCFJOrder.quantities = [{ id: qunty.id, type: qunty.type, quantity: Math.abs(this.state.quantity) }]
                x++
              }
              if(_.includes(['initiatereturn','initiaterepair'],'initiaterepair')
                && _.includes(['initiatereturn','initiaterepair'],qunty.type) && x === 0){
                RCFJOrder.quantities = [{ id: qunty.id, type: qunty.type, quantity: Math.abs(this.state.quantity) }]
                x++
              }
            })
            if(x === 0){
              RCFJOrder.quantities = [{ type: 'initiaterepair', id: RCFJOrder.id, quantity: parseInt(this.state.quantity) }]
              x++
            }
          } else{
            RCFJOrder.quantities = [{ type: 'initiaterepair', id: RCFJOrder.id, quantity: parseInt(this.state.quantity) }]
          }
        })
      }
      obj.rcfjReasonNote = [{ id: parseInt(productId), rcfjOption: [`Resizing (${this.state.newRingSize})`] }]
      objDetail.selectedItems = [RCFJOrder]
    }
    
    this.setState({ isLoader: true })
    if(totalFee > 0){
      let paymentMethodsFields = {}
      let paymentData = {
        orderId: _.get(orderDetail,'order.order_number',''),
        id: _.get(orderDetail, 'order.customer.id', ''),
        email: getOrderEmail(orderDetail),
        isPaymentDone: _.get(orderDetail, 'order.customer.isPaymentDone', false)
      }
      if(addNewCard && cardBox){
        const err = this.validateCardDetails()
        if(Object.keys(err).length > 0) {
          this.setState({ errSup:err, isLoader: false })
        } else{
          cardExpireDate = cardExpireDate.split('/')
          const cardDetails = {
            "number": cardNumber,
            "exp_month": cardExpireDate[0],
            "exp_year":cardExpireDate[1],
            "cvc": cvvCode,
            "name": cardholderName
          }
          await load(`${process.env.REACT_APP_STRIPE_KEY}`).then(stripe => {
            stripe.card.createToken(cardDetails)
              .then(async (token, err) => {
                if(token){
                  const paymentMethodsFields = {
                    token: token.id,
                    cardId: token.card.id,
                    card: token.card,
                    object: token.object,
                  }
                  paymentData.paymentMethodsFields = paymentMethodsFields
                  const customerDetails = await this.props.addPaymentMethod(paymentData)
                  if(customerDetails && customerDetails.value && _.get(customerDetails.value, 'id', false)){
                    customerStripeId = _.get(customerDetails.value, 'id', '')
                    this.setState({ customerStripeId })
                    objDetail.customerStripeId = customerStripeId
                    objDetail.amount = totalFee
                    this.handleRepairRequest(obj, objDetail)
                  } else{
                    this.setState({ isLoader: false, errSup: err })
                  }
                }
              }).catch(err => {
              message.error(_.get(err, 'message', ''), () => {
                this.setState({ errSup: err, isLoader: false })
              })
            })
          })
        }
      } else{
        paymentData.paymentMethodsFields = paymentMethodsFields
        paymentData.paymentMethodId = _.get(paymentMethods, '_id', null)
        const customerDetails = await this.props.addPaymentMethod(paymentData)
        if(customerDetails && customerDetails.value && _.get(customerDetails.value, 'id', false)){
          customerStripeId = _.get(customerDetails.value, 'id', '')
          this.setState({ customerStripeId })
          objDetail.customerStripeId = customerStripeId
          objDetail.amount = totalFee
          this.handleRepairRequest(obj, objDetail)
        } else{
          this.setState({ errSup: err, isLoader: false })
        }
      }
    } else{
      this.handleRepairRequest(obj, objDetail)
    }
  }

  handleRepairRequest = async (obj, objDetail) => {
    const { orderDetail } = this.state
    const val = await this.props.generateId(obj)
    if (_.get(val, 'value.status', false)) {
      objDetail.Id = _.get(val,'value.data.Id','')
      objDetail.reason = _.get(val,'value.data.reason','')
      objDetail.rcfjReasonNote = _.get(val,'value.data.rcfjReasonNote',[])
      objDetail.repairReturnExchangeId = _.get(val,'value.data._id','')
      const { value } = await this.props.repairRequest(objDetail)
      if (_.get(value, 'status', false)) {
        message.error(_.get(value, 'message', ''), () => {
          this.setState({ isLoader: false })
        })
        this.props.history.push(`/rcfj-resize/request-confirmation/${_.get(orderDetail, 'order.order_number', '')}`)
      } else{
        message.error(_.get(value, 'message', ''), () => {
          this.setState({ isLoader: false })
        })
      }
    }
  }

  async getTotalAmount(necklace, quantity, newRingSize, RCFJOrder){
    const { paymentMethods } = this.state
    let cardBox = false; let addNewCard = false
    let charges = 0
    let totalFee = 0
    let rcfjReasonCharges = []
    if(necklace){
      const resizeCharges = config.resizingChainCharges
      let originalCharge = 0
      _.map(resizeCharges, item => {
        if(item.value === newRingSize){
          charges = parseInt(quantity) * parseFloat(item.charge)
          originalCharge = item.charge
        }
      })
      totalFee = charges + 30
      rcfjReasonCharges.push({ reason: `Resizing (${newRingSize})`,quantity: quantity, charge: parseFloat(charges), option: 'Resizing', originalCharge: originalCharge  })
    } else{
      charges = parseInt(quantity) * 45
      totalFee = charges + 30
      rcfjReasonCharges.push({ reason: `Resizing (${newRingSize})`,quantity: quantity, charge: parseFloat(charges), option: 'Resizing', originalCharge: 45  })
    }
    cardBox = totalFee > 0 ? true : false
    cardBox = _.get(paymentMethods, '_id', '') !== '' ? false : cardBox
    addNewCard = cardBox ? true : false
    if(totalFee > 0){
      await this.calculateTax(totalFee, charges, quantity, RCFJOrder)
    }
    this.setState({ resizeFee:charges, cardBox, addNewCard, rcfjReasonCharges })
    return charges
  }

  async calculateTax(totalFee, repairFee, quantity, RCFJOrder){
    const { orderDetail } = this.state
    let taxFee = 0
    const lineItems = [
      {
        title: _.get(RCFJOrder, 'title', ''),
        variant_title: _.get(RCFJOrder, 'variant_title', ''),
        id: _.get(RCFJOrder, 'id', ''), 
        amount: parseFloat(repairFee), 
        quantity: quantity,
        type: 'repair'
      },
      {
        title: 'shipping Fee',
        variant_title: 'shipping Fee',
        id: Date.now(), 
        amount: 30, 
        quantity: 1,
        type: 'shipping'
      }
    ]
    const obj = {
      taxType: 'repair',
      lineItems,
      shippingAddress: _.get(orderDetail,'order.shipping_address',''),
      customerCode: _.get(orderDetail,'order.customer.email','')
    }
    const { value } = await this.props.calculateTax(obj)
    if(_.get(value,'status',false)){
      _.map(_.get(value,'taxSummary',[]), taxLine => {
        if(_.get(taxLine,'tax',0)){
          taxFee += parseFloat(_.get(taxLine,'tax',0))
        }
      })
    }
    totalFee += taxFee
    this.setState({ 
      totalFee, 
      taxFee: (parseFloat(taxFee)).toLocaleString(undefined, {minimumFractionDigits: 2}), 
      taxSummary: _.get(value,'taxSummary',[]), 
      isLoader: false 
    })
    return (true)
  }
  
  editShippingAddress(key) {
    if (key === 'editAddressModal') {
      const valAddress = _.cloneDeep(this.state.shippingAddress)
      this.setState({ [key]: true, valAddress })
      this.forceUpdate()
    }
  }
  handleChangeAddress(e) {
    const { shippingAddress } = this.state
    shippingAddress[e.target.name] = e.target.value
    this.setState({ shippingAddress })
    this.forceUpdate()
  }
  
  handlePaymentDetailChange(event){
    this.setState({ [event.target.name]:event.target.value })
    if (event.target.name === 'cardNumber') {
      this.handleCreditCardChange(event.target.value)
    }
    this.forceUpdate()
  }
  
  handleCreditCardChange = (cardNumber) => {
    var cardType = creditCardType(cardNumber)
    this.setState({
      cvvLength: _.get(cardType[0], 'code.size', 3),
    })
  }
  
  validateCardDetails() {
    const err = {}
    const { cardholderName, cardNumber, cardExpireDate, cvvCode } = this.state
    if (cardNumber === '') {
      err.cardNumber = 'Card number is required'
    }
    if (cardholderName === '') {
      err.cardholderName = 'Card name is required'
    }
    if (cvvCode === '') {
      err.cvvCode = 'Cvv is required'
    }
    if (cardExpireDate === '') {
      err.cardExpireDate = 'Expiration date is required'
    }
    return err
  }

  handleChangeCountry = (name, value) => {
    const { shippingAddress } = this.state
    shippingAddress[name] = value
    if(name === 'country'){
      let countryshortForm = getCode(value)
      if(!countryshortForm){
        const countiesCodeName = getData()
        _.map(countiesCodeName, list => {
          if(_.get(list, 'name', '') && _.get(list, 'name', '').indexOf(value) !== -1){
            countryshortForm = _.get(list, 'code', '')
          }
        }) 
      }
      this.setState({ phoneNumberCountry: countryshortForm, shippingAddress })
    }
    this.forceUpdate()
  }

  handleChangePhone = (name, value) => {
    const { shippingAddress } = this.state
    shippingAddress[name] = value
    this.setState({ shippingAddress })
    this.forceUpdate()
  }
  
  handleEditAddress = async() => {
    const { shippingAddress, orderDetail, resizeFee, quantity, RCFJOrder } = this.state
    const err = {}
    if (!shippingAddress.first_name || shippingAddress.first_name === '' ) {
      err.first_name = 'First Name is required.'
    }
    if (!shippingAddress.last_name || shippingAddress.last_name === '' ) {
      err.last_name = 'Last Name is required.'
    }
    if (!shippingAddress.address1 || shippingAddress.address1 === '' ) {
      err.address = 'Address is required.'
    }
    if (!shippingAddress.city || shippingAddress.city === '' ) {
      err.city = 'City is required.'
    }
    if (!shippingAddress.province || shippingAddress.province === '' ) {
      err.state = 'State is required.'
    }
    if (!shippingAddress.zip || shippingAddress.zip === '' ) {
      err.zipcode = 'Zipcode is required.'
    }
    if (!shippingAddress.province || shippingAddress.province === '' ) {
      err.state = 'State is required.'
    }
    if (!shippingAddress.country || shippingAddress.country === '' ) {
      err.state = 'Country is required.'
    }
    if(Object.keys(err).length === 0) {
      const obj = {
        id: _.get(orderDetail, '_id', ''),
        orderId: _.get(orderDetail, 'order.id', ''),
        shipping_address: shippingAddress,
        addressType: 'shippingAddress'
      }
      this.setState({ isLoader: true })
      const { value } = await this.props.updateShippingAddress(obj)
      if(_.get(value, 'status', false)) {
        message.error(_.get(value, 'message', ''), () => {
          this.setState({ errSup: {}, shippingAddress: _.get(value, 'shippingAddress', '') })
        })
        this.forceUpdate()
        if(resizeFee > 0){
          const totalFee = resizeFee + 30 
          await this.calculateTax(totalFee, resizeFee, quantity, RCFJOrder)
          this.setState({ editAddressModal: false })
        } else{
          this.setState({ editAddressModal: false, isLoader: false })
        }
        await this.checkAddressValidation(_.get(value, 'shippingAddress', ''))
      } else {
        this.setState({ isLoader: false, errorMessage: _.get(value, 'message.shipping_address', []) })
      }
    } else {
      this.setState({ errSup: err, isLoader: false })
    }
  }

  addNewCardDetails= () =>{
    this.setState({ cardBox: true, addNewCard: true, removeCard: true })
    this.forceUpdate()
  }

  removeCard = () =>{
    this.setState({ cardBox: false, addNewCard: false, removeCard: false})
    this.forceUpdate()
  }

  openChat(){
    const { chatOpen } = this.state
    if(chatOpen === 0){
      window.GorgiasChat.open()
      this.setState({ chatOpen: 1 })
    } else{
      window.GorgiasChat.close()
      this.setState({ chatOpen: 0 })
    }
  }

  checkAddressValidation = async (shippingAddress) => {
    const { value } = await this.props.checkAddressValidation({ shippingAddress })
    if(!_.get(value, 'status', false)){
      this.setState({ messageShown: true })
      message.error(_.get(value, 'message', ''), () => {
        this.setState({ messageShown: false, invalidAddress: true })
      })
    } else{
      this.setState({ invalidAddress: false })
    }
  }

  render() {
    const { orderDetail, invalidAddress, userName, isLoader, errSup, shippingAddress, productId, RCFJOrder, paymentMethods, cardBox } = this.state
    const image = _.filter(_.get(orderDetail, "images", []), filter => {
      return String(filter.id) === String(productId);
    })
    return (
      <>
        {isLoader ? (
          <Loader />
        ) : (
          <>
          <TopNavigation userName={userName} orderDetail={orderDetail} {...this.props}/>
          <div className="mini-container-wrapper">
            <div className="mini-container">
              <div className="head-wrap head-wrap-space">
                <div className="head-wrap-back">
                  <button type="button" className="btn btn-sm btn-back" onClick={this.goBack.bind(this)}>
                    <BackIcon/>
                  </button>
                </div>
                <div className="head-wrap-title">
                  Overview
                </div>
              </div>
              <div className="horizontal-product">
                <div className="product-thumbnail">
                  {_.get(image, "[0].image", "/default.webp") !== "" && (
                    <img
                      src={_.get(image, "[0].image", "/default.webp")}
                      alt={""}
                    />
                  )}
                </div>
                <div>
                  <h4 className="product-name">{_.get(RCFJOrder,'title','')}</h4>
                  <p className="product-shape-color">{_.get(RCFJOrder,'variant_title','')}</p>
                </div>
              </div>
              <div className="mini-container-inner">
                  <div className="form-group">
                    <label className="label-info-text">{this.state.necklace ? 'Your Adjustment' : 'Tell us what Size you need' }</label>
                    {this.state.necklace ?
                      <div>
                        <p>{this.state.newRingSize}<span className="amount-text">${parseFloat(this.state.resizeFee).toLocaleString(undefined, {minimumFractionDigits: 2})}</span></p>
                      </div>
                    :
                      <div>
                        <p>Current size: {this.state.currentRingSize} <br/> New Size: {this.state.newRingSize} <span className="amount-text">${parseFloat(this.state.resizeFee).toLocaleString(undefined, {minimumFractionDigits: 2})}</span></p>
                      </div> 
                    }
                  </div>

                <div className="form-group">
                  <label className="label-info-text">Return Address</label>
                  <div className="address-info">
                    {_.get(shippingAddress,'first_name','')} {_.get(shippingAddress,'last_name','')}<br/>
                    {_.get(shippingAddress,'address1','')} {_.get(shippingAddress,'address2','')}, {_.get(shippingAddress,'city','')}, {_.get(shippingAddress,'province_code','')} {_.get(shippingAddress,'zip','')}
                    <span onClick={this.editShippingAddress.bind(this, 'editAddressModal')} className="btn-edit-address">Edit Address</span>
                  </div>
                </div>

                {
                  !cardBox && this.state.totalFee > 0  && !this.state.addNewCard ? (
                    <div className="">
                      <ul className="payment-card-list">
                        <li>
                          <div className="payment-card-info"><span className="card-icon">
                          {
                            _.get(paymentMethods, 'card.brand', '') === 'Visa' ? (
                              <img src={VisaIcon} alt="" height="32"/>
                            ) : (
                              _.get(paymentMethods, 'card.brand', '') === 'MasterCard' ? (
                                  <img src={MasterCardIcon} alt="" height="32"/>
                                ) : (
                                  _.get(paymentMethods, 'card.brand', '') === 'American Express' ? (
                                      <img src={AmericanExpressIcon} alt="" height="32"/>
                                    ) : (
                                      _.get(paymentMethods, 'card.brand', '') === 'Discover' ? (
                                          <img src={DiscoverIcon} alt="" height="32"/>
                                        ) : (
                                          _.get(paymentMethods, 'card.brand', '') === 'Diners Club' ? (
                                              <img src={DinersClubIcon} alt="" height="32"/>
                                            ) : (
                                              _.get(paymentMethods, 'card.brand', '') === 'UnionPay' ? (
                                                  <img src={UnionPayIcon} alt="" height="32"/>
                                                ) : (
                                                  _.get(paymentMethods, 'card.brand', '') === 'Maestro' ? (
                                                      <img src={MaestroIcon} alt="" height="32"/>
                                                    ) : (
                                                      ''
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                          }

                          </span><span class="card-number">{_.get(paymentMethods, 'card.brand', '')}****{_.get(paymentMethods, 'card.last4', '')}</span></div>
                          <div>Expiration Date {_.get(paymentMethods, 'card.exp_month', '')}/{`${_.get(paymentMethods, 'card.exp_year', '')}`.toString().substr(-2)}</div>
                          {/* <div>Expire date 20/7<button className="btn btn-link w-120">Expire date 20/7</button></div> */}
                        </li>
                      </ul>
                      <div className="form-group">
                        <button type="button" className="btn btn-dark w-100" onClick={this.addNewCardDetails.bind(this)}>Add New Card</button>
                      </div>
                    </div>
                  ) : (
                    ''
                  )
                }
                {
                  cardBox ? (
                    <div>
                      <hr className="horizontal-line"/>
                      <div className="form-group">
                        <label className="form-label">Cardholder name</label>
                        <input
                          type="text"
                          name="cardholderName"
                          className="form-control"
                          onChange={this.handlePaymentDetailChange.bind(this)}
                          // onBlur={handleBlur}
                          value={this.state.cardholderName}
                          required
                        />
                        <div className="invalid-feedback">{errSup && errSup.cardholderName ? errSup.cardholderName : ""}</div>
                      </div>
                      <div className="form-group material-textfield">
                        <label className="form-label">Card Number</label>
                        <InputMask
                          maskChar={null}
                          mask="9999 9999 9999 9999"
                          name="cardNumber"
                          className="form-control"
                          onChange={this.handlePaymentDetailChange.bind(this)}
                          // onBlur={handleBlur}
                          value={this.state.cardNumber}
                          required
                        />
                        <button type="button" className="btn info-tooltip"><LockIcon/></button>
                        <div className="invalid-feedback">{errSup && errSup.cardNumber ? errSup.cardNumber : ""}</div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">MM/YY</label>
                            <InputMask
                              mask="99/99"
                              maskChar={null}
                              name="cardExpireDate"
                              className="form-control"
                              onChange={this.handlePaymentDetailChange.bind(this)}
                              // onBlur={handleBlur}
                              value={this.state.cardExpireDate}
                              required
                            />
                            <div className="invalid-feedback">{errSup && errSup.cardExpireDate ? errSup.cardExpireDate : ""}</div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <label className="form-label">CVV</label>
                            <InputMask
                              mask={this.state.cvvLength === 3 ? '999' : '9999'}
                              maskChar={null}
                              name="cvvCode"
                              className="form-control"
                              onChange={this.handlePaymentDetailChange.bind(this)}
                              // onBlur={handleBlur}
                              value={this.state.cvvCode}
                              required
                            />
                            <Tooltip placement="top" title="3-digit security code usually found on the back of
                                      your card. American Express cards have a 4-digit
                                      code located on the front.">
                              <button type="button" className="btn info-tooltip"><QuestionIcon/></button>
                            </Tooltip>
                            <div className="invalid-feedback">{errSup && errSup.cvvCode ? errSup.cvvCode : ""}</div>
                          </div>
                        </div>
                      </div>
                      {
                       this.state.removeCard ? (
                         <div className="form-group">
                           <button type="button" className="btn btn-dark w-100" onClick={this.removeCard.bind(this)}>Cancel</button>
                         </div>
                       ) : ('')
                      }
                    </div>
                  ) : ('')
                }

                     <div className="form-group">
                       <div className="note-text">
                         These costs are estimates based upon the average resize/repair and are subject to change upon reviewing your piece. We will notify you should your piece require additional costs.
                       </div>
                     </div>
                     <div className="form-group">
                      <label className="label-info-text">Summary</label>
                      <ul className="summary-info">
                        <li>
                          <span>Quantity</span> <span>{this.state.quantity}</span>
                        </li>
                        <li>
                          <span>Resize Fee </span> <span>${parseFloat(this.state.resizeFee).toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
                        </li>
                        <li>
                          <span>Shipping (round-trip fully insured)</span> <span>$30.00</span>
                        </li>
                        <li>
                          <span>Tax </span> <span>${parseFloat(this.state.taxFee).toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
                        </li>
                        <li>
                          <span>Total </span> <span>${parseFloat(this.state.totalFee).toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
                        </li>
                       </ul>
                     </div>
                    <div>
                      <button 
                        type="button" 
                        className="btn btn-dark w-100" 
                        disabled={invalidAddress}
                        onClick={this.submit.bind(this)}
                      >
                        Submit
                      </button>
                    </div>
                <div className="chat-with-us">
                  Have questions? <span onClick={this.openChat.bind(this)}>Chat with us</span>
                </div>
              </div>
            </div>
          </div>
          <ReactModal
            isOpen={this.state.editAddressModal}
            onRequestClose={this.closeModal.bind(this)}
            ariaHideApp={false}
            contentLabel="Edit Shippping Address"
            portalClassName="react-modal"
            overlayClassName="modal"
            className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
          >
            <div className="modal-content">
              <div className="react-modal-header">
                <h5 className="react-modal-title">Edit Shippping Address</h5>
                <button type="button" className="btn react-modal-close" onClick={this.closeModal.bind(this)}><CloseIcon/></button>
              </div>
              <div className="react-modal-body">
                {this.state.errorMessage && this.state.errorMessage.length ? (
                  <div className="notice-attention">
                    <div>
                      <h6 className="notice-attention-heading">
                        There was {this.state.errorMessage.length} error on this form:
                      </h6>
                      {
                        _.map(this.state.errorMessage, message  => {
                          return(
                            <p className="notice-attention-subheading">
                              {message}
                            </p>
                          )
                        })
                      }
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">First Name <span className="text-danger">*</span></label>
                      <input
                        name="first_name"
                        value={_.get(shippingAddress,'first_name','')}
                        onChange={this.handleChangeAddress.bind(this)}
                        type="text"
                        className="form-control"
                        required
                      />
                      <div className="invalid-feedback">{errSup && errSup.first_name ? errSup.first_name : ""}</div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Last Name <span className="text-danger">*</span></label>
                      <input
                        name="last_name"
                        value={_.get(shippingAddress,'last_name','')}
                        onChange={this.handleChangeAddress.bind(this)}
                        type="text"
                        className="form-control"
                        required
                      />
                      <div className="invalid-feedback">{errSup && errSup.last_name ? errSup.last_name : ""}</div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">company </label>
                  <input
                    name="company"
                    value={_.get(shippingAddress, "company", "")}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Address <span className="text-danger">*</span></label>
                  <input
                    name="address1"
                    value={_.get(shippingAddress, "address1", "")}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                  <div className="invalid-feedback">{errSup && errSup.address ? errSup.address : ""}</div>
                </div>
                <div className="form-group">
                  <label className="form-label">Apartment, suite, etc. (optional) </label>
                  <input
                    name="address2"
                    value={_.get(shippingAddress, "address2", "")}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">City <span className="text-danger">*</span></label>
                  <input
                    name="city"
                    value={_.get(shippingAddress,'city','')}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Country/Region <span className="text-danger">*</span></label>
                      <CountryDropdown
                        name="country"
                        value={_.get(shippingAddress, "country", "")}
                        onChange={this.handleChangeCountry.bind(this, 'country')}
                        classes="form-control material-textfield-input"
                      />
                      <div className="invalid-feedback">{errSup && errSup.country ? errSup.country : ""}</div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">State/Provision <span className="text-danger">*</span></label>
                      <RegionDropdown
                        name="province"
                        country={_.get(shippingAddress, "country", "")}
                        value={_.get(shippingAddress, "province", "")}
                        onChange={this.handleChangeCountry.bind(this, 'province')}
                        classes="form-control material-textfield-input"
                      />
                      <div className="invalid-feedback">{errSup && errSup.state ? errSup.state : ""}</div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group material-textfield">
                      <label className="form-label">Zip <span className="text-danger">*</span></label>
                      <input
                        name="zip"
                        value={_.get(shippingAddress,'zip','')}
                        onChange={this.handleChangeAddress.bind(this)}
                        type="number"
                        className="form-control material-textfield-input"
                        required
                      />
                      <div className="invalid-feedback">{errSup && errSup.zipcode ? errSup.zipcode : ""}</div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="country-input-field">
                    <PhoneInput
                      name="phone"
                      defaultCountry={this.state.phoneNumberCountry}
                      international={true}
                      value={_.get(shippingAddress, "phone", "")}
                      onChange={this.handleChangePhone.bind(this, 'phone')}
                      classes="form-control"
                    />
                  </div>
                </div>
                <button onClick={this.handleEditAddress.bind(this)} className="btn btn-dark w-100">
                  Save
                </button>
              </div>
            </div>
          </ReactModal>
        </>
        )}
      </>
    )
  }
}
