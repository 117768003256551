import React, { PureComponent } from 'react'
import { Tooltip, message } from 'antd'
import _ from 'lodash'
import Slider from 'react-slick'
import InputMask from "react-input-mask"
import ReactModal from 'react-modal'
import Cookies from 'js-cookie'
import Loader from '../../../components/loader'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import TopNavigation from '../../TopNavigation/component'
import { BackIcon, CloseIcon, QuestionIcon, LockIcon } from '../../../components/icons'
import { getOrderEmail } from '../../../constants/common'
import creditCardType from 'credit-card-type'
import config from '../../../config/index'
import load from 'load-stripe'
import VisaIcon from "../../../images/Visa.svg"
import AmericanExpressIcon from "../../../images/AmericanExpress.svg"
import DinersClubIcon from "../../../images/DinersClub.svg"
import DiscoverIcon from "../../../images/Discover.svg"
import MaestroIcon from "../../../images/Maestro.svg"
import MasterCardIcon from "../../../images/MasterCard.svg"
import UnionPayIcon from "../../../images/UnionPay.svg"
import { getCode, getData } from 'country-list'
import { CountryDropdown, RegionDropdown } from "react-country-region-selector"
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

ReactModal.setAppElement('#root')

const randomFixedInteger = function (length) {
  return Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1))
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
}

export default class RepairOverviewComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  }

  constructor(props){
    super(props)
    this.state = {
      orderDetail: {},
      product: {},
      userName: '',
      shippingAddress: {},
      RepairReason: [],
      someDetails: '',
      isLoader: true,
      errSup: {},
      images:[],
      ringSize:'',
      editAddressModal: false,
      otherReason: '',
      otherStatus: false,
      showOther: '',
      cardholderName:'',
      cardExpireDate:'',
      cardNumber:'',
      cvvCode:'',
      cvvLength: 3,
      customerStripeId:'',
      repairFee:0,
      cardBox:false,
      paymentMethods:{},
      addNewCard: false,
      removeCard: false,
      lastRingSize: 0,
      ringSizeItem: [],
      selectRepair: false,
      chargeMessage:'',
      shippingCharge: false,
      chatOpen: 0,
      repairOptions : [],
      first_name: '',
      last_name: '',
      address1: '',
      address2: '',
      city: '',
      province_code: '',
      zip: '',
      valAddress: {},
      updatedRepairOptions: [],
      count: true,
      vowReasonCharges: [],
      taxFee: 0,
      errorMessage: [],
      phoneNumberCountry: 'US',
      invalidAddress: false,
      taxSummary: []
    }
  }

  closeModal() {
    const { valAddress } = this.state
    this.setState({ editAddressModal: false, errSup: {}, shippingAddress: valAddress, errorMessage: [] })
  }

  goBack(){
    const { orderDetail, otherStatus, RepairReason } = this.state
    if(otherStatus === true){
      RepairReason.push('Other')
      RepairReason.splice(-1)
    }
    const data = {
      RepairReasons: RepairReason,
      someDetails: this.state.someDetails,
      images: this.state.images,
      ringSize: this.state.ringSize,
      otherReason: this.state.otherReason,
      otherStatus: this.state.otherStatus,
      showOther: this.state.showOther
    }
    this.setState({ RepairReason: [] })
    this.props.history.push({
      pathname : `/repair/${_.get(orderDetail, 'order.order_number', '')}`,
      state : { detail : data }
    })
  }

  async componentDidMount(){
    const data ={
      orderId: Cookies.get('orderId'),
      customerId: Cookies.get('customerId')
    }
    const { value } = await this.props.getOrderDetail(data)
    if(_.get(value,'status',false)){
      let ringSizeItem = _.filter(_.get(value, 'data.requestId', []), v => { return v.reason === "Repair" && parseInt(v.newRingsize) > 0})
      this.setState({
        orderDetail: _.get(value, 'data', {}),
        product: _.get(value, 'lItem', {}),
        userName: _.get(value, 'userName', {}),
        shippingAddress: _.get(value, 'data.order.shipping_address', {}) === null ? {} : _.get(value, 'data.order.shipping_address', {}),
        first_name: _.get(value, 'data.order.shipping_address.first_name', ''),
        last_name: _.get(value, 'data.order.shipping_address.last_name', ''),
        address1: _.get(value, 'data.order.shipping_address.address1', ''),
        address2: _.get(value, 'data.order.shipping_address.address2', ''),
        city: _.get(value, 'data.order.shipping_address.city', ''),
        province_code: _.get(value, 'data.order.shipping_address.province_code', ''),
        zip: _.get(value, 'data.order.shipping_address.zip', ''),
        // isLoader: false,
        paymentMethods: _.get(value, 'data.paymentMethods', {}),
        ringSizeItem: ringSizeItem,
        errSup: {}, editAddressModal: false
      })
      await this.checkAddressValidation(_.get(value, 'data.order.shipping_address', {}))
    } else{
      this.props.history.push('/no-page-found')
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.location.state && this.state.count){
      let { addNewCard } = this.state
      let RepairReason = _.get(prevProps,'location.state.detail.RepairReasons',[])
      let ringSize = _.get(prevProps,'location.state.detail.ringSize','')
      this.setState({
        RepairReason : _.get(prevProps,'location.state.detail.RepairReasons',[]),
        someDetails : _.get(prevProps,'location.state.detail.someDetails',''),
        images : _.get(prevProps,'location.state.detail.images',[]),
        ringSize : _.get(prevProps,'location.state.detail.ringSize',''),
        otherReason : _.get(prevProps,'location.state.detail.otherReason',''),
        otherStatus : _.get(prevProps,'location.state.detail.otherStatus',''),
        showOther : _.get(prevProps,'location.state.detail.showOther',''),
        count: false
      })
      if(!addNewCard){
        this.getTotalAmount(RepairReason, ringSize)
      }
    }
  }

  async submit(){
    let { orderDetail, cardholderName, cardNumber, cardExpireDate, cvvCode, customerStripeId, repairFee, paymentMethods, addNewCard, cardBox, RepairReason, vowReasonCharges, shippingCharge, totalFee } = this.state

    let err = {}
    let reasonArr = RepairReason
    reasonArr.forEach((item,i) =>{
      if(item === 'Need a different ring size'){
        reasonArr[i] = `Need a different ring size (${this.state.ringSize})`
      }
    })
    const obj = {
      orderId: _.get(orderDetail, '_id', ''),
      Id: randomFixedInteger(12),
      reason: 'Repair',
      reasonNote: reasonArr,
      someDetails: this.state.someDetails,
      images: this.state.images,
      ringSize: this.state.ringSize,
      action: 'vowrepair',
      vowReasonCharges,
      shippingFee: shippingCharge ? 50 : 0,
      subTotalFee: shippingCharge ? repairFee - 50 : repairFee,
      totalFee: totalFee,
      taxFee: parseFloat(this.state.taxFee),
      isChargeCustomer: true
    }
    let objDetail = {
      orderId: _.get(orderDetail,'order.order_number',''),
      tags:['VOW'],
      action:'RCLoop',
      vowReasonCharges,
      shippingCharge,
      shippingFee: shippingCharge ? 50 : 0,
      subTotalFee: shippingCharge ? repairFee - 50 : repairFee,
      totalFee: totalFee,
      taxFee: parseFloat(this.state.taxFee),
      taxSummary: this.state.taxSummary
    }

    this.setState({ isLoader: true })
    if(totalFee > 0){
      let paymentMethodsFields = {}
      let paymentData = {}
      if(addNewCard && cardBox){
        const err = this.validateCardDetails()
        if(Object.keys(err).length > 0) {
          this.setState({ errSup:err, isLoader: false })
        }else{
          cardExpireDate = cardExpireDate.split('/')
          const cardDetails = {
            "number": cardNumber,
            "exp_month": cardExpireDate[0],
            "exp_year":cardExpireDate[1],
            "cvc": cvvCode,
            "name": cardholderName
          }
          paymentData.orderId = _.get(orderDetail,'order.order_number','')
          paymentData.id = _.get(orderDetail, 'order.customer.id', '')
          paymentData.email = getOrderEmail(orderDetail)
          paymentData.isPaymentDone = _.get(orderDetail, 'order.customer.isPaymentDone', false)

          await load(`${process.env.REACT_APP_STRIPE_KEY}`).then(stripe => {
            stripe.card.createToken(cardDetails)
              .then(async (token, err) => {
                if(token){
                  const paymentMethodsFields = {
                    token: token.id,
                    cardId: token.card.id,
                    card: token.card,
                    object: token.object,
                  }
                  paymentData.paymentMethodsFields = paymentMethodsFields
                  const customerDetails = await this.props.addPaymentMethod(paymentData)
                  if(customerDetails && customerDetails.value && _.get(customerDetails.value, 'id', false)){
                    customerStripeId = _.get(customerDetails.value, 'id', '')
                    // const paymentFields = {
                    //   customerStripeId:customerStripeId,
                    //   amount: repairFee,
                    //   orderId:_.get(orderDetail,'order.order_number','')
                    // }
                    // const paymentDetails = await this.props.addPayment(paymentFields)
                    this.setState({ customerStripeId })
                    objDetail.customerStripeId = customerStripeId
                    objDetail.amount = totalFee
                    const val = await this.props.generateId(obj)
                    if (_.get(val, 'value.status', false)) {
                      objDetail.Id = _.get(val,'value.data.Id','')
                      objDetail.reason = _.get(val,'value.data.reason','')
                      objDetail.reasonNote = _.get(val,'value.data.reasonNote','')
                      objDetail.someDetails = _.get(val,'value.data.someDetails','')
                      objDetail.images = _.get(val,'value.data.ringImages',[])
                      objDetail.repairReturnExchangeId = _.get(val,'value.data._id','')
                      const { value } = await this.props.repairRequest(objDetail)
                      if (_.get(value, 'status', false)) {
                        message.error(_.get(value, 'message', ''), () => {
                          this.setState({ isLoader: false })
                        })
                        this.props.history.push(`/repair/request-confirmation/${_.get(orderDetail, 'order.order_number', '')}`)
                      } else{
                        message.error(_.get(value, 'message', ''), () => {
                          this.setState({ isLoader: false })
                        })
                      }
                    }
                  }else{
                    this.setState({ isLoader: false, errSup: err })
                  }
                }
              }).catch(err => {
              message.error(_.get(err, 'message', ''), () => {
                this.setState({ errSup: err, isLoader: false })
              })
            })
          })
        }
      }else{
        paymentData.orderId = _.get(orderDetail,'order.order_number','')
        paymentData.id = _.get(orderDetail, 'order.customer.id', '')
        paymentData.email = getOrderEmail(orderDetail)
        paymentData.isPaymentDone = _.get(orderDetail, 'order.customer.isPaymentDone', false)
        paymentData.paymentMethodsFields = paymentMethodsFields
        paymentData.paymentMethodId = _.get(paymentMethods, '_id', null)
        const customerDetails = await this.props.addPaymentMethod(paymentData)
        if(customerDetails && customerDetails.value && _.get(customerDetails.value, 'id', false)){
          customerStripeId = _.get(customerDetails.value, 'id', '')
          // const paymentFields = {
          //   customerStripeId:customerStripeId,
          //   amount: repairFee,
          //   orderId:_.get(orderDetail,'order.order_number','')
          // }
          // const paymentDetails = await this.props.addPayment(paymentFields)
          this.setState({ customerStripeId })
          objDetail.customerStripeId = customerStripeId
          objDetail.amount = totalFee
          const val = await this.props.generateId(obj)
          if (_.get(val, 'value.status', false)) {
            objDetail.Id = _.get(val,'value.data.Id','')
            objDetail.reason = _.get(val,'value.data.reason','')
            objDetail.reasonNote = _.get(val,'value.data.reasonNote','')
            objDetail.someDetails = _.get(val,'value.data.someDetails','')
            objDetail.images = _.get(val,'value.data.ringImages',[])
            objDetail.repairReturnExchangeId = _.get(val,'value.data._id','')
            const { value } = await this.props.repairRequest(objDetail)
            if (_.get(value, 'status', false)) {
              message.error(_.get(value, 'message', ''), () => {
                this.setState({ isLoader: false })
              })
              this.props.history.push(`/repair/request-confirmation/${_.get(orderDetail, 'order.order_number', '')}`)
            } else{
              message.error(_.get(value, 'message', ''), () => {
                this.setState({ isLoader: false })
              })
            }
          }
        }else{
          this.setState({ errSup: err, isLoader: false })
        }
      }
    } else{
      const val = await this.props.generateId(obj)
      if (_.get(val, 'value.status', false)) {
        objDetail.Id = _.get(val,'value.data.Id','')
        objDetail.reason = _.get(val,'value.data.reason','')
        objDetail.reasonNote = _.get(val,'value.data.reasonNote','')
        objDetail.someDetails = _.get(val,'value.data.someDetails','')
        objDetail.images = _.get(val,'value.data.ringImages',[])
        objDetail.repairReturnExchangeId = _.get(val,'value.data._id','')
        const { value } = await this.props.repairRequest(objDetail)
        if (_.get(value, 'status', false)) {
          message.error(_.get(value, 'message', ''), () => {
            this.setState({ isLoader: false })
          })
          this.props.history.push(`/repair/request-confirmation/${_.get(orderDetail, 'order.order_number', '')}`)
        } else{
          message.error(_.get(value, 'message', ''), () => {
            this.setState({ isLoader: false })
          })
        }
      }
    }
  }

  differenceInDays(firstDate, secondDate) {
    const days = Math.round((secondDate-firstDate)/(1000*60*60*24))
    return Math.round(days/31)
  }

  getTotalAmount = async (RepairReason, ringSize) => {
    let { orderDetail, ringSizeItem, shippingCharge, paymentMethods, cardBox, addNewCard } = this.state
    let updatedRepairOptions = []
    const repairCharges = config.repairCharges
    let orderRingSize = 0
    let months = 0
    let vowReasonCharges = []
    if(_.get(orderDetail, 'purchaseorders[0].createdAt', false)){
      const currentDate  = new Date()
      const purchaseDate = new Date(_.get(orderDetail, 'purchaseorders[0].createdAt', ''))
      months = this.differenceInDays(purchaseDate, currentDate)
    }
    let charges = 0
    let repairOptions = _.filter(repairCharges, track => {
      return _.includes(RepairReason, track.key)
    })

    if(_.size(repairOptions) > 0){
      _.filter(repairOptions, item => {
        let currentCharge = 0
        if(item.key === 'Need a different ring size'){
          _.map(_.get(orderDetail, 'order.line_items', []), item =>{
            _.map(item.properties, itemProp =>{
              if(itemProp.name === 'RingSize'){
                orderRingSize = itemProp.value
              }
            })
          })
          let upSize = parseFloat(orderRingSize)+1.5
          let downSize = parseFloat(orderRingSize)-1.5
          if( months <= 12 && ringSize && ringSize > 0){
            if(_.size(ringSizeItem) === 0){ // First Time Ring Size Repair
              shippingCharge = false
              if(parseFloat(upSize) >= parseFloat(ringSize)) {
                charges = 0
                currentCharge = 0
              } else if((parseFloat(downSize) <= parseFloat(ringSize)) && (parseFloat(upSize) >= parseFloat(ringSize))){
                charges = 0
                currentCharge = 0
              } else {
                charges = 100
                currentCharge = 100
              }
              vowReasonCharges.push({ reason: `Need a different ring size (${ringSize})`, quantity:1, charge: parseFloat(currentCharge).toFixed(2), option: item.key })

            } else{ // Second Time Ring Size Repair

              shippingCharge = true

              upSize = parseFloat(ringSizeItem[0].newRingsize)+1.5
              downSize = parseFloat(ringSizeItem[0].newRingsize)-1.5
              if(parseFloat(upSize) >= parseFloat(ringSize)) {
                charges = 125
                currentCharge = 75
              } else if((parseFloat(downSize) <= parseFloat(ringSize)) && (parseFloat(upSize) >= parseFloat(ringSize))){
                charges = 125
                currentCharge = 75
              } else{
                charges = 150
                currentCharge = 100
              }
              vowReasonCharges.push({ reason: `Need a different ring size (${ringSize})`, quantity:1, charge: parseFloat(currentCharge).toFixed(2), option: item.key })
            }
          } else if(months > 12 && ringSize && ringSize > 0) {
            shippingCharge = true

            if(_.size(ringSizeItem) === 0){
              if(parseFloat(upSize) >= parseFloat(ringSize)) {
                charges = 125
                currentCharge = 75
              } else if((parseFloat(downSize) <= parseFloat(ringSize)) && (parseFloat(upSize) >= parseFloat(ringSize))){
                charges = 125
                currentCharge = 75
              } else {
                charges = 150
                currentCharge = 100
              }
              vowReasonCharges.push({ reason: `Need a different ring size (${ringSize})`, quantity:1, charge: parseFloat(currentCharge).toFixed(2), option: item.key })
            } else{
              upSize = parseFloat(ringSizeItem[0].newRingsize)+1.5
              downSize = parseFloat(ringSizeItem[0].newRingsize)-1.5
              if(parseFloat(upSize) >= parseFloat(ringSize)) {
                charges = 125
                currentCharge = 75
              } else if((parseFloat(downSize) <= parseFloat(ringSize)) && (parseFloat(upSize) >= parseFloat(ringSize))){
                charges = 125
                currentCharge = 75
              } else {
                charges = 150
                currentCharge = 100
              }
            }
            vowReasonCharges.push({ reason: `Need a different ring size (${ringSize})`, quantity:1, charge: parseFloat(currentCharge).toFixed(2), option: item.key })
          } else {
            vowReasonCharges.push({ reason: `Need a different ring size (${ringSize})`, quantity:1, charge: '0.00', option: item.key })
          }
        }
        if(item.key === 'Missing accent diamond'){
          charges = months <= 12 ? charges+item.charge : charges+item.charge1
          currentCharge =  months <= 12 ? item.charge : item.charge1
          vowReasonCharges.push({ reason: 'Missing accent diamond', quantity:1, charge: parseFloat(currentCharge).toFixed(2), option: item.key })
        }
        if(item.key === 'Missing side stone'){
          charges = charges+item.charge
          currentCharge = item.charge
          vowReasonCharges.push({ reason: 'Missing side stone', quantity:1, charge: parseFloat(currentCharge).toFixed(2), option: item.key })
        }
        if(item.key === 'Ring is misshapen or bent'){
          charges = charges+item.charge
          currentCharge = item.charge
          vowReasonCharges.push({ reason: 'Ring is misshapen or bent', quantity:1, charge: parseFloat(currentCharge).toFixed(2), option: item.key })
        }
        if(item.key === 'Damaged prongs'){
          charges = charges+item.charge
          currentCharge = item.charge
          vowReasonCharges.push({ reason: 'Damaged prongs', quantity:1, charge: parseFloat(currentCharge).toFixed(2), option: item.key })
        }
        if(item.key === 'Polishing'){
          charges = charges+item.charge
          currentCharge = item.charge
          vowReasonCharges.push({ reason: 'Polishing', quantity:1, charge: parseFloat(currentCharge).toFixed(2), option: item.key })
        }
        if(item.key === 'Refinishing'){
          charges = charges+item.charge
          currentCharge = item.charge
          vowReasonCharges.push({ reason: 'Refinishing', quantity:1, charge: parseFloat(currentCharge).toFixed(2), option: item.key })
        }
        if(item.key === 'Diamond is loose'){
          charges = months <= 12 ? charges+item.charge : charges+item.charge1
          currentCharge = months <= 12 ? item.charge : item.charge1
          vowReasonCharges.push({ reason: 'Diamond is loose', quantity:1, charge: parseFloat(currentCharge).toFixed(2), option: item.key })
        }
        if(item.key === 'Loose diamond in band'){
          charges = charges+item.charge
          currentCharge = item.charge
          vowReasonCharges.push({ reason: 'Loose diamond in band', quantity:1, charge: parseFloat(currentCharge).toFixed(2), option: item.key })
        }
        item.value = currentCharge
        updatedRepairOptions.push(item)

      })
      cardBox = charges > 0 ? true : false
      cardBox = _.get(paymentMethods, '_id', '') !== '' ? false : cardBox
      addNewCard = cardBox ? true : false
      this.setState({ totalFee: charges, shippingCharge, updatedRepairOptions, repairFee: charges, cardBox, addNewCard, vowReasonCharges })
      if(charges > 0){
        await this.calculateTax(charges, shippingCharge)
      } else{
        this.setState({ isLoader: false })
      }
      return (true)
    }else{
      cardBox = charges > 0 ? true : false
      cardBox = _.get(paymentMethods, '_id', '') !== '' ? false : cardBox
      addNewCard = cardBox ? true : false
      this.setState({ totalFee: charges, repairFee: charges, cardBox, addNewCard, vowReasonCharges, isLoader: false })
      return (true)
    }
  }

  async calculateTax(charges, shippingCharge){
    const { orderDetail, product } = this.state
    let taxFee = 0
    let amount = shippingCharge ? charges - 50 : charges
    const lineItems = [
      {
        title: _.get(product, 'title', ''),
        variant_title: _.get(product, 'variant_title', ''),
        id: _.get(product, 'id', ''), 
        amount: parseFloat(amount), 
        quantity: 1,
        type: 'repair'
      }
    ]
    if(shippingCharge){
      lineItems.push({
        title: 'shipping Fee',
        variant_title: 'shipping Fee',
        id: Date.now(), 
        amount: 50, 
        quantity: 1,
        type: 'shipping'
      })
    }
    const obj = {
      taxType: 'repair',
      lineItems,
      shippingAddress: _.get(orderDetail,'order.shipping_address',''),
      customerCode: _.get(orderDetail,'order.customer.email','')
    }
    const { value } = await this.props.calculateTax(obj)
    if(_.get(value,'status',false)){
      _.map(_.get(value,'taxSummary',[]), taxLine => {
        if(_.get(taxLine,'tax',0)){
          taxFee += parseFloat(_.get(taxLine,'tax',0))
        }
      })
    }
    charges += taxFee
    this.setState({ 
      totalFee: charges, 
      taxFee: (parseFloat(taxFee)).toLocaleString(undefined, {minimumFractionDigits: 2}), 
      taxSummary: _.get(value,'taxSummary',[]), 
      isLoader: false 
    })
    return (true)
  }

  editShippingAddress(key) {
    if (key === 'editAddressModal') {
      const valAddress = _.cloneDeep(this.state.shippingAddress)
      this.setState({ [key]: true, valAddress })
      this.forceUpdate()
    }
  }
  handleChangeAddress(e) {
    const { shippingAddress } = this.state
    shippingAddress[e.target.name] = e.target.value
    this.setState({ shippingAddress })
    this.forceUpdate()
  }


  handlePaymentDetailChange = event => {
    this.setState({ [event.target.name]:event.target.value })
    if (event.target.name === 'cardNumber') {
      this.handleCreditCardChange(event.target.value)
    }
    this.forceUpdate()
  }

  handleCreditCardChange = (cardNumber) => {
    var cardType = creditCardType(cardNumber)
    this.setState({
      cvvLength: _.get(cardType[0], 'code.size', 3),
    })
  }

  validateCardDetails() {
    const err = {}
    const { cardholderName, cardNumber, cardExpireDate, cvvCode } = this.state
    if (cardNumber === '') {
      err.cardNumber = 'Card number is required'
    }
    if (cardholderName === '') {
      err.cardholderName = 'Card name is required'
    }
    if (cvvCode === '') {
      err.cvvCode = 'Cvv is required'
    }
    if (cardExpireDate === '') {
      err.cardExpireDate = 'Expiration date is required'
    }
    return err
  }

  handleChangeCountry = (name, value) => {
    const { shippingAddress } = this.state
    shippingAddress[name] = value
    if(name === 'country'){
      let countryshortForm = getCode(value)
      if(!countryshortForm){
        const countiesCodeName = getData()
        _.map(countiesCodeName, list => {
          if(_.get(list, 'name', '') && _.get(list, 'name', '').indexOf(value) !== -1){
            countryshortForm = _.get(list, 'code', '')
          }
        }) 
      }
      this.setState({ phoneNumberCountry: countryshortForm, shippingAddress })
    }
    this.forceUpdate()
  }

  handleChangePhone = (name, value) => {
    const { shippingAddress } = this.state
    shippingAddress[name] = value
    this.setState({ shippingAddress })
    this.forceUpdate()
  }

  handleEditAddress = async() => {
    const { shippingAddress, orderDetail, repairFee, shippingCharge } = this.state
    const err = {}
    if (!shippingAddress.first_name || shippingAddress.first_name === '' ) {
      err.first_name = 'First Name is required.'
    }
    if (!shippingAddress.last_name || shippingAddress.last_name === '' ) {
      err.last_name = 'Last Name is required.'
    }
    if (!shippingAddress.address1 || shippingAddress.address1 === '' ) {
      err.address = 'Address is required.'
    }
    if (!shippingAddress.city || shippingAddress.city === '' ) {
      err.city = 'City is required.'
    }
    if (!shippingAddress.province || shippingAddress.province === '' ) {
      err.state = 'State is required.'
    }
    if (!shippingAddress.province || shippingAddress.province === '' ) {
      err.state = 'State is required.'
    }
    if (!shippingAddress.country || shippingAddress.country === '' ) {
      err.state = 'Country is required.'
    }
    if(Object.keys(err).length === 0) {
      const obj = {
        id: _.get(orderDetail, '_id', ''),
        orderId: _.get(orderDetail, 'order.id', ''),
        shipping_address: shippingAddress,
        addressType: 'shippingAddress'
      }
      this.setState({ isLoader: true })
      const { value } = await this.props.updateShippingAddress(obj)
      if(_.get(value, 'status', false)) {
        message.error(_.get(value, 'message', ''), () => {
          this.setState({ errSup: {}, shippingAddress: _.get(value, 'shippingAddress', '') })
        })
        this.forceUpdate()
        if(repairFee > 0){
          await this.calculateTax(repairFee, shippingCharge)
          this.setState({ editAddressModal: false })
        } else{
          this.setState({ editAddressModal: false, isLoader: false })
        }
        await this.checkAddressValidation(_.get(value, 'shippingAddress', ''))
      } else {
        message.error(_.get(value, 'message', ''), () => {
          this.setState({ isLoader: false, errorMessage: _.get(value, 'message.shipping_address', []) })
        })
      }
    } else {
      this.setState({ errSup: err, isLoader: false })
    }
    // this.componentDidMount()
  }

  addNewCardDetails= () =>{
    this.setState({ cardBox: true, addNewCard: true, removeCard: true })
    this.forceUpdate()
  }

  removeCard = () =>{
    this.setState({ cardBox: false, addNewCard: false, removeCard: false})
    this.forceUpdate()
  }

  openChat(){
    const { chatOpen } = this.state
    if(chatOpen === 0){
      window.GorgiasChat.open()
      this.setState({ chatOpen: 1 })
    } else{
      window.GorgiasChat.close()
      this.setState({ chatOpen: 0 })
    }
  }

  checkAddressValidation = async (shippingAddress) => {
    const { value } = await this.props.checkAddressValidation({ shippingAddress })
    if(!_.get(value, 'status', false)){
      this.setState({ messageShown: true })
      message.error(_.get(value, 'message', ''), () => {
        this.setState({ messageShown: false, invalidAddress: true })
      })
    } else{
      this.setState({ invalidAddress: false })
    }
  }

  render() {
    const { orderDetail, invalidAddress, userName, product, isLoader, shippingAddress, RepairReason, errSup, images, cardholderName, cardExpireDate, cardNumber, cvvCode, cvvLength, cardBox, paymentMethods, shippingCharge, updatedRepairOptions } = this.state


    const image = _.filter(_.get(orderDetail, "images", []), filter => {
      return String(filter.id) === String(product.id);
    })
    const title = _.get(product, "name", "").split("/")

    // updatedRepairOptions = _.uniq(updatedRepairOptions)
    return (
      <>
        {isLoader ? (
            <Loader />
          ) : (
          <>
          <TopNavigation userName={userName} orderDetail={orderDetail} {...this.props}/>
          <div className="mini-container-wrapper">
            <div className="mini-container">
              <div className="head-wrap head-wrap-space">
                <div className="head-wrap-back">
                  <button type="button" className="btn btn-sm btn-back" onClick={this.goBack.bind(this)}>
                    <BackIcon/>
                  </button>
                </div>
                <div className="head-wrap-title">
                  Overview
                </div>
              </div>
              <div className="horizontal-product">
                <div className="product-thumbnail">
                  {_.get(image, "[0].image", "/default.webp") !== "" && (
                    <img
                      src={_.get(image, "[0].image", "/default.webp")}
                      alt={""}
                    />
                  )}
                </div>
                <div>
                  <h4 className="product-name">{_.get(product,'title','')}</h4>
                  <p className="product-shape-color">{title[1]}</p>
                </div>
              </div>
              <div className="mini-container-inner">

                {
                  this.state.repairFee > 0 ? (
                    <div className="form-group">
                      <label className="label-info-text">What do you need repaired?</label>
                      { updatedRepairOptions && _.map(updatedRepairOptions, (reason,i) =>{
                        if(reason.key === 'Need a different ring size'){
                          return(
                            <div key={i}>
                              <p>{reason.key} ({this.state.ringSize})<span className="amount-text">${reason.value}</span></p>
                            </div>
                          )
                        } else{
                          return(
                            <div key={i}>
                              <p>{reason.key} <span className="amount-text">${reason.value}</span></p>
                            </div>
                          )
                        }
                      })}
                    </div>
                  ) : (
                    <div className="form-group">
                      <label className="label-info-text">What do you need repaired?</label>
                      { RepairReason && _.map(RepairReason, (reason,i) =>{
                        if(reason === 'Need a different ring size'){
                          return(
                            <div key={i}>
                              <p>{reason} ({this.state.ringSize})</p>
                            </div>
                          )
                        } else{
                          return(
                            <div key={i}>
                              <p>{reason}</p>
                            </div>
                          )
                        }
                        })}
                    </div>
                  )
                }
                {/*<div className="form-group">
                  <label className="label-info-text">What do you need repaired?</label>
                  {RepairReason && _.map(RepairReason, (reason,i) =>{
                    return(
                      <div key={i}>
                        <p>{reason} <span className="amount-text">$302</span></p>
                      </div>
                  )})
                  }
                </div>*/}
                <div className="form-group">
                  <label className="label-info-text">More Details </label>
                  <p>{this.state.someDetails}</p>
                </div>
                <div className="form-group">
                  <label className="label-info-text">Photo</label>
                    <Slider
                      {...settings}
                      className="slick-carousel-slider"
                    >
                      {images && _.map(images, (img,i) =>{
                        return(
                          <div key={i} className="slick-carousel-slider-items">
                            <div className="product-thumbnail mb-0">
                              <img src={img} alt=""/>
                            </div>
                          </div>
                        )
                      })}
                    </Slider>
                </div>
                <div className="form-group">
                  <label className="label-info-text">Return Address</label>
                  <div className="address-info">
                    {this.state.first_name} {this.state.last_name}<br/>
                    {this.state.address1} {this.state.address2}, {this.state.city}, {this.state.province_code} {this.state.zip}
                   {/* <span onClick={this.editShippingAddress.bind(this, 'editAddressModal')} className="btn-edit-address">Edit Address</span>*/}
                  </div>
                </div>
                {
                  !cardBox && this.state.repairFee > 0  && !this.state.addNewCard ? (
                    <div className="">
                      <ul className="payment-card-list">
                        <li>
                          <div className="payment-card-info"><span className="card-icon">
                          {
                            _.get(paymentMethods, 'card.brand', '') === 'Visa' ? (
                              <img src={VisaIcon} alt="" height="32"/>
                            ) : (
                              _.get(paymentMethods, 'card.brand', '') === 'MasterCard' ? (
                                  <img src={MasterCardIcon} alt="" height="32"/>
                                ) : (
                                  _.get(paymentMethods, 'card.brand', '') === 'American Express' ? (
                                      <img src={AmericanExpressIcon} alt="" height="32"/>
                                    ) : (
                                      _.get(paymentMethods, 'card.brand', '') === 'Discover' ? (
                                          <img src={DiscoverIcon} alt="" height="32"/>
                                        ) : (
                                          _.get(paymentMethods, 'card.brand', '') === 'Diners Club' ? (
                                              <img src={DinersClubIcon} alt="" height="32"/>
                                            ) : (
                                              _.get(paymentMethods, 'card.brand', '') === 'UnionPay' ? (
                                                  <img src={UnionPayIcon} alt="" height="32"/>
                                                ) : (
                                                  _.get(paymentMethods, 'card.brand', '') === 'Maestro' ? (
                                                      <img src={MaestroIcon} alt="" height="32"/>
                                                    ) : (
                                                      ''
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                          }

                          </span><span class="card-number">{_.get(paymentMethods, 'card.brand', '')}****{_.get(paymentMethods, 'card.last4', '')}</span></div>
                          <div>Expiration Date {_.get(paymentMethods, 'card.exp_month', '')}/{`${_.get(paymentMethods, 'card.exp_year', '')}`.toString().substr(-2)}</div>
                          {/* <div>Expire date 20/7<button className="btn btn-link w-120">Expire date 20/7</button></div> */}
                        </li>
                      </ul>
                      <div className="form-group">
                        <button type="button" className="btn btn-dark w-100" onClick={this.addNewCardDetails.bind(this)}>Add New Card</button>
                      </div>
                      {/*<button type="button" className="btn btn-dark w-100" onClick={this.submit.bind(this)}>Submit</button>*/}
                      <div className="form-group">
                        <label className="label-info-text">Summary</label>
                        <ul className="summary-info">
                          <li>
                            <span>Repair Fee</span> <span>${shippingCharge ? parseFloat(parseFloat(this.state.repairFee)- 50.00).toLocaleString(undefined, {minimumFractionDigits: 2}) : parseFloat(parseFloat(this.state.repairFee)).toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
                          </li>
                          <li>
                            <span>Shipping</span> <span>{shippingCharge ? '$50.00' : 'FREE'}</span>
                          </li>
                          <li>
                            <span>Tax</span> <span>${parseFloat(parseFloat(this.state.taxFee)).toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
                          </li>
                          <li>
                            <span>Total</span> <span>${parseFloat(parseFloat(this.state.totalFee)).toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    ''
                  )
                }
                {
                  cardBox ? (
                  <div>
                    <hr className="horizontal-line"/>
                    <div className="form-group">
                      <label className="form-label">Cardholder name</label>
                      <input
                        type="text"
                        name="cardholderName"
                        className="form-control"
                        onChange={this.handlePaymentDetailChange}
                        // onBlur={handleBlur}
                        value={cardholderName}
                        required
                      />
                      <div className="invalid-feedback">{errSup && errSup.cardholderName ? errSup.cardholderName : ""}</div>
                    </div>

                    <div className="form-group material-textfield">
                      <label className="form-label">Card Number</label>
                      <InputMask
                        maskChar={null}
                        mask="9999 9999 9999 9999"
                        name="cardNumber"
                        className="form-control"
                        onChange={this.handlePaymentDetailChange}
                        // onBlur={handleBlur}
                        value={cardNumber}
                        required
                      />
                      <button type="button" className="btn info-tooltip"><LockIcon/></button>
                      <div className="invalid-feedback">{errSup && errSup.cardNumber ? errSup.cardNumber : ""}</div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">MM/YY</label>
                          <InputMask
                            mask="99/99"
                            maskChar={null}
                            name="cardExpireDate"
                            className="form-control"
                            onChange={this.handlePaymentDetailChange}
                            // onBlur={handleBlur}
                            value={cardExpireDate}
                            required
                          />
                          <div className="invalid-feedback">{errSup && errSup.cardExpireDate ? errSup.cardExpireDate : ""}</div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <label className="form-label">CVV</label>
                          <InputMask
                            mask={cvvLength === 3 ? '999' : '9999'}
                            maskChar={null}
                            name="cvvCode"
                            className="form-control"
                            onChange={this.handlePaymentDetailChange}
                            // onBlur={handleBlur}
                            value={cvvCode}
                            required
                          />
                          <Tooltip placement="top" title="3-digit security code usually found on the back of
                    your card. American Express cards have a 4-digit
                    code located on the front.">
                            <button type="button" className="btn info-tooltip"><QuestionIcon/></button>
                          </Tooltip>
                          <div className="invalid-feedback">{errSup && errSup.cvvCode ? errSup.cvvCode : ""}</div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="form-group material-textfield">
                      <label className="form-label">Coupon Code</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                      />
                      <button className="btn btn-dark coupon-code-apply">Apply</button>
                      <div className="invalid-feedback"></div>
                    </div> */}

                    {
                     this.state.removeCard ? (
                       <div className="form-group">
                         <button type="button" className="btn btn-dark w-100" onClick={this.removeCard.bind(this)}>Cancel</button>
                       </div>
                     ) : ('')
                    }
                    <div className="form-group">
                      <label className="label-info-text">Summary</label>
                      <ul className="summary-info">
                        <li>
                          <span>Shipping</span> <span>{shippingCharge ? '$50' : 'FREE'}</span>
                        </li>
                        <li>
                          <span>Repair Fee</span> <span>${shippingCharge ? (parseInt(this.state.repairFee)- 50) : this.state.repairFee}</span>
                        </li>
                      </ul>
                    </div>
                    {/*<button type="button" className="btn btn-dark w-100" onClick={this.submit.bind(this)}>Submit</button>*/}
                    </div>
                  ) : ('')
                }
                <div>
                  <button 
                    type="button" 
                    className="btn btn-dark w-100" 
                    disabled={invalidAddress}
                    onClick={this.submit.bind(this)}
                  >
                    Submit
                  </button>
                </div>
                <div className="chat-with-us">
                  Have questions? <span onClick={this.openChat.bind(this)}>Chat with us</span>
                </div>
              </div>
            </div>
          </div>
          <ReactModal
            isOpen={this.state.editAddressModal}
            onRequestClose={this.closeModal.bind(this)}
            ariaHideApp={false}
            contentLabel="Edit Shippping Address"
            portalClassName="react-modal"
            overlayClassName="modal"
            className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
          >
            <div className="modal-content">
              <div className="react-modal-header">
                <h5 className="react-modal-title">Edit Shippping Address</h5>
                <button type="button" className="btn react-modal-close" onClick={this.closeModal.bind(this)}><CloseIcon/></button>
              </div>
              <div className="react-modal-body">
                {this.state.errorMessage && this.state.errorMessage.length ? (
                  <div className="notice-attention">
                    <div>
                      <h6 className="notice-attention-heading">
                        There was {this.state.errorMessage.length} error on this form:
                      </h6>
                      {
                        _.map(this.state.errorMessage, message  => {
                          return(
                            <p className="notice-attention-subheading">
                              {message}
                            </p>
                          )
                        })
                      }
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">First Name <span className="text-danger">*</span></label>
                      <input
                        name="first_name"
                        value={_.get(shippingAddress, "first_name", "")}
                        onChange={this.handleChangeAddress.bind(this)}
                        type="text"
                        className="form-control"
                        required
                      />
                      <div className="invalid-feedback">{errSup && errSup.first_name ? errSup.first_name : ""}</div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Last Name <span className="text-danger">*</span></label>
                      <input
                        name="last_name"
                        value={_.get(shippingAddress, "last_name", "")}
                        onChange={this.handleChangeAddress.bind(this)}
                        type="text"
                        className="form-control"
                        required
                      />
                      <div className="invalid-feedback">{errSup && errSup.last_name ? errSup.last_name : ""}</div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">company </label>
                  <input
                    name="company"
                    value={_.get(shippingAddress, "company", "")}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Address <span className="text-danger">*</span></label>
                  <input
                    name="address1"
                    value={_.get(shippingAddress, "address1", "")}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                  <div className="invalid-feedback">{errSup && errSup.address ? errSup.address : ""}</div>
                </div>
                <div className="form-group">
                  <label className="form-label">Apartment, suite, etc. (optional) </label>
                  <input
                    name="address2"
                    value={_.get(shippingAddress, "address2", "")}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">City <span className="text-danger">*</span></label>
                  <input
                    name="city"
                    value={_.get(shippingAddress,'city','')}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Country/Region <span className="text-danger">*</span></label>
                      <CountryDropdown
                        name="country"
                        value={_.get(shippingAddress, "country", "")}
                        onChange={this.handleChangeCountry.bind(this, 'country')}
                        classes="form-control material-textfield-input"
                      />
                      <div className="invalid-feedback">{errSup && errSup.country ? errSup.country : ""}</div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">State/Provision <span className="text-danger">*</span></label>
                      <RegionDropdown
                        name="province"
                        country={_.get(shippingAddress, "country", "")}
                        value={_.get(shippingAddress, "province", "")}
                        onChange={this.handleChangeCountry.bind(this, 'province')}
                        classes="form-control material-textfield-input"
                      />
                      <div className="invalid-feedback">{errSup && errSup.state ? errSup.state : ""}</div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group material-textfield">
                      <label className="form-label">Zip <span className="text-danger">*</span></label>
                      <input
                        name="zip"
                        value={_.get(shippingAddress,'zip','')}
                        onChange={this.handleChangeAddress.bind(this)}
                        type="number"
                        className="form-control material-textfield-input"
                        required
                      />
                      <div className="invalid-feedback">{errSup && errSup.zipcode ? errSup.zipcode : ""}</div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                <div className="country-input-field">
                  <PhoneInput
                    name="phone"
                    defaultCountry={this.state.phoneNumberCountry}
                    international={true}
                    value={_.get(shippingAddress, "phone", "")}
                    onChange={this.handleChangePhone.bind(this, 'phone')}
                    classes="form-control"
                  />
                </div>
                </div>
                <button onClick={this.handleEditAddress.bind(this)} className="btn btn-dark w-100">
                  Save
                </button>
              </div>
            </div>
          </ReactModal>
          </>
        )}
      </>
    )
  }
}
