import { connect } from 'react-redux'
import { getOrderDetail, uploadImage } from "../../store/order/duck"
import RcfjRepairComponent from './component'

const RcfjRepairContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
  	getOrderDetail,
  	uploadImage,
  }
)(RcfjRepairComponent)

export default RcfjRepairContainer
