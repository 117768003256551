import React, { PureComponent } from 'react'
import { CloseIcon } from '../../components/icons'
import Logo from '../../images/Rc-logo.png'
import Cookies from 'js-cookie'
import _ from 'lodash'
import Loader from '../../components/loader'
import './styles.scss'
import { message  } from 'antd'

export default class LoginComponent extends PureComponent {

  static propTypes = {
    // PropTypes go here
  }

  constructor(props){
    super(props)
    this.state = {
      isLoader: false,
      orderNumber:'',
      zipCodeOrEmailId: '',
      showMessage: false,
      chatOpen: 0
    }
  }

  handleOnChange(e){
    this.setState({ [e.target.name]: e.target.value, showMessage: false })
  }

  componentDidMount(){
    // on login screen remove customer cookies and show login screen
    Cookies.remove('customerId')
    Cookies.remove('orderId')
    // const orderId = Cookies.get('orderId')
    // const customerId = Cookies.get('customerId')
    // if(orderId && customerId){
    //   this.props.history.push(`/detail/${orderId}`)
    // } else {
    //   Cookies.remove('customerId')
    //   Cookies.remove('orderId')
    // }
  }

  async login(){
    const obj = {
      orderNumber: this.state.orderNumber,
      zipCodeOrEmailId: this.state.zipCodeOrEmailId
    }
    this.setState({ isLoader: true })
    const { value } = await this.props.login(obj)
    if(_.get(value,'status',false) === true){
      message.error(_.get(value, 'message', ''), () => {
        this.setState({ isLoader: false })
      })
      let customerId = _.get(value,'data.orderApiResponse.customer.id','')
      let orderId = _.get(value,'data.orderApiResponse.order_number','')
      let tags = _.get(value,'data.orderType','')
      Cookies.set('customerId',customerId)
      Cookies.set('orderId',orderId)
      Cookies.set('tags',tags)
      this.props.history.push(`/detail/${orderId}`)
    } else{
      this.setState({ isLoader: false, showMessage: true, orderNumber: '', zipCodeOrEmailId: '' })
    }
  }

  handleEnter(event){
    var code = event.keyCode || event.charCode
    if (code === 13) {
      this.login()
    }
  }

  goToAccount(){
    Cookies.remove('customerId')
    Cookies.remove('orderId')
    window.open(`${process.env.REACT_APP_ACCOUNT_URL}`,"_self")
  }

  openChat(){
    const { chatOpen } = this.state
    if(chatOpen === 0){
      window.GorgiasChat.open()
      this.setState({ chatOpen: 1 })
    } else{
      window.GorgiasChat.close()
      this.setState({ chatOpen: 0 })
    }
  }

  render() {
    return (
      <>
        <>
          {this.state.isLoader ? <Loader /> : ('')}
        </>
        <>
          <div className="wrapper-section login__wrapper">
            <div className="wrapper-close">
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.goToAccount.bind(this)}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="wrapper-container">
              <div className="wrapper-inner">
                <div className="wrapper-header">
                  <img className="logo" src={Logo} alt="" />
                  {/*<h4 className="title">Enter your order number and shipping zip code to process your repair or print your return label </h4>*/}
                  <h4 className="title">
                    Enter your order number and shipping zip code to process your repair.
                    <div className="subtitle">
                      Looking to return?
                      <a href={process.env.REACT_APP_RETURN_APP_URL} className="text-black underline cta-button ml-1">
                        Click Here
                      </a>
                    </div>
                  </h4>
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    placeholder="Enter Order Number"
                    type="text"
                    name="orderNumber"
                    value={this.state.orderNumber}
                    onChange={this.handleOnChange.bind(this)}
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    placeholder="Enter Email Address or Shipping Zip Code "
                    type="text"
                    name="zipCodeOrEmailId"
                    value={this.state.zipCodeOrEmailId}
                    onChange={this.handleOnChange.bind(this)}
                    onKeyPress={this.handleEnter.bind(this)}
                  />
                </div>
                {this.state.showMessage &&
                this.state.orderNumber === "" &&
                this.state.zipCodeOrEmailId === "" ? (
                  <div className="form-group text-center">
                    We're having some trouble locating your order! Not to worry-- our
                    Customer Concierge team can help look this up for you. Please email
                    <a href="mailto:info@ringconcierge.com" className="text-black underline cta-button ml-1 link">
                      info@ringconcierge.com
                    </a>
                    {" "}and a representative will be able to help!
                  </div>
                ) : (
                  ""
                )}
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-dark w-100"
                    disabled={
                      this.state.orderNumber === "" || this.state.zipCodeOrEmailId === ""
                        ? true
                        : false
                    }
                    onClick={this.login.bind(this)}
                  >
                    Find my Order
                  </button>
                </div>
                <div className="wrapper-footer">
                  Already have an account?{" "}
                  <a href={process.env.REACT_APP_ACCOUNT_URL} className="cta">
                    Login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    )
  }
}
