import React from "react"
import get from "lodash/get"
import { Link } from "react-router-dom"
import { CaretRightIcon } from "../../../components/icons"

export default function VariantAction({
  productType,
  orderNumber,
  openChat,
  handleCheckValidation,
  rcfjResizeId,
  RCFJOrder,
  rcfjResizeActive,
  rcfjConfirmActive,
  rcfjRepairId,
  rcfjRepairActive,
  ConfirmSizeMessage,
}) {
  return (
    <div>
      <h4 className="product-name">{get(RCFJOrder, "title", "")}</h4>
      <p className="product-shape-color">
        {get(RCFJOrder, "variant_title", "")}
      </p>
      {!["Lab Grown Engagement Rings", "Wedding Bands", "Mens Rings"].includes(productType) ? (
        <ul className="select-action-list">
          {rcfjResizeId && rcfjResizeId.length === 0 ? (
            <li>
              <Link
                className={rcfjResizeActive === false ? "disabled-link" : ""}
                to={`/rcfj-resize/${orderNumber}`}
                onClick={() => handleCheckValidation()}
              >
                <span className="select-action-title">Resize</span>{" "}
                <CaretRightIcon className="caret-icon" />
              </Link>
            </li>
          ) : (
            <li>
              <Link
                to={`/rcfj-resize/request-confirmation/${orderNumber}`}
                onClick={() => handleCheckValidation()}
              >
                <span className="select-action-title">Resize</span>{" "}
                <CaretRightIcon className="caret-icon" />
              </Link>
            </li>
          )}
          {rcfjRepairId && rcfjRepairId.length === 0 ? (
            <li>
              <Link
                className={rcfjRepairActive === false ? "disabled-link" : ""}
                to={`/rcfj-repair/${orderNumber}`}
                onClick={() => handleCheckValidation()}
              >
                <span className="select-action-title">Repair</span>{" "}
                <CaretRightIcon className="caret-icon" />
              </Link>
            </li>
          ) : (
            <li>
              <Link
                to={`/rcfj-repair/request-confirmation/${orderNumber}`}
                onClick={() => handleCheckValidation()}
              >
                <span className="select-action-title">Repair</span>{" "}
                <CaretRightIcon className="caret-icon" />
              </Link>
            </li>
          )}
          <li>
            <Link
              className={rcfjConfirmActive === false ? "disabled-link" : ""}
              to={`/confirm-size/${orderNumber}`}
              onClick={() => handleCheckValidation()}
            >
              <span className="select-action-title">Confirm Size</span>{" "}
              <CaretRightIcon className="caret-icon" />
            </Link>
          </li>
          {ConfirmSizeMessage ? (
            <div className="alert-for-info">
              Oops! Looks like your order is past the confirmation window.{" "}
              <span className="link" onClick={() => openChat()}>
                Click here
              </span>{" "}
              to chat with our Customer Concierge team.
            </div>
          ) : (
            ""
          )}
        </ul>
      ) : (
        <ul className="select-action-list">
          {["Wedding Bands", "Mens Rings"].includes(productType) ? (
            <p class="select-action-title">If you are in need of a repair or resize, please reach out to our Customer Concierge Team at <a href="mailto:info@ringconcierge.com" className="link">info@ringconcierge.com</a> and they will assist you further!</p>
          ) : ( 
            ""
          )}
          <li>
            <Link onClick={() => openChat()}>
              <span className="select-action-title">Speak with a Specialist</span>{" "}
              <CaretRightIcon className="caret-icon" />
            </Link>
          </li>
        </ul>
      )}
    </div>
  )
}
