import { connect } from 'react-redux'
import { getOrderDetail } from "../../../store/order/duck"
import CustomSizeConfirmationComponent from './component'

const CustomSizeConfirmationContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
  	getOrderDetail
  }
)(CustomSizeConfirmationComponent)

export default CustomSizeConfirmationContainer
