import React, { PureComponent } from "react"
import "./styles.scss"
import _ from "lodash"

import Loader from '../../components/loader'
import TopNavigation from '../TopNavigation/component'
import Cookies from 'js-cookie'
import RCFJSku from '../../config/index.json' //(exclude-list sku's keep commented as of now)
import config from '../../config/index'
import ProductImage from "./Partially/ProductImage"
import VariantAction from "./Partially/VariantAction"
import ProductAction from "./Partially/ProductAction"

const RingSizerSku = config.RingSizerSku


export default class SelectActionComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      orderDetail: {},
      product: {},
      userName: '',
      isLoader: true,
      isActive: true,
      chatOpen: 0,
      tags: '',
      productId: '',
      RCFJOrder: '',
      rcfjProductDetail: '',
      RCFJItems: [],
      shippingAddress: ''
    }
  }

  componentDidMount = async () => {
    Cookies.remove('checkValidation')
    const data = {
      orderId: Cookies.get('orderId'),
      customerId: Cookies.get('customerId')
    }
    let productId = Cookies.get('productId')
    let RCFJOrder = ''
    let productDetail = ''
    const { value } = await this.props.getOrderDetail(data)
    if (_.get(value, 'status', false)) {
      let orderDetail = _.get(value, 'data', {})
      let isLeapOrder = _.includes(_.get(orderDetail, 'orderTags', []), 'Leap')
      if(!_.includes([null,undefined,'null',''], productId)){
        RCFJOrder = _.find(_.get(orderDetail, "order.line_items", []), filter => {
          return String(filter.id) === String(productId);
        })
        let productID = _.get(RCFJOrder, 'product_id', '')
        productDetail = await this.props.fetchProductDetails(productID)
      }
      this.setState({
        orderDetail,
        product: _.get(value, 'lItem', {}),
        userName: _.get(value, 'userName', {}),
        tags: Cookies.get('tags'),
        productId,
        isLoader: false,
        rcfjProductDetail: productDetail,
        RCFJOrder,
        shippingAddress: _.get(orderDetail, 'order.shipping_address', ''),
        isLeapOrder: isLeapOrder
      })
    } else {
      this.props.history.push('/no-page-found')
    }
  }

  openChat = () => {
    const { chatOpen } = this.state
    if (chatOpen === 0) {
      window.GorgiasChat.open()
      this.setState({ chatOpen: 1 })
    } else {
      window.GorgiasChat.close()
      this.setState({ chatOpen: 0 })
    }
  }

  handleCheckValidation = () => Cookies.set("checkValidation", true)

  render() {
    const { orderDetail, product, userName, isLoader, productId, rcfjProductDetail, shippingAddress, isLeapOrder } = this.state
    let image = []
    let RCFJOrder = this.state.RCFJOrder
    image = _.filter(_.get(orderDetail, "images", []), filter => {
      return String(filter.id) === String(product.id);
    })

    // allow user to do action on vow order when there is no open repair for that product 
    let allowActionDays = ''
    let repairActive = false
    let exchangeActive = false
    let returnActive = false
    let lostMyRingDisabled = false
    const title = _.get(product, "name", "").split("/")
    if (_.get(orderDetail, 'fulfillmentStatus', '') === 'delivered' && _.get(orderDetail, 'orderType', '') === 'VOW') {
      lostMyRingDisabled = true
      repairActive = true
      let deliveredDate = _.get(orderDetail, 'deliveredDate', '')

      if (_.includes([null, undefined, ''], deliveredDate)) {
        _.map(_.get(orderDetail, 'order.fulfillments', []), fulfillment => {
          if (_.get(fulfillment, 'shipment_status', '') === 'delivered') {
            const vowItem = _.find(_.get(fulfillment, 'line_items', []), item => {
              return String(_.get(item, 'id', '')) === String(_.get(product, 'id', ''))
            })
            if (vowItem) {
              deliveredDate = _.get(fulfillment, 'updated_at', '')
            }
          }
        })
      }

      if (deliveredDate) {
        allowActionDays = new Date(deliveredDate)
        // vow order should be allowed to repair/resize and return within 7 days of delivery
        allowActionDays.setDate(allowActionDays.getDate() + 7)
        if (new Date(deliveredDate) < new Date() && new Date() < allowActionDays) {
          exchangeActive = true
          returnActive = true
        }
      }
    }

    if (_.get(orderDetail, 'fulfillmentStatus', '') !== 'delivered') {
      let exchangeId = []
      exchangeId = _.filter(_.get(orderDetail, "requestId", []), filter => {
        return (filter.reason === "Exchange" || filter.reason === "Return")
      })
      if (exchangeId.length !== 0) {
        if (exchangeId[0].reason === 'Exchange') {
          exchangeActive = true
        } else if (exchangeId[0].reason === 'Return') {
          returnActive = true
        }
      }
    }

    if (['repairinitiated', 'repairreceived'].includes(_.get(orderDetail, 'fulfillmentStatus', ''))) {
      exchangeActive = false
      returnActive = false
    }

    let rcfjResizeActive = false
    let rcfjRepairActive = false
    let rcfjConfirmActive = false
    let ConfirmSizeMessage = false

    let quantityDropOption = []; let itemQunt = ''
    let rcfjResizeId = []
    let rcfjRepairId = []
    // Not needed now to exclude sku's https://trello.com/c/FGAg5qjc/11303
    // let skuList = _.get(RCFJSku, '[0].SKU', [])
    let tennisBraceletSku = _.get(RCFJSku, '[1].SKU', [])

    if (!_.includes([null, undefined, 'null', ''], productId)) {
      image = _.filter(_.get(orderDetail, "images", []), filter => {
        return String(filter.id) === String(productId);
      })
    }
    // check condition if its a leap order.
    if ((!_.includes([null,undefined,'null',''], productId) && _.get(shippingAddress,'country','') === 'United States') || (!_.includes([null,undefined,'null',''], productId) && isLeapOrder )) {
      // tags = 'RCFJ'
      let rcfjFulfillmentStatus = _.find(_.get(orderDetail, "fulfillmentStatusRCFJ", []), filter => {
        return String(filter.id) === String(productId);
      });
      let quantities = []; let Rejectquant = ''
      let sixtyDays = new Date(_.get(orderDetail, 'order.created_at', ''))
      sixtyDays.setDate(sixtyDays.getDate() + 60)

      // console.log(new Date(_.get(orderDetail, 'order.created_at', '')),'created_at')
      // console.log(sixtyDays,'sixtyDays')

      _.map([RCFJOrder], (item, i) => {
        let variant_1 = !_.includes([null, undefined, ''], _.get(item, 'variant_title', '')) ? `${_.get(item, 'variant_title', '')}`.split(' / ') : []
        _.map(variant_1, list => {
          if (list.toLowerCase().indexOf('i don\'t know my size') !== -1 && _.get(item, 'fulfillment_status', '') !== 'fulfilled' && _.get(item, 'fulfillable_quantity', '') !== 0) {
            rcfjResizeActive = false
            rcfjRepairActive = false
            if (new Date(_.get(orderDetail, 'order.created_at', '')) < new Date() && new Date() < sixtyDays) {
              rcfjConfirmActive = true
            } else {
              ConfirmSizeMessage = true
            }
          }
        })
        // check item have idkms item in properties for man size and woman size
        const isOtherSizeOption = _.find(_.get(item, 'properties', []), property => {
          return _.includes(['size', 'men-size', 'women-size', 'mens-size', 'womens-size'], `${_.get(property, 'name', '')}`.toLowerCase())
            // check default size option have idkms value
            && `${_.get(property, 'value', '')}`.toLowerCase().indexOf('i don\'t know my size; send me a ring sizing gauge') !== -1
            // check item is not fulfilled
            && _.get(item, 'fulfillment_status', '') !== 'fulfilled'
            // fulfillable quantity for idkms item should not be zero
            && _.get(item, 'fulfillable_quantity', '') !== 0
        })
        // if item have properties like size for man and woman then need to allow idkms option to select size
        if (isOtherSizeOption) {
          if (new Date(_.get(orderDetail, 'order.created_at', '')) < new Date() && new Date() < sixtyDays) {
            rcfjConfirmActive = true
          } else {
            ConfirmSizeMessage = true
          }
        }
      })

      if (rcfjFulfillmentStatus) {
        if (_.includes(['Necklaces', 'Rings', 'Bracelets', 'Anklets'], _.get(rcfjProductDetail, 'value.data.product.product_type', ''))) {
          rcfjResizeActive = true
        }
        quantities = _.find(_.get(rcfjFulfillmentStatus, "quantities", []), filter => {
          return (filter.type === 'initiatereturn' || filter.type === 'initiaterepair')
        });
        Rejectquant = _.find(_.get(rcfjFulfillmentStatus, "quantities", []), filter => {
          return filter.type === 'rejectreturn'
        });
        let perviousQunt = _.get(quantities, 'quantity', 0)
        /* New Repair orders from subOrders collection code start */
        let shopifyRepairFulfillmentStatusRCFJ = ''
        const shopifySubOrders = _.get(orderDetail, 'subOrders', [])
        if (_.size(shopifySubOrders)) {
          const subOrders = _.filter(_.get(orderDetail, "subOrders", []), filter => {
            return (_.get(filter, 'shopifyRepairDetails[0].type', '') === 'Repair Order')
          })
          const rcfjShopifyRepair = _.filter(subOrders, items => {
            return String(_.get(items, 'shopifyRepairDetails[0].lineItemId', 0)) === String(productId)
          })
          let ShopifyRepairQunt = 0
          _.map(rcfjShopifyRepair, repairs =>{
            ShopifyRepairQunt += parseInt(_.get(repairs, 'shopifyRepairDetails.[0].quantity', 0))
          })
          shopifyRepairFulfillmentStatusRCFJ = _.get(rcfjShopifyRepair, '[0].fulfillmentStatusRCFJ[0].fulfillmentStatus', '')
          perviousQunt = perviousQunt + ShopifyRepairQunt
        }
        /* New Repair orders from subOrders collection code end */
        if (!_.includes([undefined, null, ''], Rejectquant)) {
          perviousQunt = perviousQunt + _.get(Rejectquant, 'quantity', 0)
        }
        itemQunt = RCFJOrder.quantity - perviousQunt
        itemQunt = perviousQunt ===  RCFJOrder.quantity ? RCFJOrder.quantity : itemQunt
        for (let i = 1; i <= Math.abs(itemQunt); i++) {  quantityDropOption.push(i) }
        // check fulfillment status of leap orders if satisifed then allow users to initiate repairs.
        if((_.get(rcfjFulfillmentStatus, "fulfillmentStatus", '') === 'delivered' && _.get(rcfjFulfillmentStatus, "deliveredDate", null) !== null) || (_.get(rcfjFulfillmentStatus, 'fulfillmentStatus', 'partiallyShipped') && isLeapOrder )) {
          if(_.includes(['Necklaces','Rings', 'Bracelets','Anklets'],_.get(rcfjProductDetail,'value.data.product.product_type',''))){
            rcfjResizeActive = true
          }
          rcfjRepairActive = true
        } else {
          rcfjRepairActive = false
          rcfjResizeActive = false
        }
        if (perviousQunt === RCFJOrder.quantity) {
          rcfjResizeActive = false
          rcfjRepairActive = false
          rcfjResizeId = _.filter(_.get(orderDetail, "requestId", []), filter => {
            return (filter.reason === 'Repair' && filter.action === 'rcfjresize')
          })
          rcfjRepairId = _.filter(_.get(orderDetail, "requestId", []), filter => {
            return ((filter.reason === 'Repair' && filter.action === 'rcfjrepair') || (_.get(filter, 'shopifyRepairDetails[0].type', '') === 'Repair Order'))
          })
        }
        // allow multiple repair and repair when repair received back
        if ((['repairreceived'].includes(_.get(rcfjFulfillmentStatus, 'fulfillmentStatus', ''))) || (['repairreceived'].includes(shopifyRepairFulfillmentStatusRCFJ))) {
          rcfjRepairActive = true
          rcfjResizeActive = true
          rcfjResizeId = []
          rcfjRepairId = []
        }
      } else {
        itemQunt = RCFJOrder.quantity
        for (let i = 1; i <= itemQunt; i++) { quantityDropOption.push(i) }
      }
      // Not needed now to exclude sku's https://trello.com/c/FGAg5qjc/11303
      // if (RCFJOrder && _.includes(skuList, _.get(RCFJOrder, 'sku', ''))) {
      //   rcfjResizeActive = false
      //   rcfjRepairActive = false
      // }
      if (RCFJOrder && _.includes(tennisBraceletSku, _.get(RCFJOrder, 'sku', ''))) {
        rcfjResizeActive = false
      }

      //disable all action for ring size product 
      if (RCFJOrder && _.includes(RingSizerSku, _.get(RCFJOrder, 'sku', ''))) {
        rcfjRepairActive = false
        rcfjResizeActive = false
        rcfjConfirmActive = false
      }
    }
    return (
      <>
        <div>
          {isLoader ? (
            <Loader />
          ) : (
            <div>
              <TopNavigation
                userName={userName}
                orderDetail={orderDetail}
                {...this.props}
              />
              <div className="mini-container-wrapper">
                <div className="mini-container">
                  <ProductImage
                    orderNumber={_.get(orderDetail, "order.order_number", "")}
                    image={image}
                  />
                  {_.includes([null, undefined, "null", ""], productId) ? (
                    <ProductAction
                      orderNumber={_.get(orderDetail, "order.order_number", "")}
                      productType={_.get(rcfjProductDetail, "value.data.product.product_type", "")}
                      openChat={this.openChat}
                      orderDetail={orderDetail}
                      product={product}
                      title={title}
                      repairActive={repairActive}
                      exchangeActive={exchangeActive}
                      returnActive={returnActive}
                      lostMyRingDisabled={lostMyRingDisabled}
                    />
                  ) : (
                    <VariantAction
                      orderNumber={_.get(orderDetail, "order.order_number", "")}
                      productType={_.get(rcfjProductDetail, "value.data.product.product_type", "")}
                      openChat={this.openChat}
                      handleCheckValidation={this.handleCheckValidation}
                      rcfjResizeId={rcfjResizeId}
                      RCFJOrder={RCFJOrder}
                      rcfjResizeActive={rcfjResizeActive}
                      rcfjConfirmActive={rcfjConfirmActive}
                      rcfjRepairId={rcfjRepairId}
                      rcfjRepairActive={rcfjRepairActive}
                      ConfirmSizeMessage={ConfirmSizeMessage}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    )
  }
}
