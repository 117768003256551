import { connect } from 'react-redux'
import { getOrderDetail, uploadImage, updateShippingAddress, addProduct } from "../../store/order/duck"
import RepairComponent from './component'

const RepairContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
  	getOrderDetail,
  	uploadImage,
  	updateShippingAddress,
  	addProduct
  }
)(RepairComponent)

export default RepairContainer
