 /* eslint-disable react/jsx-no-target-blank */
import React, { PureComponent } from 'react'
import { CloseIcon, BackIcon, DeleteIcon } from '../../components/icons'
import { Link } from 'react-router-dom'
import Dropzone from 'react-dropzone'
import ReactModal from 'react-modal'
import _ from 'lodash'
import Cookies from 'js-cookie'
import config from '../../config/index'
import Loader from '../../components/loader'
// import MeasurementRings from './../../images/measurement.png'
import PaveStone from './../../images/diamond-comparison-pave-stone-example.jpg'
import DiamondStone from './../../images/diamond-comparison-diamond-stone-example.jpg'
import './styles.scss'
import TopNavigation from '../TopNavigation/component'
// import { message } from 'antd'

// const confirmSize = config.confirmSize
const HowManyMissing = config.HowManyMissing

export default class RcfjRepairComponent extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      exampleModal: false,
      orderDetail: '',
      userName: '',
      isLoader: true,
      RCFJRepairOption: [
        {value:'Ring misshapen or bent', key:'ring-misshapen-or-bent', isChecked:false, disabled:false},
        {value:'Missing pavé stone (diamond or gemstone)', key:'missing-pave-stone', isChecked:false, disabled:false},
        {value:'Missing diamond or gemstone', key:'missing-diamond-or-gemstone', isChecked:false, disabled:false},
        {value:'Stone is loose, tigten prongs', key:'stone-is-loose-tigten-prongs', isChecked:false, disabled:false},
        {value:'Polish (minor scratches)', key:'polish-minor-scratches', isChecked:false, disabled:false},
        // {value:'Damaged chain', key:'damaged-chain', isChecked:false, disabled:false},
        {value:'Broken chain/clasp', key:'broken-clasp', isChecked:false, disabled:false},
        {value:'Other', key:'other', isChecked:false, disabled:false}
      ],
      RepairReason:'',
      RCFJOrder: [],
      productId:'',
      quantity: 0,
      originalQty: 0,
      count: true,
      chatOpen: 0,
      missingDiamondGemstone: '',
      missingDiamondPave: '',
      quantityDropOption: [],
      images:[]
    }
  }

  async componentDidMount(){
    const checkValidation = Cookies.get('checkValidation')
    if(checkValidation){
      const data ={
        orderId: Cookies.get('orderId'),
        customerId: Cookies.get('customerId')
      }
      let productId = Cookies.get('productId')
      let RCFJOrder = []
      let quantityDropOption = []; let itemQunt = ''
      const { value } = await this.props.getOrderDetail(data)
      if(_.get(value,'status',false)){
        if(!_.includes([null, undefined, 'null', ''], productId)){
          let orderDetail = _.get(value, 'data', {})
          RCFJOrder = _.find(_.get(orderDetail, "order.line_items", []), filter => {
            return String(filter.id) === String(productId);
          })
          let rcfjFulfillmentStatus = _.find(_.get(orderDetail, "fulfillmentStatusRCFJ", []), filter => {
            return String(filter.id) === String(productId);
          });
          if(rcfjFulfillmentStatus){
            let quantities = []; let Rejectquant = ''
            quantities = _.find(_.get(rcfjFulfillmentStatus, "quantities", []), filter => {
              return (filter.type === 'initiatereturn' || filter.type ===  'initiaterepair')
            });
            Rejectquant = _.find(_.get(rcfjFulfillmentStatus, "quantities", []), filter => {
              return filter.type === 'rejectreturn'
            });
            let perviousQunt = _.get(quantities,'quantity',0)
            if(!_.includes([undefined, null, ''], Rejectquant)){
              perviousQunt = perviousQunt + _.get(Rejectquant,'quantity',0)
            }
            itemQunt = RCFJOrder.quantity - perviousQunt
            // itemQunt = perviousQunt ===  RCFJOrder.quantity ? RCFJOrder.quantity : itemQunt
            for (let i = 1; i <= Math.abs(itemQunt); i++) {  quantityDropOption.push(i) }
            // check old repair received
            if (itemQunt === 0 && ['repairreceived'].includes(_.get(rcfjFulfillmentStatus, 'fulfillmentStatus', ''))) {
              // check the quantity need to repair last time
              const totalQuantity = _.find(_.get(rcfjFulfillmentStatus, "quantities", []), filter => {
                return filter.type ===  'initiaterepair'
              })
              itemQunt = _.get(totalQuantity, 'quantity', 0)
              for (let i = 1; i <= itemQunt; i++) { quantityDropOption.push(i) }
            }
          } else{
            itemQunt = RCFJOrder.quantity
            for (let i = 1; i <= itemQunt; i++) { quantityDropOption.push(i) }
          }
        }
        this.setState({ 
          orderDetail: _.get(value, 'data', {}), 
          userName: _.get(value, 'userName', {}), 
          isLoader: false, 
          RCFJOrder,
          productId,
          quantity: itemQunt,
          originalQty:itemQunt,
          quantityDropOption
        })
      } else{
        this.props.history.push('/no-page-found')
      }
    } else{
      this.props.history.push('/no-page-found')
    }
  }

  componentDidUpdate(prevProps, prevState){
    const { RCFJRepairOption } = this.state
    if(prevProps.location.state && this.state.count){
      this.setState({
        RepairReason : _.get(prevProps,'location.state.detail.RepairReason',''),
        quantity : _.get(prevProps,'location.state.detail.quantity',0),
        originalQty : _.get(prevProps,'location.state.detail.originalQty',0),
        RCFJOrder : _.get(prevProps,'location.state.detail.RCFJOrder',''),
        images : _.get(prevProps,'location.state.detail.images',[]),
        missingDiamondPave : _.get(prevProps,'location.state.detail.missingDiamondPave',''),
        missingDiamondGemstone : _.get(prevProps,'location.state.detail.missingDiamondGemstone',''),
        count:false
      })
      _.map(_.get(prevProps,'location.state.detail.RepairReason',[]), options => {
        RCFJRepairOption.forEach(option =>{
          if(option.value === options){
            option.isChecked = !option.isChecked
          }
        })
      })
      this.setState({ RCFJRepairOption })
      this.forceUpdate()
    }
  }

  openExampleModal() {
    this.setState({ exampleModal: true })
  }

  closeModal() {
    this.setState({ exampleModal: false })
  }

  removeImage(index){
    const { images } = this.state
    images.splice(index, 1)
    this.setState({ images })
    this.forceUpdate()
  }

  handleOnChange(e){
    this.setState({ [e.target.name]: e.target.value })
  }

  selectReason(option){
    const { RCFJRepairOption, chatOpen } = this.state
    let RepairReason = []
    RCFJRepairOption.forEach(options =>{
      if(chatOpen === 0 && option.value === 'Other'){
        window.GorgiasChat.open()
        this.setState({ chatOpen: 1 })
      } else if(chatOpen === 1 && option.value === 'Other') {
        window.GorgiasChat.close()
        this.setState({ chatOpen: 0 })
      }
      if(options.value === option.value && option.value !== 'Other'){
        options.isChecked = !options.isChecked
      }
    })

    RCFJRepairOption.forEach(item => {
      if(item.isChecked === true){
        RepairReason.push(item.value)
      } else if(item.value === 'Missing pavé stone (diamond or gemstone)' && !item.isChecked){
        this.setState({ missingDiamondPave: '' })
      } else if(item.value === 'Missing diamond or gemstone' && !item.isChecked){
        this.setState({ missingDiamondGemstone: '' })
      }
    })
    this.setState({ RepairReason, RCFJRepairOption })
    this.forceUpdate()
  }

  // selectReason(option, name){
  //   const { chatOpen } = this.state
  //   if(option.value !== 'Other'){
  //     this.setState({ [name]: option.value })
  //   } else if(chatOpen === 0 && option.value === 'Other'){
  //     window.Smooch.open()
  //     this.setState({ chatOpen: 1 })
  //   } else if(chatOpen === 1 && option.value === 'Other') {
  //     window.Smooch.close()
  //     this.setState({ chatOpen: 0 })
  //   } 
  //   if(option.value !== 'Missing pave stone (diamond or gemstone)'){
  //     this.setState({ missingDiamondPave: '' })
  //   } else if(option.value !== 'Missing diamond or gemstone'){
  //     this.setState({ missingDiamondGemstone: '' })
  //   }
  // }

  resizeImage(file, maxWidth, maxHeight) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;
          // Scale down the image while maintaining the aspect ratio
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file.name, {
              type: file.type,
              lastModified: Date.now(),
            });
            resolve(resizedFile);
          }, file.type);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  }

  async handleChangeFile(files) {
    const { images } = this.state;
    this.setState({ files: files, isLoader: true });
    let x = -1;
    const nextFun = async () => {
      x++;
      if (x < files.length) {
        const file = files[x];
        const resizedFile = await this.resizeImage(file, 800, 600); // Resize the image file
        const data = new FormData();
        data.append('file', resizedFile);
        data.append(
          'fileName',
          `${parseInt(Math.random() * 1000)}-${Date.now()}-${file.name}`
        );
        const { value } = await this.props.uploadImage(data);
        if (value && value.status) {
          images.push(value.data);
          nextFun();
        } else if (value && !value.status) {
          nextFun();
        } else {
          nextFun();
        }
      } else {
        this.setState({ images: images, isLoader: false });
        this.forceUpdate();
      }
    };
    nextFun();
  }

  continueWithRequest(){
    const { orderDetail, RepairReason } = this.state
    const data = {
      RepairReason: RepairReason,
      quantity: this.state.quantity,
      originalQty: this.state.originalQty,
      RCFJOrder: this.state.RCFJOrder,
      images: this.state.images,
      missingDiamondPave: this.state.missingDiamondPave,
      missingDiamondGemstone: this.state.missingDiamondGemstone,
    }
    this.props.history.push({
      pathname : `/rcfj-repair/overview/${_.get(orderDetail, 'order.order_number', '')}`,
      state : { detail : data }
    })
  }

  goToNecklaceExtender(value){
    Cookies.remove('customerId')
    Cookies.remove('orderId')
    Cookies.remove('productId')
    this.setState({ isLoader: true })
    window.open(`${process.env.REACT_APP_NECKLACE_URL}`,"_self")
  }

  render() {
    const { orderDetail, userName, isLoader, RCFJOrder, productId, RCFJRepairOption, images, quantityDropOption, RepairReason } = this.state
    let image = []
    if(productId){
      image = _.filter(_.get(orderDetail, "images", []), filter => {
        return String(filter.id) === String(productId);
      })
    }
    let disableButton = true
    if (RepairReason.length !== 0 && images.length !== 0 && this.state.quantity > 0) {
      disableButton = false
      if(_.includes(this.state.RepairReason,'Missing pavé stone (diamond or gemstone)')){
        if(this.state.missingDiamondPave === ''){
          disableButton = true
        } else{
          disableButton = false
        }
      }
      if(_.includes(this.state.RepairReason,'Missing diamond or gemstone') ){
        if(this.state.missingDiamondGemstone === ''){
          disableButton = true
        } else{
          disableButton = false
        }
      }
      if(_.includes(this.state.RepairReason,'Missing pavé stone (diamond or gemstone)') && _.includes(this.state.RepairReason,'Missing diamond or gemstone')){
        if(this.state.missingDiamondGemstone === '' || this.state.missingDiamondPave === ''){
          disableButton = true
        } else{
          disableButton = false
        }
      }
    }
    const rcfjFulfillmentStatus = _.find(_.get(orderDetail, "fulfillmentStatusRCFJ", []), filter => {
      return String(filter.id) === String(productId)
    })
    // allow multiple repair when old repair received
    if (['repairreceived'].includes(_.get(rcfjFulfillmentStatus,'fulfillmentStatus', '')) && !disableButton) {
      disableButton = false
    }
    return (
      <>
        {isLoader ? (
          <Loader />
        ) : (
          <>
        <TopNavigation userName={userName} orderDetail={orderDetail} {...this.props}/>
          <div className="mini-container-wrapper">
            <div className="mini-container">
              <div className="head-wrap head-wrap-space">
                <div className="head-wrap-back">
                  <button type="button" className="btn btn-sm btn-back" >
                    <Link to={`/select-action/${_.get(orderDetail, 'order.order_number', '')}`}>
                      <BackIcon/>
                    </Link>
                  </button>
                </div>
                <div className="head-wrap-title">
                  Repair
                </div>
              </div>
              <div className="horizontal-product">
                <div className="product-thumbnail">
                  {_.get(image, "[0].image", "/default.webp") !== "" && (
                    <img
                      src={_.get(image, "[0].image", "/default.webp")}
                      alt={""}
                    />
                  )}
                </div>
                <div>
                  <h4 className="product-name">{_.get(RCFJOrder,'title','')}</h4>
                  <p className="product-shape-color">{_.get(RCFJOrder,'variant_title','')}</p>
                </div>
              </div>
              <div className="mini-container-inner">
                <h5 className="section-title">What do you need repaired? </h5>
                <p className="section-subtitle">Select all that apply</p>
                <ul className="select-options-list">
                  {_.map(RCFJRepairOption, (option, i) => {
                    return(  
                      <>
                        <li key={i}>
                          <label className="custom-checkbox custom-redio">
                            {option.value}
                            <input
                              type="checkbox"
                              className="custom-checkbox--input"
                              name="ReturnReason"
                              disabled={option.disabled}
                              value={this.state.RepairReason}
                              // checked={option.value === this.state.RepairReason ? true : false} 
                              checked={option.isChecked} 
                              onChange={this.selectReason.bind(this, option)}  // 'RepairReason'
                              required
                            />
                            <span className="checkmark"></span>
                          </label>
                          
                          {option.value === 'Missing pavé stone (diamond or gemstone)' ?
                            <div className="rc-popover-wrapper">
                              <span className="rc-popover">i</span>
                              <div className="rc-popover-content">
                                <img className="rc-popover-thumb" src={PaveStone} alt="loading.."/>
                              </div>
                            </div>
                          : '' }
                          
                          {option.value === 'Missing diamond or gemstone' ? 
                            <div className="rc-popover-wrapper">
                              <span className="rc-popover">i</span>
                              <div className="rc-popover-content">
                                <img className="rc-popover-thumb" src={DiamondStone}  alt="loading.."/>
                              </div>
                            </div>
                          : '' }

                        </li>
                        {option.value === 'Missing pavé stone (diamond or gemstone)' && _.includes(this.state.RepairReason,'Missing pavé stone (diamond or gemstone)') ?
                          <div className="form-group">
                            {/*<label className="form-label w-100">How many Missing? <span className="btn-example float-right" onClick={this.openExampleModal.bind(this)}>Example</span></label>*/}
                            <select
                              type="select"
                              className="form-control custom-select"
                              name="missingDiamondPave"
                              value={this.state.missingDiamondPave}
                              onChange={this.handleOnChange.bind(this)}
                            >
                              <option value="">Select</option>
                              {_.map(HowManyMissing, (option,i) => {
                                return(
                                  <option key={i} value={option} >{option}</option>
                                )
                              })}
                            </select>
                          </div>
                        :''}
                        {option.value === 'Missing diamond or gemstone' && _.includes(this.state.RepairReason,'Missing diamond or gemstone') ?
                          <div className="form-group">
                            {/*<label className="form-label w-100">How many Missing? <span className="btn-example float-right" onClick={this.openExampleModal.bind(this)}>Example</span></label>*/}
                            <select
                              type="select"
                              className="form-control custom-select"
                              name="missingDiamondGemstone"
                              value={this.state.missingDiamondGemstone}
                              onChange={this.handleOnChange.bind(this)}
                            >
                              <option value="">Select</option>
                              {_.map(HowManyMissing, (option,i) => {
                                return(
                                  <option key={i} value={option} >{option}</option>
                                )
                              })}
                            </select>
                          </div>
                        :''}
                      </>
                    )
                  })}
                </ul>
                <div className="form-group">
                  <label className="form-label">Upload Photo(s) <span className="text-danger">*</span></label>
                  <div className="dropzone dropzone-sm">
                    <Dropzone
                      onDrop={this.handleChangeFile.bind(this)}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="upload-container">
                          <div className="dropzone-inner-area" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="upload-text">
                              Drag & Drop <br /> or
                              <span className="btn-upload">Upload Photos</span>
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                  <ul className="uploaded-list">
                    {images && _.map(images, (img,i) => {
                      return(
                        <li key={i}>
                          <div className="uploaded-thumb">
                            <div className="uploaded-thumb-delete">
                              <button onClick={this.removeImage.bind(this, i)} className="btn btn-delete"><DeleteIcon/></button>
                            </div>
                            <img src={img} alt=""/>
                          </div>
                        </li>
                      )
                      })
                    }
                  </ul>
                </div>
                <div className="note-text text-left">
                  <b>Please note: Outside of one year and up to 3 years from date of delivery, Ring Concierge proudly will take in any piece for inspection. Once the inspection is complete Ring Concierge will contact you for next steps regarding your repair. For any damages deemed a manufacturer defect or quality related issue, Ring Concierge will repair at no cost to you. For any damages deemed normal wear and tear, a fee will be determined based on the assessment by our Jewelers. Please note that the costs associated with your initial repair requests are approximations and final pricing will be confirmed once your inspection is complete.</b>
                </div>
                <br/>
                <div className="note-text text-left">
                  Please note: Our bangles, cuffs, and herringbone pieces cannot be extended or shortened. If you are looking to extend a necklace, try our <a href={process.env.REACT_APP_NECKLACE_URL} className="link" target="_blank">3” Necklace Extender!</a> 
                </div>
                <br/>
                <div className="note-text text-left">
                  If you need a tennis bracelet or tennis necklace resized, please reach out to <a href="mailto:info@ringconcierge.com" className="link">info@ringconcierge.com</a> for a custom price quote.
                </div>
                <br/>
                {RCFJOrder !== '' && this.state.originalQty > 1 ? 
                  <div className="form-group">
                    <label className="form-label">Select your Quantity:</label>
                    <select
                      type="select"
                      className="form-control custom-select"
                      name="quantity"
                      value={this.state.quantity}
                      onChange={this.handleOnChange.bind(this)}
                    >
                      {_.map(quantityDropOption, (option,i) => {
                        return(
                            <option key={i} value={option} >{option}</option>
                          )
                      })
                      }
                    </select>
                  </div>
                :''}
                <button
                  disabled={disableButton}
                  type="button"
                  className="btn btn-dark w-100"
                  onClick={this.continueWithRequest.bind(this)}
                >
                  Continue with  REQUEST
                </button>

              </div>
            </div>
          </div>

          <ReactModal
            isOpen={this.state.exampleModal}
            onRequestClose={this.closeModal.bind(this)}
            ariaHideApp={false}
            contentLabel="Example Image"
            portalClassName="react-modal"
            overlayClassName="modal"
            className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
          >
            <div className="modal-content">
              <div className="react-modal-header">
                <h5 className="react-modal-title">Example Image</h5>
                <button type="button" className="btn react-modal-close" onClick={this.closeModal.bind(this)}><CloseIcon/></button>
              </div>
              <div className="react-modal-body">
              </div>
            </div>
          </ReactModal>

          </>
        )}
      </>
    )
  }
}
