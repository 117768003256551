import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import './styles.scss'
import { BackIcon } from '../../components/icons'
import Loader from '../../components/loader'
import config from '../../config/index'
import TopNavigation from '../TopNavigation/component'
import Cookies from 'js-cookie'
import { message } from 'antd'

const ReturnOption = config.Return

const randomFixedInteger = function (length) {
  return Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1))
}

export default class ReturnComponent extends PureComponent {

  static propTypes = {
    // PropTypes go here
  }

  constructor(props){
    super(props)
    this.state = {
      orderDetail: {},
      product: {},
      userName: '',
      ReturnReason: '',
      otherReason: '',
      someDetails: '',
      isLoader: true
    }
  }

  async componentDidMount(){
    const data ={
      orderId: Cookies.get('orderId'),
      customerId: Cookies.get('customerId')
    }
    const { value } = await this.props.getOrderDetail(data)
    if(_.get(value,'status',false)){
      this.setState({ orderDetail: _.get(value, 'data', {}), product: _.get(value, 'lItem', {}), isLoader: false, userName: _.get(value, 'userName', {}), })
    } else{
      this.props.history.push('/no-page-found')
    }
  }

  handleOnChange(e){
    this.setState({ [e.target.name]: e.target.value })
  }

  selectReason(option, name){
    const { orderDetail } = this.state
    this.setState({ [name]: option })
    if(option === 'I don’t love the ring design' || option === 'I don’t love the diamond'){
      window.GorgiasChat.close()
      this.props.history.push(`/exchange/${_.get(orderDetail, 'order.order_number', '')}`)
    } else if(option === 'I need a different ring size'){
      window.GorgiasChat.close()
      this.props.history.push(`/repair/${_.get(orderDetail, 'order.order_number', '')}`)
    } else if(option === 'I bought a ring else where' || option === 'I no longer need a ring' ){
      window.GorgiasChat.open()
    } else if(option !== 'I bought a ring else where'){
      window.GorgiasChat.close()
    } else if(option !== 'I no longer need a ring'){
      window.GorgiasChat.close()
    }
  }

  async continueWithReturn(){
    const { orderDetail, otherReason } = this.state
    this.setState({ isLoader: true })
    let  ReturnReason = this.state.ReturnReason
    if(this.state.ReturnReason === 'Other'){
      ReturnReason = otherReason
    }
    const obj = {
      orderId: _.get(orderDetail,'order.order_number',''),
      Id: randomFixedInteger(12),
      reasontype: 'Return',
      reasonNote: [ReturnReason],
      someDetails: this.state.someDetails,
      action: 'vowreturn'
    }
    const { value } = await this.props.returnRequest(obj)
    if (_.get(value, 'status', false)) {
      message.error(_.get(value, 'message', ''), () => {
        this.setState({ isLoader: false })
      })
      this.props.history.push(`/return/overview/${_.get(orderDetail, 'order.order_number', '')}`)
    } else{
      message.error(_.get(value, 'message', ''), () => {
        this.setState({ isLoader: false })
      })
    }
  }

  render() {
    const { orderDetail, product, userName, isLoader, ReturnReason } = this.state
    const image = _.filter(_.get(orderDetail, "images", []), filter => {
      return String(filter.id) === String(product.id);
    })
    const title = _.get(product, "name", "").split("/")
    let isDisable = true
    if(ReturnReason !== 'Other'){
      if(this.state.ReturnReason !== '' && this.state.someDetails !== ''){
        isDisable = false
      }
    }else{
      if(this.state.ReturnReason !== '' && this.state.someDetails !== '' && this.state.otherReason !== ''){
        isDisable = false
      }
    }
    return (
      <>
      <div>
        {isLoader ? (
          <Loader />
        ) : (
        <div>
        <TopNavigation userName={userName} orderDetail={orderDetail} {...this.props}/>
          <div className="mini-container-wrapper">
            <div className="mini-container">
              <div className="head-wrap head-wrap-space">
                <div className="head-wrap-back">
                  <button type="button" className="btn btn-sm btn-back">
                    <Link to={`/select-action/${_.get(orderDetail, 'order.order_number', '')}`}>
                      <BackIcon/>
                    </Link>
                  </button>
                </div>
                <div className="head-wrap-title">
                  Return Ring
                </div>
              </div>
              <div className="horizontal-product">
                <div className="product-thumbnail">
                  {_.get(image, "[0].image", "/default.webp") !== "" && (
                    <img
                      src={_.get(image, "[0].image", "/default.webp")}
                      alt={""}
                    />
                  )}
                </div>
                <div>
                  <h4 className="product-name">{_.get(product,'title','')}</h4>
                  <p className="product-shape-color">{title[1]}</p>
                </div>
              </div>
              <div className="mini-container-inner">
                <h5 className="section-title">Why would you like to Return your ring?</h5>
                <ul className="select-options-list">
                {_.map(ReturnOption, (option, i) => {
                  return(
                    <li key={i}>
                      <label className="custom-checkbox custom-redio">
                        {option}
                        <input
                          type="checkbox"
                          className="custom-checkbox--input"
                          name="ReturnReason"
                          value={this.state.ReturnReason}
                          checked={option === this.state.ReturnReason ? true : false} 
                          onChange={this.selectReason.bind(this, option, 'ReturnReason')}
                          required
                        />
                        <span className="checkmark"></span>
                      </label>
                    </li>
                  )
                })}
                </ul>
                {this.state.ReturnReason === 'Other' ?
                  <div className="form-group">
                    <label className="form-label">other Reason:</label>
                    <textarea
                      className="form-control"
                      placeholder="If your reason is not listed above, tell us here what needs attention on your ring..."
                      rows="5"
                      name="otherReason"
                      value={this.state.otherReason}
                      onChange={this.handleOnChange.bind(this)}
                    >
                    </textarea>
                  </div> : ''
                }
                <div className="form-group">
                  <label className="form-label">give us some details (required)</label>
                  <textarea
                    className="form-control"
                    placeholder="Tell us what’s happening with your ring and what’s driving your desire for exchange..."
                    rows="7"
                    name="someDetails"
                    value={this.state.someDetails}
                    onChange={this.handleOnChange.bind(this)}
                  >
                  </textarea>
                </div>
                <button
                  disabled={isDisable} 
                  type="button" 
                  className="btn btn-dark w-100"
                  onClick={this.continueWithReturn.bind(this)}
                >Continue with Return</button>
              </div>
            </div>
          </div>
        </div>
        )}
        </div> 
      </>
    )
  }
}
