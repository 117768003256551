import React, { PureComponent } from 'react'
import { BackIcon } from '../../components/icons'
import './styles.scss'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import Loader from '../../components/loader'
import config from '../../config/index'
import TopNavigation from '../TopNavigation/component'
import Cookies from 'js-cookie'

const ExchangeOption = config.Exchange

export default class ExchangeComponent extends PureComponent {

  static propTypes = {
    // PropTypes go here
  }

  constructor(props){
    super(props)
    this.state = {
      orderDetail: {},
      product: {},
      userName: '',
      ExchangeReason: '',
      otherReason: '',
      someDetails: '',
      tabType: '',
      isLoader: true,
      count: true,
      chatOpen: 0
    }
  }

  async componentDidMount(){
    const data ={
      orderId: Cookies.get('orderId'),
      customerId: Cookies.get('customerId')
    }
    const { value } = await this.props.getOrderDetail(data)
    if(_.get(value,'status',false)){
      this.setState({ orderDetail: _.get(value, 'data', {}), product: _.get(value, 'lItem', {}), isLoader: false, userName: _.get(value, 'userName', {}), })
    } else{
      this.props.history.push('/no-page-found')
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.location.state && this.state.count){
      this.setState({
        ExchangeReason : _.get(prevProps,'location.state.detail.ExchangeReason',''),
        someDetails : _.get(prevProps,'location.state.detail.someDetails',''),
        otherReason : _.get(prevProps,'location.state.detail.otherReason',''),
        count: false
      })
    }
  }

  handleOnChange(e){
    this.setState({ [e.target.name]: e.target.value })
  }

  selectReason(option, name){
    const { orderDetail, chatOpen } = this.state
    this.setState({ [name]: option })
    if(option === 'I need a different ring size'){
      this.props.history.push(`/repair/${_.get(orderDetail, 'order.order_number', '')}`)
    } else if(chatOpen === 0 && option === 'Other'){
      window.GorgiasChat.open()
      this.setState({ chatOpen: 1 })
    } else if(option !== 'Other'){
      window.GorgiasChat.close()
      this.setState({ chatOpen: 0 })
    } 
  }

  continueWithExchange(){
    const { orderDetail, ExchangeReason, otherReason } = this.state
    let reason =  ExchangeReason
    let otherStatus = false
    if(ExchangeReason === 'Other'){
      reason =  otherReason
      otherStatus = true
    }
    const data = {
      ExchangeReason: reason,
      someDetails: this.state.someDetails,
      otherReason: otherReason,
      otherStatus: otherStatus
    }
    this.props.history.push({
      pathname : `/exchange/overview/${_.get(orderDetail, 'order.order_number', '')}`,
      state : { detail : data }
    })
  }

  openChat(){
    const { chatOpen } = this.state
    if(chatOpen === 0){
      window.GorgiasChat.open()
      this.setState({ chatOpen: 1 })
    } else{
      window.GorgiasChat.close()
      this.setState({ chatOpen: 0 })
    }
  }

  render() {
    const { orderDetail, product, isLoader, userName } = this.state
    const image = _.filter(_.get(orderDetail, "images", []), filter => {
      return String(filter.id) === String(product.id);
    })
    const title = _.get(product, "name", "").split("/")
    // let isDisable = true
    // if(ExchangeReason !== 'Other'){
    //   if(this.state.ExchangeReason !== '' && this.state.someDetails !== ''){
    //     isDisable = false
    //   }
    // }else{
    //   if(this.state.ExchangeReason !== '' && this.state.someDetails !== '' && this.state.otherReason !== ''){
    //     isDisable = false
    //   }
    // }
    return (
      <>
      <div>
        {isLoader ? (
              <Loader />
            ) : (
            <div>
            <TopNavigation userName={userName} orderDetail={orderDetail} {...this.props}/>
              <div className="mini-container-wrapper">
                <div className="mini-container">
                  <div className="head-wrap head-wrap-space">
                    <div className="head-wrap-back">
                      <button type="button" className="btn btn-sm btn-back">
                        <Link to={`/select-action/${_.get(orderDetail, 'order.order_number', '')}`}>
                          <BackIcon/>
                        </Link>
                      </button>
                    </div>
                    <div className="head-wrap-title">
                      Exchange Ring
                    </div>
                  </div>
                  <div className="horizontal-product">
                    <div className="product-thumbnail">
                      {_.get(image, "[0].image", "/default.webp") !== "" && (
                        <img
                          src={_.get(image, "[0].image", "/default.webp")}
                          alt={""}
                        />
                      )}
                    </div>
                    <div>
                      <h4 className="product-name">{_.get(product,'title','')}</h4>
                      <p className="product-shape-color">{title[1]}</p>
                    </div>
                  </div>
                  <div className="mini-container-inner">
                    <h5 className="section-title">what would you like to exchange?</h5>
                    <ul className="select-options-list">
                    {_.map(ExchangeOption, (option, i) => {
                      return(
                        <>
                          <li key={i}>
                            <label className="custom-checkbox custom-redio">
                              {option}
                              <input
                                type="checkbox"
                                className="custom-checkbox--input"
                                name="availability"
                                value={this.state.ExchangeReason}
                                checked={option === this.state.ExchangeReason ? true : false} 
                                onChange={this.selectReason.bind(this, option, 'ExchangeReason')}
                                required
                              />
                              <span className="checkmark"></span>
                            </label>
                          </li>
                          {_.includes(['I want a new setting','I want a new diamond','I want a new design and new diamond'],this.state.ExchangeReason) && option === this.state.ExchangeReason ?
                            <div className="chat-specialist">
                              {this.state.ExchangeReason === 'I want a new design and new diamond' ?                            
                                'Speak to a Ring Concierge specialist about designing a different ring and diamond. ' :  ''}
                              {this.state.ExchangeReason === 'I want a new setting' ?                            
                                'Speak to a Ring Concierge specialist about designing a different ring. ' :  ''}
                              {this.state.ExchangeReason === 'I want a new diamond' ?                            
                                'Speak to a Ring Concierge specialist about designing a different diamond. ' :  ''}
                              <span onClick={this.openChat.bind(this)}>Chat Ring Specialist</span>
                            </div> : ''
                          }
                        </>
                      )
                    })}
                    </ul>
                    {/*!_.includes(['I want a new setting','I want a new diamond','I want a new design and new diamond'],this.state.ExchangeReason) ?
                      <div>
                        {this.state.ExchangeReason === 'Other' ?
                          <div className="form-group">
                            <label className="form-label">other Reason:</label>
                            <textarea
                              className="form-control"
                              placeholder="If your reason is not listed above, tell us here what needs attention on your ring..."
                              rows="5"
                              name="otherReason"
                              value={this.state.otherReason}
                              onChange={this.handleOnChange.bind(this)}
                            >
                            </textarea>
                          </div> : ''
                        }
                        <div className="form-group">
                          <label className="form-label">give us some details <span className="text-danger">*</span></label>
                          <textarea
                            className="form-control"
                            placeholder="Tell us what’s happening with your ring and what’s driving your desire for exchange..."
                            rows="7"
                            name="someDetails"
                            value={this.state.someDetails}
                            onChange={this.handleOnChange.bind(this)}
                          >
                          </textarea>
                        </div>
                        <button
                          disabled={isDisable} 
                          type="button" 
                          className="btn btn-dark w-100"
                          onClick={this.continueWithExchange.bind(this)}
                        >Continue with Exchange</button>
                      </div>
                    : ''
                    */}
                  </div>
                </div>
              </div>
          </div>
          )}
          </div>
      </>
    )
  }
}
  