import React, { PureComponent } from 'react'
import { Tooltip, message } from 'antd'
import ReactModal from 'react-modal'
import { BackIcon, CloseIcon } from '../../../components/icons'
import _ from 'lodash'
import Loader from '../../../components/loader'
import TopNavigation from '../../TopNavigation/component'
import Cookies from 'js-cookie'
import { getCode, getData } from 'country-list'
import { CountryDropdown, RegionDropdown } from "react-country-region-selector"
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

ReactModal.setAppElement('#root')

const randomFixedInteger = function (length) {
  return Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1))
}

export default class ExchangeOverviewComponent extends PureComponent {

  static propTypes = {
    // PropTypes go here
  }

  constructor(props){
    super(props)
    this.state = {
      orderDetail: {},
      product: {},
      ExchangeReason: '',
      someDetails: '',
      userName: '',
      isLoader: true,
      errSup: {},
      shippingAddress: {},
      editAddressModal: false,
      otherReason:'',
      otherStatus: false,
      refund: 0.00,
      first_name: '',
      last_name: '',
      address1: '',
      address2: '',
      city: '',
      province_code: '',
      zip: '',
      valAddress: {},
      errorMessage: [],
      phoneNumberCountry: 'US'
    }
  }

  closeModal() {
    const { valAddress } = this.state
    this.setState({ editAddressModal: false, errSup: {}, shippingAddress: valAddress, errorMessage: [] })
  }

  goBack(){
    const { orderDetail, otherStatus, ExchangeReason } = this.state
    let Reason = ExchangeReason
    if(otherStatus === true){
      Reason = 'Other'
    }
    const data = {
      ExchangeReason: Reason,
      someDetails: this.state.someDetails,
      otherReason: this.state.otherReason
    }
    this.props.history.push({
      pathname : `/exchange/${_.get(orderDetail, 'order.order_number', '')}`,
      state : { detail : data }
    })
  }

  async componentDidMount(){
    const data ={
      orderId: Cookies.get('orderId'),
      customerId: Cookies.get('customerId')
    }
    const { value } = await this.props.getOrderDetail(data)
    if(_.get(value,'status',false)){
      let orderDetail = _.get(value, 'data', {})
      const refund = await this.props.calculaterefund(_.get(orderDetail, '_id', {}))
      const amt = parseFloat(_.get(refund, 'value.data.transactions[0].amount', 0.00))
      this.setState({
        refund: amt,
        orderDetail: _.get(value, 'data', {}),
        userName: _.get(value, 'userName', {}),
        product: _.get(value, 'lItem', {}),
        shippingAddress: _.get(value, 'data.order.shipping_address', {}) === null ? {} : _.get(value, 'data.order.shipping_address', {}),
        first_name: _.get(value, 'data.order.shipping_address.first_name', ''),
        last_name: _.get(value, 'data.order.shipping_address.last_name', ''),
        address1: _.get(value, 'data.order.shipping_address.address1', ''),
        address2: _.get(value, 'data.order.shipping_address.address2', ''),
        city: _.get(value, 'data.order.shipping_address.city', ''),
        province_code: _.get(value, 'data.order.shipping_address.province_code', ''),
        zip: _.get(value, 'data.order.shipping_address.zip', ''),
        isLoader: false,
        errSup: {}, 
        editAddressModal: false
      })
    } else{
      this.props.history.push('/no-page-found')
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.location.state){
      this.setState({
        ExchangeReason : _.get(prevProps,'location.state.detail.ExchangeReason',''),
        someDetails : _.get(prevProps,'location.state.detail.someDetails',''),
        otherReason : _.get(prevProps,'location.state.detail.otherReason',''),
        otherStatus : _.get(prevProps,'location.state.detail.otherStatus','')
      })
    }
  }

  async submit(){
    const { orderDetail } = this.state
    this.setState({ isLoader: true })
    const obj = {
      orderId: _.get(orderDetail, '_id', ''),
      Id: randomFixedInteger(12),
      reason: 'Exchange',
      reasonNote: [this.state.ExchangeReason],
      someDetails: this.state.someDetails,
      action: 'vowexchange'
    }
    const objDetail = {
      orderId: _.get(orderDetail,'order.order_number',''),
      tags:['VOW'],
      action:'RCLoop'
    }
    const val = await this.props.generateId(obj)
    if (_.get(val, 'value.status', false)) {
      objDetail.Id = _.get(val,'value.data.Id','')
      objDetail.reason = _.get(val,'value.data.reason','')
      objDetail.reasonNote = _.get(val,'value.data.reasonNote','')
      objDetail.someDetails = _.get(val,'value.data.someDetails','')
      objDetail.repairReturnExchangeId = _.get(val,'value.data._id','')
      const { value } = await this.props.repairRequest(objDetail)
      if (_.get(value, 'status', false)) {
        message.error(_.get(value, 'message', ''), () => {
          this.setState({ isLoader: false })
        })
        this.props.history.push(`/exchange/request-confirmation/${_.get(orderDetail, 'order.order_number', '')}`)
      } else{
        message.error(_.get(value, 'message', ''), () => {
          this.setState({ isLoader: false })
        })
      }
    }
  }

  editShippingAddress(key) {
    if (key === 'editAddressModal') {
      const valAddress = _.cloneDeep(this.state.shippingAddress)
      this.setState({ [key]: true, valAddress })
      this.forceUpdate()
    }
  }
  handleChangeAddress(e) {
    const { shippingAddress } = this.state
    shippingAddress[e.target.name] = e.target.value
    this.setState({ shippingAddress })
    this.forceUpdate()
  }

  handleChangeCountry = (name, value) => {
    const { shippingAddress } = this.state
    shippingAddress[name] = value
    if(name === 'country'){
      let countryshortForm = getCode(value)
      if(!countryshortForm){
        const countiesCodeName = getData()
        _.map(countiesCodeName, list => {
          if(_.get(list, 'name', '') && _.get(list, 'name', '').indexOf(value) !== -1){
            countryshortForm = _.get(list, 'code', '')
          }
        }) 
      }
      this.setState({ phoneNumberCountry: countryshortForm, shippingAddress })
    }
    this.forceUpdate()
  }

  handleChangePhone = (name, value) => {
    const { shippingAddress } = this.state
    shippingAddress[name] = value
    this.setState({ shippingAddress })
    this.forceUpdate()
  }

  handleEditAddress = async() => {
    const { shippingAddress, orderDetail } = this.state
    const err = {}
    if (!shippingAddress.first_name || shippingAddress.first_name === '' ) {
      err.first_name = 'First Name is required.'
    }
    if (!shippingAddress.last_name || shippingAddress.last_name === '' ) {
      err.last_name = 'Last Name is required.'
    }
    if (!shippingAddress.address1 || shippingAddress.address1 === '' ) {
      err.address = 'Address is required.'
    }
    if (!shippingAddress.city || shippingAddress.city === '' ) {
      err.city = 'City is required.'
    }
    if (!shippingAddress.province || shippingAddress.province === '' ) {
      err.state = 'State is required.'
    }
    if (!shippingAddress.province || shippingAddress.province === '' ) {
      err.state = 'State is required.'
    }
    if (!shippingAddress.country || shippingAddress.country === '' ) {
      err.state = 'Country is required.'
    }
    if(Object.keys(err).length === 0) {
      const obj = {
        id: _.get(orderDetail, '_id', ''),
        orderId: _.get(orderDetail, 'order.id', ''),
        shipping_address: shippingAddress,
        addressType: 'shippingAddress'
      }
      this.setState({ isLoader: true })
      const { value } = await this.props.updateShippingAddress(obj)
      if(_.get(value, 'status', false)) {
        this.componentDidMount()
        message.error(_.get(value, 'message', ''), () => {
          this.setState({ errSup: {}, editAddressModal: false, shippingAddress: _.get(value, 'shippingAddress', '') })
        })
      } else {
        message.error(_.get(value, 'message', ''), () => {
          this.setState({ isLoader: false, errorMessage: _.get(value, 'message.shipping_address', []) })
        })
      }
    } else {
      this.setState({ errSup: err, isLoader: false})
    }
  }

  render() {
    const { orderDetail, userName, product, isLoader, shippingAddress, errSup, refund } = this.state
    const image = _.filter(_.get(orderDetail, "images", []), filter => {
      return String(filter.id) === String(product.id);
    })
    const title = _.get(product, "name", "").split("/")
    // let totalPrice = parseFloat(_.get(orderDetail, "order.total_price",0.00))
    // let RCFJOrder = []
    // RCFJOrder = _.filter(_.get(orderDetail, 'order.line_items', []), items => {
    //   return (items.vendor === 'Ring Concierge' || items.vendor === 'Kelly Bello')
    // })
    // if(RCFJOrder && RCFJOrder.length !== 0 && totalPrice !== 0.00){
    //   let RCFJProductPrice = 0.00
    //   _.map(RCFJOrder, items => {
    //     RCFJProductPrice = RCFJProductPrice + items.price
    //   })
    //   if(RCFJProductPrice !== 0.00){
    //     totalPrice = totalPrice - RCFJProductPrice
    //   }
    // }
    return (
      <>
      {isLoader ? (
          <Loader />
        ) : (
        <>
        <TopNavigation userName={userName} orderDetail={orderDetail} {...this.props}/>
          <div className="mini-container-wrapper">
            <div className="mini-container">
              <div className="head-wrap head-wrap-space">
                <div className="head-wrap-back">
                  <button type="button" className="btn btn-sm btn-back" onClick={this.goBack.bind(this)}>
                    <BackIcon/>
                  </button>
                </div>
                <div className="head-wrap-title">
                  Exchange Overview
                </div>
              </div>
              <div className="horizontal-product">
                <div className="product-thumbnail">
                  {_.get(image, "[0].image", "/default.webp") !== "" && (
                    <img
                      src={_.get(image, "[0].image", "/default.webp")}
                      alt={""}
                    />
                  )}
                </div>
                <div>
                  <h4 className="product-name">{_.get(product,'title','')}</h4>
                  <p className="product-shape-color">{title[1]}</p>
                </div>
              </div>
              <div className="mini-container-inner">
                <div className="form-group">
                  <label className="label-info-text">what would you like to exchange?</label>
                  <p>{this.state.ExchangeReason}</p>
                </div>
                <div className="form-group">
                  <label className="label-info-text">More Details </label>
                  <p>{this.state.someDetails}</p>
                </div>
                <div className="form-group">
                  <label className="label-info-text">Return Address</label>
                  <div className="address-info">
                    {this.state.first_name} {this.state.last_name}<br/>
                    {this.state.address1} {this.state.address2}, {this.state.city}, {this.state.province_code} {this.state.zip}
                    {/*<span onClick={this.editShippingAddress.bind(this, 'editAddressModal')} className="btn-edit-address">Edit Address</span>*/}
                  </div>
                </div>
                <hr className="horizontal-line"/>
                <div className="form-group">
                  <label className="label-info-text">Summary</label>
                  <ul className="summary-info">
                    <li>
                      <span>Exchange Credit  </span> <span>${refund}</span>
                    </li>
                  </ul>
                  <Tooltip
                    title="Your credit is issued as soon as we receive your
                    ring and ensure it is in it’s original state.
                    Store credit is available to use immediately when it’s issued."
                  >
                    <span className="btn-exchange-credit">When do I get my Exchange Credit?</span>
                  </Tooltip>
                </div>
                <button type="button" className="btn btn-dark w-100" onClick={this.submit.bind(this)}>Submit Exchange </button>
              </div>
            </div>
          </div>
          <ReactModal
            isOpen={this.state.editAddressModal}
            onRequestClose={this.closeModal.bind(this)}
            ariaHideApp={false}
            contentLabel="Edit Shippping Address"
            portalClassName="react-modal"
            overlayClassName="modal"
            className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
          >
            <div className="modal-content">
              {this.state.errorMessage && this.state.errorMessage.length ? (
                <div className="notice-attention">
                  <div>
                    <h6 className="notice-attention-heading">
                      There was {this.state.errorMessage.length} error on this form:
                    </h6>
                    {
                      _.map(this.state.errorMessage, message  => {
                        return(
                          <p className="notice-attention-subheading">
                            {message}
                          </p>
                        )
                      })
                    }
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="react-modal-header">
                <h5 className="react-modal-title">Edit Shippping Address</h5>
                <button type="button" className="btn react-modal-close" onClick={this.closeModal.bind(this)}><CloseIcon/></button>
              </div>
              <div className="react-modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">First Name <span className="text-danger">*</span></label>
                      <input
                        name="first_name"
                        value={_.get(shippingAddress, "first_name", "")}
                        onChange={this.handleChangeAddress.bind(this)}
                        type="text"
                        className="form-control"
                        required
                      />
                      <div className="invalid-feedback">{errSup && errSup.first_name ? errSup.first_name : ""}</div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Last Name <span className="text-danger">*</span></label>
                      <input
                        name="last_name"
                        value={_.get(shippingAddress, "last_name", "")}
                        onChange={this.handleChangeAddress.bind(this)}
                        type="text"
                        className="form-control"
                        required
                      />
                      <div className="invalid-feedback">{errSup && errSup.last_name ? errSup.last_name : ""}</div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">company </label>
                  <input
                    name="company"
                    value={_.get(shippingAddress, "company", "")}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Address <span className="text-danger">*</span></label>
                  <input
                    name="address1"
                    value={_.get(shippingAddress, "address1", "")}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control "
                    required
                  />
                  <div className="invalid-feedback">{errSup && errSup.address ? errSup.address : ""}</div>
                </div>
                <div className="form-group">
                  <label className="form-label">Apartment, suite, etc. (optional) </label>
                  <input
                    name="address2"
                    value={_.get(shippingAddress, "address2", "")}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">City <span className="text-danger">*</span></label>
                  <input
                    name="city"
                    value={_.get(shippingAddress,'city','')}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Country/Region <span className="text-danger">*</span></label>
                      <CountryDropdown
                        name="country"
                        value={_.get(shippingAddress, "country", "")}
                        onChange={this.handleChangeCountry.bind(this, 'country')}
                        classes="form-control material-textfield-input"
                      />
                      <div className="invalid-feedback">{errSup && errSup.country ? errSup.country : ""}</div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">State/Provision <span className="text-danger">*</span></label>
                      <RegionDropdown
                        name="province"
                        country={_.get(shippingAddress, "country", "")}
                        value={_.get(shippingAddress, "province", "")}
                        onChange={this.handleChangeCountry.bind(this, 'province')}
                        classes="form-control material-textfield-input"
                      />
                      <div className="invalid-feedback">{errSup && errSup.state ? errSup.state : ""}</div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group material-textfield">
                      <label className="form-label">Zip <span className="text-danger">*</span></label>
                      <input
                        name="zip"
                        value={_.get(shippingAddress,'zip','')}
                        onChange={this.handleChangeAddress.bind(this)}
                        type="number"
                        className="form-control material-textfield-input"
                        required
                      />
                      <div className="invalid-feedback">{errSup && errSup.zipcode ? errSup.zipcode : ""}</div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="country-input-field">
                    <PhoneInput
                      name="phone"
                      defaultCountry={this.state.phoneNumberCountry}
                      international={true}
                      value={_.get(shippingAddress, "phone", "")}
                      onChange={this.handleChangePhone.bind(this, 'phone')}
                      classes="form-control"
                    />
                  </div>
                </div>
                <button onClick={this.handleEditAddress.bind(this)} className="btn btn-dark w-100">
                  Save
                </button>
              </div>
            </div>
          </ReactModal>
          </>
        )}
      </>
    )
  }
}
