import React, { PureComponent } from 'react'
import { CloseIcon, BackIcon, DeleteIcon } from '../../components/icons'
import { Link } from 'react-router-dom'
import Dropzone from 'react-dropzone'
import ReactModal from 'react-modal'
import _ from 'lodash'
import Cookies from 'js-cookie'
import config from '../../config/index'
import Loader from '../../components/loader'
import MeasurementRings from './../../images/measurement.png'
import './styles.scss'
import TopNavigation from '../TopNavigation/component'
import { message } from 'antd'
import { getCode, getData } from 'country-list'
import { CountryDropdown, RegionDropdown } from "react-country-region-selector"
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

// const RepairOption = config.Repair
const RingSizeOption = config.RingSize
ReactModal.setAppElement('#root')


export default class RepairComponent extends PureComponent {

  static propTypes = {
    // PropTypes go here
  }

  constructor(props){
    super(props)
    this.state = {
      orderDetail: {},
      product: {},
      userName: '',
      RepairReasons: [],
      otherReason: '',
      someDetails: '',
      tabType: '',
      ringSize:'',
      selectedTab: 'Select New Size',
      RepairOption: [
        {value:'Need a different ring size', isChecked:false, disabled:false, check: false},
        {value:'Ring is misshapen or bent', isChecked:false, disabled:false},
        {value:'Missing accent diamond', isChecked:false, disabled:false},
        {value:'Missing side stone', isChecked:false, disabled:false},
        {value:'Damaged prongs', isChecked:false, disabled:false},
        // {value:'Missing center diamond', isChecked:false, disabled:false},
        // {value:'Missing melee diamonds from the halo', isChecked:false, disabled:false},
        // {value:'Missing melee diamonds from the band', isChecked:false, disabled:false},
        {value:'Polishing', isChecked:false, disabled:false},
        {value:'Refinishing', isChecked:false, disabled:false},
        {value:'Diamond is loose', isChecked:false, disabled:false},
        // {value:'Repair Prongs', isChecked:false, disabled:false},
        // {value:'Loose stone in shank or wedding band', isChecked:false, disabled:false},
        {value:'Loose diamond in band', isChecked:false, disabled:false},
        // {value:'Other', isChecked:false, disabled:false}
      ],
      anotherRepairOption: [
        {value:'Need a different ring size', isChecked:false, disabled:false, check: true},
        {value:'Ring is misshapen or bent', isChecked:false, disabled:false},
        {value:'Missing accent diamond', isChecked:false, disabled:false},
        {value:'Missing side stone', isChecked:false, disabled:false},
        {value:'Damaged prongs', isChecked:false, disabled:false},
        // {value:'Missing center diamond', isChecked:false, disabled:false},
        // {value:'Missing melee diamonds from the halo', isChecked:false, disabled:false},
        // {value:'Missing melee diamonds from the band', isChecked:false, disabled:false},
        {value:'Polishing', isChecked:false, disabled:false},
        {value:'Refinishing', isChecked:false, disabled:false},
        {value:'Diamond is loose', isChecked:false, disabled:false},
        // {value:'Repair Prongs', isChecked:false, disabled:false},
        {value:'Loose diamond in band', isChecked:false, disabled:false},
        // {value:'Loose stone in shank or wedding band', isChecked:false, disabled:false},
        // {value:'Other', isChecked:false, disabled:false}
      ],
      anotherOption:[
        {value:'Need a different ring size', isChecked:true, disabled:false, check: true}
      ],
      InsideRepairOption: [
        // {value:'Ring is misshapen or bent', isChecked:false, disabled:false},
        // {value:'Diamond is loose', isChecked:false, disabled:false},
        // {value:'Missing accent diamond', isChecked:false, disabled:false},
        // {value:'Missing center diamond', isChecked:false, disabled:true},
        // {value:'Other', isChecked:false, disabled:false}
        {value:'Ring is misshapen or bent', isChecked:false, disabled:false},
        {value:'Missing accent diamond', isChecked:false, disabled:false},
        {value:'Missing side stone', isChecked:false, disabled:false},
        {value:'Damaged prongs', isChecked:false, disabled:false},
        // {value:'Missing center diamond', isChecked:false, disabled:false},
        // {value:'Missing melee diamonds from the halo', isChecked:false, disabled:false},
        // {value:'Missing melee diamonds from the band', isChecked:false, disabled:false},
        {value:'Polishing', isChecked:false, disabled:false},
        {value:'Refinishing', isChecked:false, disabled:false},
        {value:'Diamond is loose', isChecked:false, disabled:false},
        // {value:'Repair Prongs', isChecked:false, disabled:false},
        {value:'Loose diamond in band', isChecked:false, disabled:false},
        // {value:'Loose stone in shank or wedding band', isChecked:false, disabled:false},
        // {value:'Other', isChecked:false, disabled:false}
      ],
      isLoader: true,
      showOther: '',
      showRingOption: '',
      errSup: {},
      shippingAddress: {},
      editAddressModal: false,
      images: [],
      count: true,
      productVariant:[],
      ringSizeItem: [],
      first_name: '',
      last_name: '',
      address1: '',
      address2: '',
      city: '',
      province_code: '',
      zip: '',
      errorMessage: [],
      phoneNumberCountry: 'US'
    }
  }

  closeModal() {
    const { valAddress } = this.state
    this.setState({ editAddressModal: false, errSup: {}, shippingAddress: valAddress, errorMessage: [] })
  }

  async componentDidMount(){
    const data ={
      orderId: Cookies.get('orderId'),
      customerId: Cookies.get('customerId')
    }
    const { value } = await this.props.getOrderDetail(data)
    // console.log(val,'val')
    if(_.get(value,'status',false)){

      let ringSizeItem = _.filter(_.get(value, 'data.requestId', []), v => { return v.reason === "Repair" && parseInt(v.newRingsize) > 0})

      this.setState({ 
        orderDetail: _.get(value, 'data', {}), 
        product: _.get(value, 'lItem', {}), 
        userName: _.get(value, 'userName', {}), 
        isLoader: false, 
        shippingAddress: _.get(value, 'data.order.shipping_address', {}) === null ? {} : _.get(value, 'data.order.shipping_address', {}), 
        productVariant: _.get(value, 'product', []), 
        ringSizer: _.get(value, 'ringSizer', ''), 
        ringSizeItem: ringSizeItem,
        first_name: _.get(value, 'data.order.shipping_address.first_name', ''),
        last_name: _.get(value, 'data.order.shipping_address.last_name', ''),
        address1: _.get(value, 'data.order.shipping_address.address1', ''),
        address2: _.get(value, 'data.order.shipping_address.address2', ''),
        city: _.get(value, 'data.order.shipping_address.city', ''),
        province_code: _.get(value, 'data.order.shipping_address.province_code', ''),
        zip: _.get(value, 'data.order.shipping_address.zip', ''),
        errSup: {}, editAddressModal: false
      })
    } else{
      this.props.history.push('/no-page-found')
    }
  }

  componentDidUpdate(prevProps, prevState){
    const { RepairOption, anotherOption, InsideRepairOption } = this.state
    if(prevProps.location.state && this.state.count){
      this.setState({
        RepairReasons : _.get(prevProps,'location.state.detail.RepairReasons',[]),
        someDetails : _.get(prevProps,'location.state.detail.someDetails',''),
        images : _.get(prevProps,'location.state.detail.images',[]),
        ringSize : _.get(prevProps,'location.state.detail.ringSize',''),
        otherReason : _.get(prevProps,'location.state.detail.otherReason',''),
        showOther : _.get(prevProps,'location.state.detail.showOther',''),
        count:false
      })
      if(_.get(prevProps,'location.state.detail.RepairReasons[0]',[]) !== 'Need a different ring size'){
        _.map(_.get(prevProps,'location.state.detail.RepairReasons',[]), options => {
          RepairOption.forEach(option =>{
            if(option.value === options){
              option.isChecked = !option.isChecked
            }
          })
        })
        this.setState({ RepairOption })
      } else{
        _.map(_.get(prevProps,'location.state.detail.RepairReasons',[]), options => {
          InsideRepairOption.forEach(option =>{
            if(option.value === options){
              option.isChecked = !option.isChecked
            }
          })
        })
        this.setState({ RepairOption: anotherOption, InsideRepairOption, showRingOption: 'Need a different ring size' })
      }
    }
  }

  handleOnChange(e){
    this.setState({ [e.target.name]: e.target.value })
  }

  changeTab(name, value){
    this.setState({ [name]: value })
  }

  selectReason(option){
    const { RepairOption, orderDetail, anotherOption, anotherRepairOption, InsideRepairOption } = this.state
    let RepairReasons = []
    RepairOption.forEach(options =>{
      if(options.value === option.value){
        options.isChecked = !options.isChecked
      }
    })

    RepairOption.forEach(item =>{
      if(item.value === 'Other' && item.isChecked === true){
        this.setState({ showOther: 'Other' })
      } else if (item.value === 'Other' && item.isChecked === false){
        this.setState({ showOther: '' })
      }
    })
    let repair = RepairOption
    if(option.value === 'Need a different ring size'){
      RepairOption.forEach(item =>{
        if(item.value === 'Need a different ring size' && item.isChecked === true){
          _.map(repair, oldOption => {
            _.map(InsideRepairOption, newOption => {
              if(oldOption.value === newOption.value && oldOption.isChecked){
                newOption.isChecked = !newOption.isChecked 
              }
            })
          })
          repair = anotherOption
          anotherRepairOption.forEach(options => {
            if(options.isChecked === true){
              options.isChecked = !options.isChecked
            }
          })
          this.setState({ InsideRepairOption, anotherRepairOption, showRingOption: 'Need a different ring size', showOther: '', ringSize: '', otherReason: '', RepairReasons: ['Need a different ring size'] })
          this.forceUpdate()
        } else if(item.value === 'Need a different ring size' && item.isChecked === false){
          repair = anotherRepairOption
          _.map(InsideRepairOption, newOption => {
            _.map(repair, oldOption => {
              if(oldOption.value === newOption.value && newOption.isChecked){
                oldOption.isChecked = !oldOption.isChecked 
              }
            })
          })
          anotherOption.forEach(options =>{
            options.isChecked = !options.isChecked
          })
          InsideRepairOption.forEach(options => {
            if(options.isChecked === true){
              options.isChecked = !options.isChecked
            }
          })
          this.setState({ anotherOption, InsideRepairOption, showRingOption: '', selectedTab: 'Select New Size', showOther: '', ringSize: '', otherReason: '', RepairReasons: [] })
          this.forceUpdate()
        }
      })
    }

    repair.forEach(item => {
      if(item.isChecked === true){
        RepairReasons.push(item.value)
      }
    })

    RepairReasons.forEach(item => {
      if(item === 'Missing center diamond'){
        this.props.history.push(`/lost-ring/${_.get(orderDetail, 'order.order_number', '')}`)
      } else{
        repair.forEach(options => {
          if(options.value === 'Missing center diamond'){
            options.disabled = true
          }
        })
      }
    })

    if(RepairReasons.length === 0){
      repair.forEach(options => {
        if(options.value === 'Missing center diamond'){
          options.disabled = false
        }
      })
    }
    if(option.value === 'Need a different ring size'){
      InsideRepairOption.forEach(item => {
        if(item.isChecked === true && !_.includes(RepairReasons,item.value)){
          RepairReasons.push(item.value)
        }
      })
    }
    this.setState({ RepairOption: repair, RepairReasons: RepairReasons })
    this.forceUpdate()
  }

  InsideSelectReason(option){
    const { InsideRepairOption } = this.state
    let RepairReasons = ['Need a different ring size']
    InsideRepairOption.forEach(options =>{
      if(options.value === option.value){
        options.isChecked = !options.isChecked
      }
    })

    InsideRepairOption.forEach(item =>{
      if(item.value === 'Other' && item.isChecked === true){
        this.setState({ showOther: 'Other' })
      } else if (item.value === 'Other' && item.isChecked === false){
        this.setState({ showOther: '' })
      }
    })

    InsideRepairOption.forEach(item => {
      if(item.isChecked === true){
        RepairReasons.push(item.value)
      }
    })

    this.setState({ InsideRepairOption: InsideRepairOption, RepairReasons: RepairReasons })
    this.forceUpdate()
  }

  continueWithRequest(){
    const { orderDetail, RepairReasons, otherReason } = this.state
    let otherStatus = false
    RepairReasons.forEach((item,i) =>{
      if(item === 'Other'){
        RepairReasons[i] = otherReason
        otherStatus = true
      }
    })
    const data = {
      RepairReasons: RepairReasons,
      someDetails: this.state.someDetails,
      images: this.state.images,
      ringSize: this.state.ringSize,
      otherReason: otherReason,
      otherStatus: otherStatus,
      showOther: this.state.showOther
    }
    this.props.history.push({
      pathname : `/repair/overview/${_.get(orderDetail, 'order.order_number', '')}`,
      state : { detail : data }
    })
  }

  handleChangeFile = async (files) => {
    const { images } = this.state
    this.setState({ files: files, isLoader: true })
    let x = -1
    const nextFun = async() => {
      x++
      if (x < files.length) {
        const data = new FormData()
        data.append('file', files[x])
        data.append('fileName', `${parseInt(Math.random() * 1000)}-${Date.now()}-${files[x].name}`)
        const { value } = await this.props.uploadImage(data)
        if (value && value.status) {
          images.push(value.data)
          nextFun()
        } else if (value && !value.status) {
          nextFun()
        } else {
          nextFun()
        }
      } else{
        this.setState({ images: images, isLoader: false })
        this.forceUpdate()
      }
    }
    nextFun()
  }

  removeImage(index){
    const { images } = this.state
    images.splice(index, 1)
    this.setState({ images })
    this.forceUpdate()
  }

  editShippingAddress(key) {
    if (key === 'editAddressModal') {
      const valAddress = _.cloneDeep(this.state.shippingAddress)
      this.setState({ [key]: true, valAddress })
      this.forceUpdate()
    }
  }
  handleChangeAddress(e) {
    const { shippingAddress } = this.state
    shippingAddress[e.target.name] = e.target.value
    this.setState({ shippingAddress })
    this.forceUpdate()
  }
  handleChangeCountry = (name, value) => {
    const { shippingAddress } = this.state
    shippingAddress[name] = value
    if(name === 'country'){
      let countryshortForm = getCode(value)
      if(!countryshortForm){
        const countiesCodeName = getData()
        _.map(countiesCodeName, list => {
          if(_.get(list, 'name', '') && _.get(list, 'name', '').indexOf(value) !== -1){
            countryshortForm = _.get(list, 'code', '')
          }
        }) 
      }
      this.setState({ phoneNumberCountry: countryshortForm, shippingAddress })
    }
    this.forceUpdate()
  }

  handleChangePhone = (name, value) => {
    const { shippingAddress } = this.state
    shippingAddress[name] = value
    this.setState({ shippingAddress })
    this.forceUpdate()
  }
  handleEditAddress = async() => {
    const { shippingAddress, orderDetail } = this.state
    const err = {}
    if (!shippingAddress.first_name || shippingAddress.first_name === '' ) {
      err.first_name = 'First Name is required.'
    }
    if (!shippingAddress.last_name || shippingAddress.last_name === '' ) {
      err.last_name = 'Last Name is required.'
    }
    if (!shippingAddress.address1 || shippingAddress.address1 === '' ) {
      err.address = 'Address is required.'
    }
    if (!shippingAddress.city || shippingAddress.city === '' ) {
      err.city = 'City is required.'
    }
    if (!shippingAddress.province || shippingAddress.province === '' ) {
      err.state = 'State is required.'
    }
    if (!shippingAddress.province || shippingAddress.province === '' ) {
      err.state = 'State is required.'
    }
    if (!shippingAddress.country || shippingAddress.country === '' ) {
      err.state = 'Country is required.'
    }
    if(Object.keys(err).length === 0) {
      const obj = {
        id: _.get(orderDetail, '_id', ''),
        orderId: _.get(orderDetail, 'order.id', ''),
        shipping_address: shippingAddress,
        addressType: 'shippingAddress'
      }
      this.setState({ isLoader: true })
      const { value } = await this.props.updateShippingAddress(obj)
      if(_.get(value, 'status', false)) {
        message.error(_.get(value, 'message', ''), () => {
          this.setState({ errSup: {}, editAddressModal: false, shippingAddress: _.get(value, 'shippingAddress', '') })
        })
        this.componentDidMount()
      } else {
        message.error(_.get(value, 'message', ''), () => {
          this.setState({ isLoader: false, errorMessage: _.get(value, 'message.shipping_address', []) })
        })
      }
    } else {
      this.setState({ errSup: err, isLoader: false })
    }
    // this.componentDidMount()
  }

  async checkboxProduct(){
    const { orderDetail } = this.state
    let order_number = _.get(orderDetail,'order.order_number','')
    Cookies.remove('customerId')
    Cookies.remove('orderId')
    window.open(`${process.env.REACT_APP_ADD_CARD_URL}?_connected_order=${order_number}`,"_self")
  }

  render() {
    const { orderDetail, product, userName, RepairOption, selectedTab, InsideRepairOption, isLoader, RepairReasons, errSup, shippingAddress, images, ringSizer, ringSizeItem } = this.state
    const image = _.filter(_.get(orderDetail, "images", []), filter => {
      return String(filter.id) === String(product.id);
    })
    const title = _.get(product, "name", "").split("/")
    const ringSize = _.filter(_.get(product, "properties", []), filter => {
      return filter.name === "RingSize";
    })


    let OldRingSize = _.get(ringSize, "[0].value", "")
    if(_.size(ringSizeItem) > 0 && _.get(ringSizeItem,'[0].newRingsize','') !== ''){
      OldRingSize = _.get(ringSizeItem,'[0].newRingsize','')
    }

    let isDisable = true
    if(this.state.showRingOption !== 'Need a different ring size'){
      if(this.state.showOther !== 'Other'){
        if(RepairReasons.length !== 0 && this.state.someDetails !== '' && images.length !== 0){
          isDisable = false
        }
      } else if(RepairReasons.length !== 0 && this.state.someDetails !== '' && images.length !== 0 && this.state.otherReason !== ''){
        isDisable = false
      }
    } else{
      if(RepairReasons.length !== 0 && this.state.someDetails !== '' && images.length !== 0 && this.state.ringSize !== '' ){
        isDisable = false
      }
    } 
    return (
      <>
        {isLoader ? (
          <Loader />
        ) : (
        <>
        <TopNavigation userName={userName} orderDetail={orderDetail} {...this.props}/>
          <div className="mini-container-wrapper">
            <div className="mini-container">
              <div className="head-wrap head-wrap-space">
                <div className="head-wrap-back">
                  <button type="button" className="btn btn-sm btn-back" >
                    <Link to={`/select-action/${_.get(orderDetail, 'order.order_number', '')}`}>
                      <BackIcon/>
                    </Link>
                  </button>
                </div>
                <div className="head-wrap-title">
                  Resize or Repair Your Ring
                </div>
              </div>
              <div className="horizontal-product">
                <div className="product-thumbnail">
                  {_.get(image, "[0].image", "/default.webp") !== "" && (
                    <img
                      src={_.get(image, "[0].image", "/default.webp")}
                      alt={""}
                    />
                  )}
                </div>
                <div>
                  <h4 className="product-name">{_.get(product,'title','')}</h4>
                  <p className="product-shape-color">{title[1]}</p>
                </div>
              </div>
              <div className="mini-container-inner">
                <h5 className="section-title">Tell us what needs attention on your ring </h5>
                <p className="section-subtitle">Select all that apply</p>
                <ul className="select-options-list">
                {_.map(RepairOption, (option, i) => {
                  return(
                  <li key={i} class={option.disabled && 'disabled'}>
                    <label className="custom-checkbox">
                      {option.value}
                      <input
                        type="checkbox"
                        className="custom-checkbox--input"
                        name="ReturnReason"
                        disabled={option.disabled}
                        checked={option.isChecked}
                        onChange={this.selectReason.bind(this, option)}
                        required
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                )
                })}
                  <div className="">
                  {this.state.showRingOption === 'Need a different ring size' ?
                    <div>
                      <div className="current-size">Current size: {OldRingSize}</div>
                      <div className="tabs-control mb-3">
                        <button
                          className={`btn ${selectedTab === 'Select New Size' && 'active'}`}
                          onClick={this.changeTab.bind(this, 'selectedTab','Select New Size')}
                        >Select New Size</button>
                        <button
                          className={`btn ${selectedTab === 'I don’t know My Size' && 'active'}`}
                          onClick={this.changeTab.bind(this, 'selectedTab', 'I don’t know My Size')}
                        >I don’t know My Size</button>
                      </div>
                      {selectedTab === 'Select New Size' ?
                        <div>
                          <div className="form-group">
                            <label className="form-label">Select your New Ring Size:</label>
                            <select
                              type="select"
                              className="form-control custom-select"
                              name="ringSize"
                              value={this.state.ringSize}
                              onChange={this.handleOnChange.bind(this)}
                            >
                              <option value="">Select</option>
                              {_.map(RingSizeOption, (option,i) => {
                                return(
                                    <option key={i} value={option} >{option}</option>
                                  )
                              })
                              }
                            </select>
                          </div>
                          <ul className="select-options-list">
                          {_.map(InsideRepairOption, (option,i) =>{
                            return(
                              <li key={i} class={option.disabled && 'disabled'}>
                                <label className="custom-checkbox">
                                  {option.value}
                                  <input
                                    type="checkbox"
                                    className="custom-checkbox--input"
                                    name="ReturnReason"
                                    disabled={option.disabled}
                                    checked={option.isChecked}
                                    onChange={this.InsideSelectReason.bind(this, option)}
                                    required
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </li>
                            )
                          })}
                          </ul>
                        </div>
                      :
                        <div className="form-group">
                          <div className="form-group">
                            <p className="text-muted">No problem, we'll send you a complimentary ring sizer so you can find your perfect ring size!</p>
                            <p className="text-muted">When you figure out your size come back here and select your site.</p>
                          </div>
                          <div className="form-group">
                            <div className="product-thumbnail">
                              <img src={MeasurementRings} alt=""/>
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="label-info-text">Delivery Address</label>
                            <div className="address-info">
                              {this.state.first_name} {this.state.last_name}<br/>
                              {this.state.address1} {this.state.address2}, {this.state.city}, {this.state.province_code} {this.state.zip}
                              {/*<span onClick={this.editShippingAddress.bind(this, 'editAddressModal')} className="btn-edit-address">Edit Address</span>*/}
                            </div>
                          </div>
                          <hr className="horizontal-line"/>
                          <div className="form-group">
                            <label className="label-info-text">Summary</label>
                            <ul className="summary-info">
                              <li>
                                <span>Total Price </span> <span>$0.00</span>
                              </li>
                            </ul>
                          </div>
                          <button type="button" disabled={ringSizer !== null ? true : false} className="btn btn-dark w-100" onClick={this.checkboxProduct.bind(this)} >{ringSizer !== null ? 'ALREADY PURCHASED' : 'CHECKOUT'}</button>
                        </div>
                      }
                    </div> : ''
                  }
                  </div>
                </ul>

                {this.state.showOther === 'Other' ?
                  <div className="form-group">
                    <label className="form-label">other Reason:</label>
                    <textarea
                      className="form-control"
                      placeholder="If your issue is not listed above, tell us here what needs attention on your ring..."
                      rows="5"
                      name="otherReason"
                      value={this.state.otherReason}
                      onChange={this.handleOnChange.bind(this)}
                    >
                    </textarea>
                  </div> : ''
                }
                {selectedTab === 'Select New Size' ?
                <div>
                  <div className="form-group">
                    <label className="form-label">give us some More Details <span className="text-danger">*</span></label>
                    <textarea
                      className="form-control"
                      placeholder="Tell us what happened to your ring... "
                      rows="7"
                      name="someDetails"
                      value={this.state.someDetails}
                      onChange={this.handleOnChange.bind(this)}
                    >
                    </textarea>
                  </div>
                  <div className="form-group">
                    <label className="form-label">Upload Photo(s) <span className="text-danger">*</span></label>
                    <div className="dropzone dropzone-sm">
                      <Dropzone
                        onDrop={this.handleChangeFile.bind(this)}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className="upload-container">
                            <div className="dropzone-inner-area" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="upload-text">
                                Drag & Drop <br /> or
                                <span className="btn-upload">Upload Photos</span>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                    <ul className="uploaded-list">
                      {images && _.map(images, (img,i) => {
                        return(
                          <li key={i}>
                            <div className="uploaded-thumb">
                              <div className="uploaded-thumb-delete">
                                <button onClick={this.removeImage.bind(this, i)} className="btn btn-delete"><DeleteIcon/></button>
                              </div>
                              <img src={img} alt=""/>
                            </div>
                          </li>
                        )
                        })
                      }
                    </ul>
                  </div>
                  <button
                    disabled={isDisable}
                    type="button"
                    className="btn btn-dark w-100"
                    onClick={this.continueWithRequest.bind(this)}
                  >Continue with  REQUEST</button>
                  </div> : ''
                }
              </div>
            </div>
          </div>

          <ReactModal
            isOpen={this.state.editAddressModal}
            onRequestClose={this.closeModal.bind(this)}
            ariaHideApp={false}
            contentLabel="Edit Shippping Address"
            portalClassName="react-modal"
            overlayClassName="modal"
            className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
          >
            <div className="modal-content">
              <div className="react-modal-header">
                <h5 className="react-modal-title">Edit Shippping Address</h5>
                <button type="button" className="btn react-modal-close" onClick={this.closeModal.bind(this)}><CloseIcon/></button>
              </div>
              <div className="react-modal-body">
                {this.state.errorMessage && this.state.errorMessage.length ? (
                  <div className="notice-attention">
                    <div>
                      <h6 className="notice-attention-heading">
                        There was {this.state.errorMessage.length} error on this form:
                      </h6>
                      {
                        _.map(this.state.errorMessage, message  => {
                          return(
                            <p className="notice-attention-subheading">
                              {message}
                            </p>
                          )
                        })
                      }
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">First Name <span className="text-danger">*</span></label>
                      <input
                        name="first_name"
                        value={shippingAddress.first_name}
                        onChange={this.handleChangeAddress.bind(this)}
                        type="text"
                        className="form-control"
                        required
                      />
                      <div className="invalid-feedback">{errSup && errSup.first_name ? errSup.first_name : ""}</div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Last Name <span className="text-danger">*</span></label>
                      <input
                        name="last_name"
                        value={shippingAddress.last_name}
                        onChange={this.handleChangeAddress.bind(this)}
                        type="text"
                        className="form-control"
                        required
                      />
                      <div className="invalid-feedback">{errSup && errSup.last_name ? errSup.last_name : ""}</div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">company </label>
                  <input
                    name="company"
                    value={_.get(shippingAddress, "company", "")}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Address <span className="text-danger">*</span></label>
                  <input
                    name="address1"
                    value={_.get(shippingAddress, "address1", "")}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                  <div className="invalid-feedback">{errSup && errSup.address ? errSup.address : ""}</div>
                </div>
                <div className="form-group">
                  <label className="form-label">Apartment, suite, etc. (optional) </label>
                  <input
                    name="address2"
                    value={_.get(shippingAddress, "address2", "")}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">City <span className="text-danger">*</span></label>
                  <input
                    name="city"
                    value={_.get(shippingAddress,'city','')}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Country/Region <span className="text-danger">*</span></label>
                      <CountryDropdown
                        name="country"
                        value={_.get(shippingAddress, "country", "")}
                        onChange={this.handleChangeCountry.bind(this, 'country')}
                        classes="form-control material-textfield-input"
                      />
                      <div className="invalid-feedback">{errSup && errSup.country ? errSup.country : ""}</div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">State/Provision <span className="text-danger">*</span></label>
                      <RegionDropdown
                        name="province"
                        country={_.get(shippingAddress, "country", "")}
                        value={_.get(shippingAddress, "province", "")}
                        onChange={this.handleChangeCountry.bind(this, 'province')}
                        classes="form-control material-textfield-input"
                      />
                      <div className="invalid-feedback">{errSup && errSup.state ? errSup.state : ""}</div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group material-textfield">
                      <label className="form-label">Zip <span className="text-danger">*</span></label>
                      <input
                        name="zip"
                        value={_.get(shippingAddress,'zip','')}
                        onChange={this.handleChangeAddress.bind(this)}
                        type="number"
                        className="form-control material-textfield-input"
                        required
                      />
                      <div className="invalid-feedback">{errSup && errSup.zipcode ? errSup.zipcode : ""}</div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                <div className="country-input-field">
                  <PhoneInput
                    name="phone"
                    defaultCountry={this.state.phoneNumberCountry}
                    international={true}
                    value={_.get(shippingAddress, "phone", "")}
                    onChange={this.handleChangePhone.bind(this, 'phone')}
                    classes="form-control"
                  />
                </div>
                </div>
                <button onClick={this.handleEditAddress.bind(this)} className="btn btn-dark w-100">
                  Save
                </button>
              </div>
            </div>
          </ReactModal>
        </>
        )}
      </>
    )
  }
}
