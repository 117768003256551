import { connect } from 'react-redux'
import { 
  getOrderDetail, 
  repairRequest, 
  generateId, 
  updateShippingAddress, 
  addPaymentMethod, 
  addPayment, 
  calculateTax,
  checkAddressValidation 
} from "../../../store/order/duck"
import RepairOverviewComponent from './component'

const RepairOverviewContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
  	getOrderDetail,
  	repairRequest,
  	updateShippingAddress,
  	generateId,
  	addPaymentMethod, 
    addPayment,
    calculateTax,
    checkAddressValidation
  }
)(RepairOverviewComponent)

export default RepairOverviewContainer
