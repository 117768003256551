import React, { PureComponent } from 'react'
import { Tooltip, message } from 'antd'
import _ from 'lodash'
import Slider from 'react-slick'
import InputMask from "react-input-mask"
import ReactModal from 'react-modal'
import Cookies from 'js-cookie'
import Loader from '../../../components/loader'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import TopNavigation from '../../TopNavigation/component'
import { BackIcon, CloseIcon, QuestionIcon, LockIcon } from '../../../components/icons'
import { getOrderEmail } from '../../../constants/common'
import creditCardType from 'credit-card-type'
import config from '../../../config/index'
import load from 'load-stripe'
import VisaIcon from "../../../images/Visa.svg"
import AmericanExpressIcon from "../../../images/AmericanExpress.svg"
import DinersClubIcon from "../../../images/DinersClub.svg"
import DiscoverIcon from "../../../images/Discover.svg"
import MaestroIcon from "../../../images/Maestro.svg"
import MasterCardIcon from "../../../images/MasterCard.svg"
import UnionPayIcon from "../../../images/UnionPay.svg"
import { getCode, getData } from 'country-list'
import { CountryDropdown, RegionDropdown } from "react-country-region-selector"
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'


// const confirmSize = config.confirmSize

const randomFixedInteger = function (length) {
  return Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1))
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
}

export default class RepairOverviewComponent extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      exampleModal: false,
      orderDetail: '',
      userName: '',
      isLoader: true,
      RCFJOrder:'',
      productId:'',
      count: true,
      shippingAddress: {},
      valAddress: {},
      missingDiamondPave: '',
      missingDiamondGemstone: '',
      repairFee: 0,
      chatOpen: 0,
      cardholderName:'',
      cardExpireDate:'',
      cardNumber:'',
      cvvCode:'',
      cvvLength: 3,
      customerStripeId:'',
      paymentMethods:{},
      cardBox:false,
      addNewCard: false,
      removeCard: false,
      totalFee: 0,
      rcfjReasonCharges:[],
      quantity: 0,
      originalQty: 0,
      taxFee: 0,
      errorMessage: [],
      phoneNumberCountry: 'US',
      itemInWarranty: false,
      invalidAddress: false,
      taxSummary: [],
      shippingMethodFee:30,
      shippingMethodType:"byMail",
      shippingMethodText:"Shipping (round-trip fully insured)",
      confirmReturnAddress:false
    }
  }
  
  closeModal() {
    const { valAddress } = this.state
    this.setState({ editAddressModal: false, errSup: {}, shippingAddress: valAddress, errorMessage: [] })
  }
  
  goBack(){
    const { orderDetail, otherStatus, RepairReason } = this.state
    if(otherStatus === true){
      RepairReason.push('Other')
      RepairReason.splice(-1)
    }
    const data = {
      RepairReason: RepairReason,
      quantity: this.state.quantity,
      originalQty: this.state.originalQty,
      images: this.state.images,
      RCFJOrder: this.state.RCFJOrder,
      missingDiamondPave: this.state.missingDiamondPave,
      missingDiamondGemstone: this.state.missingDiamondGemstone,
    }
    this.props.history.push({
      pathname : `/rcfj-repair/${_.get(orderDetail, 'order.order_number', '')}`,
      state : { detail : data }
    })
  }
  
  async componentDidMount(){
    const data ={
      orderId: Cookies.get('orderId'),
      customerId: Cookies.get('customerId')
    }
    let productId = Cookies.get('productId')
    let RCFJOrder = ''
    let itemInWarranty = false
    const { value } = await this.props.getOrderDetail(data)
    if(_.get(value,'status',false)){
      if(!_.includes([null, undefined, 'null', ''], productId)){
        let orderDetail = _.get(value, 'data', {})
        const isLeapOrder = _.includes(_.get(orderDetail, 'orderTags', []), 'Leap')
        RCFJOrder = _.find(_.get(orderDetail, "order.line_items", []), filter => {
          return String(filter.id) === String(productId);
        })
        let rcfj_fulfilmentstatus = _.find(_.get(orderDetail, "fulfillmentStatusRCFJ", []), filter => {
          return String(filter.id) === String(_.get(RCFJOrder, 'id', ''))
        })

        let fulfillmentsList = _.filter(_.get(orderDetail, 'order.fulfillments', []), fulfillment => {
           // for leap order shipment status is null because they fulfill directly from store.
           return (_.includes(['delivered'], _.get(fulfillment, 'shipment_status', '')) || (_.includes([null], _.get(fulfillment, 'shipment_status', '')) && isLeapOrder ))
        })
        let deliveredDate = ''
        _.map(fulfillmentsList, fulfillment => {
          const fulfillmentLineItems = _.get(fulfillment, 'line_items')
          let lineItem = _.find(fulfillmentLineItems, v => {
            return String(v.id) === String(RCFJOrder.id)
          })
          if(lineItem){
            deliveredDate = _.get(fulfillment, 'updated_at', '')
          }
        })
        if(deliveredDate === '' && _.get(rcfj_fulfilmentstatus, "fulfillmentStatus", "") === 'delivered'){
          deliveredDate = _.get(rcfj_fulfilmentstatus, "deliveredDate", "") !== null ? _.get(rcfj_fulfilmentstatus, "deliveredDate", "") : ""
        }
        if(deliveredDate){
          let sixtyDays = new Date(deliveredDate)
          // set warranty to 3 years from the date when its delivered
          sixtyDays.setDate(sixtyDays.getDate() + 1095)
          if(new Date(deliveredDate) < new Date() && new Date() < sixtyDays){
            itemInWarranty = true
          }
        }
      }
      this.setState({
        orderDetail: _.get(value, 'data', {}),
        product: _.get(value, 'lItem', {}),
        userName: _.get(value, 'userName', {}),
        shippingAddress: _.get(value, 'data.order.shipping_address', {}) === null ? {} : _.get(value, 'data.order.shipping_address', {}),
        paymentMethods: _.get(value, 'data.paymentMethods', {}),
        RCFJOrder,
        productId,
        itemInWarranty
      })
      await this.checkAddressValidation(_.get(value, 'data.order.shipping_address', {}))
    } else{
      this.props.history.push('/no-page-found')
    }
  }
  
  componentDidUpdate(prevProps, prevState){
    if(prevProps.location.state && this.state.count){
      let RepairReason = _.get(prevProps,'location.state.detail.RepairReason',[])
      let missingDiamondPave = _.get(prevProps,'location.state.detail.missingDiamondPave','')
      let missingDiamondGemstone = _.get(prevProps,'location.state.detail.missingDiamondGemstone','')
      let quantity = _.get(prevProps,'location.state.detail.quantity',1)
      let RCFJOrder = _.get(prevProps,'location.state.detail.RCFJOrder','')
      this.setState({
        RepairReason : _.get(prevProps,'location.state.detail.RepairReason',[]),
        images : _.get(prevProps,'location.state.detail.images',[]),
        quantity : _.get(prevProps,'location.state.detail.quantity',0),
        originalQty : _.get(prevProps,'location.state.detail.originalQty',0),
        RCFJOrder : _.get(prevProps,'location.state.detail.RCFJOrder',''),
        missingDiamondPave : _.get(prevProps,'location.state.detail.missingDiamondPave',''),
        missingDiamondGemstone : _.get(prevProps,'location.state.detail.missingDiamondGemstone',''),
        count: false
      })
      this.getTotalAmount(RepairReason, missingDiamondPave, missingDiamondGemstone, quantity, RCFJOrder)
    }
  }
  
  async submit(){
    let { orderDetail, cardholderName, cardNumber, cardExpireDate, cvvCode, customerStripeId, paymentMethods, addNewCard, cardBox, RepairReason, productId, RCFJOrder, totalFee, rcfjReasonCharges, taxFee, shippingMethodFee, shippingMethodType } = this.state
    let err = {}
    const obj = {
      orderId: _.get(orderDetail, '_id', ''),
      Id: randomFixedInteger(12),
      reason: 'Repair',
      images: this.state.images,
      action:'rcfjrepair',
      rcfjReasonCharges,
      shippingMethodType:shippingMethodType,
      shippingFee: shippingMethodFee,
      subTotalFee: parseFloat(this.state.repairFee),
      totalFee,
      taxFee: parseFloat(taxFee),
      isChargeCustomer: true
    }
    let objDetail = {
      orderId: _.get(orderDetail,'order.order_number',''),
      tags: ['RCFJ'],
      isOnlyRCFJ: true,
      action:'RCLoop',
      shippingMethodType:shippingMethodType,
      shippingFee: shippingMethodFee,
      subTotalFee: parseFloat(this.state.repairFee),
      totalFee,
      taxFee: parseFloat(taxFee),
      rcfjReasonCharges,
      taxSummary: this.state.taxSummary
    }
    if(!_.includes([null,undefined,'null',''], productId)){
      let rcfj_fulfilmentstatus = []; let x = 0
      rcfj_fulfilmentstatus = _.filter(_.get(orderDetail, "fulfillmentStatusRCFJ", []), v => {
        return (String(v.id) === String(productId))
      })
      if (_.includes([undefined,null,''],rcfj_fulfilmentstatus) || rcfj_fulfilmentstatus.length === 0) {
        RCFJOrder.quantities = [{ type: 'initiaterepair', id: RCFJOrder.id, quantity: parseInt(this.state.quantity) }]
      } else {
        _.map(rcfj_fulfilmentstatus, (items,i) =>{
          if(_.get(items,'quantities',[]).length !== 0){
            _.map(_.get(items,'quantities',[]), (qunty,j) =>{
              if(qunty.type === 'initiaterepair' && x === 0){
                RCFJOrder.quantities = [{ id: qunty.id, type: qunty.type, quantity: Math.abs(this.state.quantity) }]
                x++
              }
              if(_.includes(['initiatereturn','initiaterepair'],'initiaterepair')
                && _.includes(['initiatereturn','initiaterepair'],qunty.type) && x === 0){
                RCFJOrder.quantities = [{ id: qunty.id, type: qunty.type, quantity: Math.abs(this.state.quantity) }]
                x++
              }
            })
            if(x === 0){
              RCFJOrder.quantities = [{ type: 'initiaterepair', id: RCFJOrder.id, quantity: parseInt(this.state.quantity) }]
              x++
            }
          } else{
            RCFJOrder.quantities = [{ type: 'initiaterepair', id: RCFJOrder.id, quantity: parseInt(this.state.quantity) }]
          }
        })
      }

      RepairReason.forEach((item,i) =>{
        if(item === 'Missing pavé stone (diamond or gemstone)'){
          RepairReason[i] = `${item} (Qty: ${this.state.missingDiamondPave})`
          obj.rcfjMissingDiamondPave = [{id: _.get(RCFJOrder,'id',''), missingDiamondPave:this.state.missingDiamondPave }]
        } else if(item === 'Missing diamond or gemstone'){
          obj.rcfjMissingDiamondGemstone = [{id: _.get(RCFJOrder,'id',''), missingDiamondGemstone:this.state.missingDiamondGemstone }]
          RepairReason[i] = `${item} (Qty: ${this.state.missingDiamondGemstone})`
        }
      })
      obj.rcfjReasonNote = [{ id: parseInt(productId), rcfjOption: RepairReason }]
      objDetail.selectedItems = [RCFJOrder]
    }
    this.setState({ isLoader: true })
    if(totalFee > 0){
      let paymentMethodsFields = {}
      let paymentData = {
        orderId: _.get(orderDetail,'order.order_number',''),
        id: _.get(orderDetail, 'order.customer.id', ''),
        email: getOrderEmail(orderDetail),
        isPaymentDone: _.get(orderDetail, 'order.customer.isPaymentDone', false)
      }
      if(addNewCard && cardBox){
        const err = this.validateCardDetails()
        if(Object.keys(err).length > 0) {
          this.setState({ errSup:err, isLoader: false })
        } else{
          cardExpireDate = cardExpireDate.split('/')
          const cardDetails = {
            "number": cardNumber,
            "exp_month": cardExpireDate[0],
            "exp_year":cardExpireDate[1],
            "cvc": cvvCode,
            "name": cardholderName
          }
          await load(`${process.env.REACT_APP_STRIPE_KEY}`).then(stripe => {
            stripe.card.createToken(cardDetails)
              .then(async (token, err) => {
                if(token){
                  const paymentMethodsFields = {
                    token: token.id,
                    cardId: token.card.id,
                    card: token.card,
                    object: token.object,
                  }
                  paymentData.paymentMethodsFields = paymentMethodsFields
                  const customerDetails = await this.props.addPaymentMethod(paymentData)
                  if(customerDetails && customerDetails.value && _.get(customerDetails.value, 'id', false)){
                    customerStripeId = _.get(customerDetails.value, 'id', '')
                    this.setState({ customerStripeId })
                    objDetail.customerStripeId = customerStripeId
                    objDetail.amount = totalFee
                    this.handleRepairRequest(obj, objDetail)
                  } else{
                    this.setState({ isLoader: false, errSup: err })
                  }
                }
              }).catch(err => {
                message.error(_.get(err, 'message', ''), () => {
                this.setState({ errSup: err, isLoader: false })
              })
            })
          })
        }
      } else{
        paymentData.paymentMethodsFields = paymentMethodsFields
        paymentData.paymentMethodId = _.get(paymentMethods, '_id', null)
        const customerDetails = await this.props.addPaymentMethod(paymentData)
        if(customerDetails && customerDetails.value && _.get(customerDetails.value, 'id', false)){
          customerStripeId = _.get(customerDetails.value, 'id', '')
          this.setState({ customerStripeId })
          objDetail.customerStripeId = customerStripeId
          objDetail.amount = totalFee
          this.handleRepairRequest(obj, objDetail)
        }else{
          this.setState({ errSup: err, isLoader: false })
        }
      }
    } else{
      this.handleRepairRequest(obj, objDetail)
    }
  }

  handleRepairRequest = async (obj, objDetail) => {
    const { orderDetail } = this.state
    const val = await this.props.generateId(obj)
    if (_.get(val, 'value.status', false)) {
      objDetail.Id = _.get(val,'value.data.Id','')
      objDetail.reason = _.get(val,'value.data.reason','')
      objDetail.rcfjReasonNote = _.get(val,'value.data.rcfjReasonNote',[])
      objDetail.images = _.get(val,'value.data.ringImages',[])
      objDetail.repairReturnExchangeId = _.get(val,'value.data._id','')
      const { value } = await this.props.repairRequest(objDetail)
      if (_.get(value, 'status', false)) {
        message.error(_.get(value, 'message', ''), () => {
          this.setState({ isLoader: false })
        })
        this.props.history.push(`/rcfj-repair/request-confirmation/${_.get(orderDetail, 'order.order_number', '')}`)
      } else{
        message.error(_.get(value, 'message', ''), () => {
          this.setState({ isLoader: false })
        })
      }
    }
  }

  getTotalAmount = async(RepairReason, missingDiamondPave, missingDiamondGemstone, quantity, RCFJOrder) => {
    const { paymentMethods, itemInWarranty } = this.state
    const repairCharges = config.RCFJRepairCharges
    let charges = 0; let cardBox = false; let addNewCard = false; let rcfjReasonCharges =[]
    let repairOptions = _.filter(repairCharges, track => {
      return _.includes(RepairReason, track.key)
    })
    if(_.size(repairOptions) > 0){
      _.filter(repairOptions, item => {
        if(item.key === 'Ring misshapen or bent'){
          charges = charges+item.charge
          rcfjReasonCharges.push({ reason: 'Ring misshapen or bent', quantity: quantity, charge: parseFloat(parseFloat(quantity) * parseFloat(item.charge)).toFixed(2), reas:'Ring misshapen or bent', originalCharge: parseFloat(item.charge), option: item.key })
        }
        if(item.key === 'Missing pavé stone (diamond or gemstone)'){
          const charge = !itemInWarranty ? item.charge : 0
          const cost = charge * parseFloat(missingDiamondPave)
          charges = charges + cost
          rcfjReasonCharges.push({ reason: `Missing pavé stone (diamond or gemstone) (Qty: ${missingDiamondPave})`, quantity: quantity, charge: parseFloat(parseFloat(quantity) * parseFloat(cost)).toFixed(2), reas:'Missing pavé stone (diamond or gemstone)', originalCharge: parseFloat(charge), option: item.key, missingDiamondQty: missingDiamondPave })
        }
        if(item.key === 'Missing diamond or gemstone'){
          const charge = !itemInWarranty ? item.charge : 0
          const cost = charge * parseInt(missingDiamondGemstone)
          charges = charges + cost
          rcfjReasonCharges.push({ reason: `Missing diamond or gemstone (Qty: ${missingDiamondGemstone})`, quantity: quantity, charge: parseFloat(parseFloat(quantity) * parseFloat(cost)).toFixed(2), reas:'Missing diamond or gemstone', originalCharge: parseFloat(item.charge), option: item.key, missingDiamondQty: missingDiamondGemstone })
        }
        if(item.key === 'Stone is loose, tigten prongs'){
          const charge = !itemInWarranty ? item.charge : 0
          charges = charges + charge
          rcfjReasonCharges.push({ reason: 'Stone is loose, tigten prongs', quantity: quantity, charge: parseFloat(parseFloat(quantity) * parseFloat(charge)).toFixed(2), reas:'Stone is loose, tigten prongs', originalCharge: parseFloat(charge), option: item.key })
        }
        if(item.key === 'Polish (minor scratches)'){
          charges = charges + item.charge
          rcfjReasonCharges.push({ reason: 'Polish (minor scratches)', quantity: quantity, charge: parseFloat(parseFloat(quantity) * parseFloat(item.charge)).toFixed(2), reas:'Polish (minor scratches)', originalCharge: parseFloat(item.charge), option: item.key })
        }
        if(item.key === 'Damaged chain'){
          charges = charges + item.charge
          rcfjReasonCharges.push({ reason: 'Damaged chain', quantity: quantity, charge: parseFloat(parseFloat(quantity) * parseFloat(item.charge)).toFixed(2), reas:'Damaged chain', originalCharge: parseFloat(item.charge), option: item.key })
        }
        if(item.key === 'Broken chain/clasp'){
          const charge = !itemInWarranty ? item.charge : 0
          charges = charges + charge
          rcfjReasonCharges.push({ reason: 'Broken chain/clasp', quantity: quantity, charge: parseFloat(parseFloat(quantity) * parseFloat(charge)).toFixed(2), reas:'Broken chain/clasp', originalCharge: parseFloat(charge), option: item.key })
        }
      })
      let shippingFee = this.state.shippingMethodFee 
      if(RepairReason && RepairReason.length <= 3 && itemInWarranty){
        shippingFee = this.state.shippingMethodFee
        _.map(RepairReason, reason => {
          if(!_.includes(['Missing pavé stone (diamond or gemstone)', 'Stone is loose, tigten prongs', 'Broken chain/clasp'], reason)){
            shippingFee = this.state.shippingMethodFee
          }
        })
      }
      const repairFee = parseFloat(charges) * parseInt(quantity)
      if (repairFee === 0) {
        shippingFee = 0
        this.setState({ shippingMethodFee: 0 });
      }
      let totalFee = repairFee + shippingFee
      cardBox = totalFee > 0 ? true : false
      cardBox = _.get(paymentMethods, '_id', '') !== '' ? false : cardBox
      addNewCard = cardBox ? true : false
      this.setState({ repairFee: parseFloat(charges) * parseInt(quantity), cardBox, addNewCard, rcfjReasonCharges, shippingFee })
      if(totalFee > 0) {
        await this.calculateTax(totalFee, quantity, RCFJOrder, repairFee, shippingFee)
        this.setState({ isLoader: false })
      } else {
        this.setState({ isLoader: false })
      } 
      return (true)
    }
  }

  async calculateTax(totalFee, quantity, RCFJOrder, repairFee, shippingFee){
    const { orderDetail, shippingAddress } = this.state
    let taxFee = 0
    const lineItems = [
      {
        title: _.get(RCFJOrder, 'title', ''),
        variant_title: _.get(RCFJOrder, 'variant_title', ''),
        id: _.get(RCFJOrder, 'id', ''), 
        amount: parseFloat(repairFee), 
        quantity: quantity,
        type: 'repair'
      },
      {
        title: 'shipping Fee',
        variant_title: 'shipping Fee',
        id: Date.now(), 
        amount: parseFloat(shippingFee), 
        quantity: 1,
        type: 'shipping'
      }
    ]
    const obj = {
      taxType: 'repair',
      lineItems,
      shippingAddress: shippingAddress ? shippingAddress : _.get(orderDetail,'order.shipping_address',''),
      customerCode: _.get(orderDetail,'order.customer.email','')
    }
    const { value } = await this.props.calculateTax(obj)
    if(_.get(value,'status',false)){
      _.map(_.get(value,'taxSummary',[]), taxLine => {
        if(_.get(taxLine,'tax',0)){
          taxFee += parseFloat(_.get(taxLine,'tax',0))
        }
      })
    }
    totalFee += taxFee
    this.setState({ 
      totalFee, 
      taxFee: (parseFloat(taxFee)).toLocaleString(undefined, {minimumFractionDigits: 2}), 
      taxSummary: _.get(value,'taxSummary',[]), 
      isLoader: false 
    })
    return (true)
  }

  editShippingAddress(key) {
    if (key === 'editAddressModal') {
      const valAddress = _.cloneDeep(this.state.shippingAddress)
      this.setState({ [key]: true, valAddress })
      this.forceUpdate()
    }
  }
  handleChangeAddress(e) {
    const { shippingAddress } = this.state
    shippingAddress[e.target.name] = e.target.value
    this.setState({ shippingAddress })
    this.forceUpdate()
  }
  async handleChangeShippingMethod(e) {
    this.setState({
      isLoader: true,
      shippingMethodText: this.shippingMethodText(e.target.getAttribute("shippingMethodType")),
      shippingMethodType: e.target.getAttribute("shippingMethodType"),
      shippingMethodFee: parseFloat(e.target.value.replace(/[^0-9.-]+/g, ''))
    },() =>{
      const {RepairReason, missingDiamondPave, missingDiamondGemstone, quantity, RCFJOrder} = this.state
      this.getTotalAmount(RepairReason, missingDiamondPave, missingDiamondGemstone, quantity, RCFJOrder)
    });
    
  }

  shippingMethodText(shippingMethodType) {
    let status = ''
    switch (shippingMethodType) {
      case 'byMail':
        status = 'Shipping (round-trip fully insured)'
        break
      case 'bleeckerStore':
        status = 'Shipping (Return Shipping Fee)'
        break
      case 'madisonStore':
        status = 'Shipping (Return Shipping Fee)'
        break
      default:
        status = 'Shipping (round-trip fully insured)'
        break
      }
    return status
  }
  
  handlePaymentDetailChange(event){
    this.setState({ [event.target.name]:event.target.value })
    if (event.target.name === 'cardNumber') {
      this.handleCreditCardChange(event.target.value)
    }
    this.forceUpdate()
  }
  
  handleCreditCardChange = (cardNumber) => {
    var cardType = creditCardType(cardNumber)
    this.setState({
      cvvLength: _.get(cardType[0], 'code.size', 3),
    })
  }
  
  validateCardDetails() {
    const err = {}
    const { cardholderName, cardNumber, cardExpireDate, cvvCode } = this.state
    if (cardNumber === '') {
      err.cardNumber = 'Card number is required'
    }
    if (cardholderName === '') {
      err.cardholderName = 'Card name is required'
    }
    if (cvvCode === '') {
      err.cvvCode = 'Cvv is required'
    }
    if (cardExpireDate === '') {
      err.cardExpireDate = 'Expiration date is required'
    }
    return err
  }

  handleChangeCountry = (name, value) => {
    const { shippingAddress } = this.state
    shippingAddress[name] = value
    if(name === 'country'){
      let countryshortForm = getCode(value)
      if(!countryshortForm){
        const countiesCodeName = getData()
        _.map(countiesCodeName, list => {
          if(_.get(list, 'name', '') && _.get(list, 'name', '').indexOf(value) !== -1){
            countryshortForm = _.get(list, 'code', '')
          }
        }) 
      }
      this.setState({ phoneNumberCountry: countryshortForm, shippingAddress })
    }
    this.forceUpdate()
  }

  handleChangePhone = (name, value) => {
    const { shippingAddress } = this.state
    shippingAddress[name] = value
    this.setState({ shippingAddress })
    this.forceUpdate()
  }
  
  handleEditAddress = async() => {
    const { shippingAddress, orderDetail, repairFee, shippingFee, quantity, RCFJOrder } = this.state
    const err = {}
    if (!shippingAddress.first_name || shippingAddress.first_name === '' ) {
      err.first_name = 'First Name is required.'
    }
    if (!shippingAddress.last_name || shippingAddress.last_name === '' ) {
      err.last_name = 'Last Name is required.'
    }
    if (!shippingAddress.address1 || shippingAddress.address1 === '' ) {
      err.address = 'Address is required.'
    }
    if (!shippingAddress.city || shippingAddress.city === '' ) {
      err.city = 'City is required.'
    }
    if (!shippingAddress.province || shippingAddress.province === '' ) {
      err.state = 'State is required.'
    }
    if (!shippingAddress.province || shippingAddress.province === '' ) {
      err.state = 'State is required.'
    }
    if (!shippingAddress.country || shippingAddress.country === '' ) {
      err.state = 'Country is required.'
    }
    if(Object.keys(err).length === 0) {
      const obj = {
        id: _.get(orderDetail, '_id', ''),
        orderId: _.get(orderDetail, 'order.id', ''),
        shipping_address: shippingAddress,
        addressType: 'shippingAddress'
      }
      this.setState({ isLoader: true })
      const { value } = await this.props.updateShippingAddress(obj)
      if(_.get(value, 'status', false)) {
        message.error(_.get(value, 'message', ''))
        this.setState({ errSup: {}, editAddressModal: false, shippingAddress: _.get(value, 'shippingAddress', '') }, () => {
          this.forceUpdate()
        })
        if (repairFee > 0) {
          const totalFee = repairFee + 30
          await this.calculateTax(totalFee, quantity, RCFJOrder, repairFee, shippingFee)
        }
        this.setState({ editAddressModal: false, isLoader: false })
        await this.checkAddressValidation(_.get(value, 'shippingAddress', ''))
      } else {
        this.setState({ isLoader: false, errorMessage: _.get(value, 'message.shipping_address', []) })
      }
    } else {
      this.setState({ errSup: err, isLoader: false })
    }
  }

  addNewCardDetails= () =>{
    this.setState({ cardBox: true, addNewCard: true, removeCard: true })
    this.forceUpdate()
  }

  removeCard = () =>{
    this.setState({ cardBox: false, addNewCard: false, removeCard: false})
    this.forceUpdate()
  }
  
  openChat(){
    const { chatOpen } = this.state
    if(chatOpen === 0){
      window.GorgiasChat.open()
      this.setState({ chatOpen: 1 })
    } else{
      window.GorgiasChat.close()
      this.setState({ chatOpen: 0 })
    }
  }

  checkAddressValidation = async (shippingAddress) => {
    if(_.size(shippingAddress)){
      const { value } = await this.props.checkAddressValidation({ shippingAddress })
      if(!_.get(value, 'status', false)){
        this.setState({ messageShown: true })
        message.error(_.get(value, 'message', ''), () => {
          this.setState({ messageShown: false, invalidAddress: true })
        })
      } else {
        this.setState({ invalidAddress: false })
      }
    } else {
      this.setState({ invalidAddress: true })
    }
  }
  handleConfirmReturnAddress = () => {
    const { confirmReturnAddress } = this.state
    this.setState({ confirmReturnAddress: !confirmReturnAddress})
  }

  render() {
    const { orderDetail, invalidAddress, userName, isLoader, errSup, shippingAddress, productId, RCFJOrder, RepairReason, missingDiamondPave, missingDiamondGemstone, images, paymentMethods, cardBox, rcfjReasonCharges, confirmReturnAddress } = this.state
    const image = _.filter(_.get(orderDetail, "images", []), filter => {
      return String(filter.id) === String(productId);
    })
    // let diamondQuantity = missingDiamondPave !== '' ? missingDiamondPave : missingDiamondGemstone
    return (
      <>
        {isLoader ? (
          <Loader />
        ) : (
          <>
          <TopNavigation userName={userName} orderDetail={orderDetail} {...this.props}/>
          <div className="mini-container-wrapper">
            <div className="mini-container">
              <div className="head-wrap head-wrap-space">
                <div className="head-wrap-back">
                  <button type="button" className="btn btn-sm btn-back" onClick={this.goBack.bind(this)}>
                    <BackIcon/>
                  </button>
                </div>
                <div className="head-wrap-title">
                  Overview
                </div>
              </div>
              <div className="horizontal-product">
                <div className="product-thumbnail">
                  {_.get(image, "[0].image", "/default.webp") !== "" && (
                    <img
                      src={_.get(image, "[0].image", "/default.webp")}
                      alt={""}
                    />
                  )}
                </div>
                <div>
                  <h4 className="product-name">{_.get(RCFJOrder,'title','')}</h4>
                  <p className="product-shape-color">{_.get(RCFJOrder,'variant_title','')}</p>
                </div>
              </div>
              <div className="mini-container-inner">
                  <div className="form-group">
                    <label className="label-info-text">What do you need repaired? </label>
                    {/* previous code when only one reason selected*/} 
                    {/*<div>
                      <span className="amount-text">${parseFloat(parseFloat(this.state.repairFee) * parseInt(this.state.quantity)).toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
                      {this.state.quantity > 1 ?
                        <p>{_.get(RepairReason,'[0]','')} (Qty: {this.state.quantity})</p>
                      :
                        <p>{_.get(RepairReason,'[0]','')}</p>
                      }
                      {_.includes(['Missing pave stone (diamond or gemstone)','Missing diamond or gemstone'],_.get(RepairReason,'[0]','')) ?
                        <p>{diamondQuantity} * ${missingDiamondPrice}</p>
                      : ''}
                    </div>*/}
                  {/* multiple reason option and price*/} 
                    {_.map(rcfjReasonCharges, rcfjReason =>{
                      if(_.get(rcfjReason,'reas','') === 'Missing pavé stone (diamond or gemstone)'){
                        return(
                          <div>
                            <span className="amount-text">${parseFloat(parseFloat(_.get(rcfjReason,'charge',0))).toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
                            <p>{_.get(rcfjReason,'reas','')} (Qty:{this.state.quantity})</p>
                            <p>{missingDiamondPave} * ${_.get(rcfjReason,'originalCharge',0)}</p>
                          </div>
                        )
                      } else if(_.get(rcfjReason,'reas','') === 'Missing diamond or gemstone'){
                        return(  
                          <div>
                            <span className="amount-text">
                              {this.state.itemInWarranty && <span className="cut-price">${parseFloat(parseFloat(_.get(rcfjReason, 'originalCharge', 0)) * parseFloat(_.get(rcfjReason, 'missingDiamondQty', 0)) * this.state.quantity)}</span>}
                              ${parseFloat(parseFloat(_.get(rcfjReason, 'charge', 0))).toLocaleString(undefined, { minimumFractionDigits: 2 })}</span>
                            <p>{_.get(rcfjReason, 'reas', '')} (Qty:{this.state.quantity})</p>
                            <p>{missingDiamondGemstone} * ${_.get(rcfjReason, 'originalCharge', 0)}</p>
                          </div>
                        )
                      } else{
                        return(
                          <div>
                            <span className="amount-text">${parseFloat(parseFloat(_.get(rcfjReason,'charge',0))).toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
                            <p>{_.get(rcfjReason,'reas','')} (Qty:{this.state.quantity})</p>
                          </div>
                        )
                      }
                    })}
                  </div>
                  <div className="form-group">
                    <label className="label-info-text">Photo</label>
                      <Slider
                        {...settings}
                        className="slick-carousel-slider"
                      >
                        {images && _.map(images, (img,i) =>{
                          return(
                            <div key={i} className="slick-carousel-slider-items">
                              <div className="product-thumbnail mb-0">
                                <img src={img} alt=""/>
                              </div>
                            </div>
                          )
                        })}
                      </Slider>
                  </div>

                <div className="form-group return-address-box-color">
                  <label className="label-info-text">Return Address</label>
                  <div className="address-info">
                    {_.get(shippingAddress,'first_name','')} {_.get(shippingAddress,'last_name','')}<br/>
                    {_.get(shippingAddress,'address1','')} {_.get(shippingAddress,'address2','')}, {_.get(shippingAddress,'city','')}, {_.get(shippingAddress,'province_code','')} {_.get(shippingAddress,'zip','')}
                    <span onClick={this.editShippingAddress.bind(this, 'editAddressModal')} className="btn-edit-address">Edit Address</span>
                  </div>
                </div>
                <div>
                  <span class="d-flex -align-items-center filter__check">
                    <label class="custom-checkbox custom-checkbox-small custom-checkbox-line">
                    <input
                      name="confirmReturnAddress"
                      type="checkbox"
                      defaultChecked={this.state.confirmReturnAddress}
                      onClick={this.handleConfirmReturnAddress.bind(this)}
                      className="custom-checkbox--input" />
                    <span class="checkmark"></span>Confirm Your Return Address
                    </label>
                  </span>
                 </div>

                {
                  !cardBox && this.state.totalFee > 0  && !this.state.addNewCard ? (
                    <div className="">
                      <ul className="payment-card-list">
                        <li>
                          <div className="payment-card-info"><span className="card-icon">
                          {
                            _.get(paymentMethods, 'card.brand', '') === 'Visa' ? (
                              <img src={VisaIcon} alt="" height="32"/>
                            ) : (
                              _.get(paymentMethods, 'card.brand', '') === 'MasterCard' ? (
                                  <img src={MasterCardIcon} alt="" height="32"/>
                                ) : (
                                  _.get(paymentMethods, 'card.brand', '') === 'American Express' ? (
                                      <img src={AmericanExpressIcon} alt="" height="32"/>
                                    ) : (
                                      _.get(paymentMethods, 'card.brand', '') === 'Discover' ? (
                                          <img src={DiscoverIcon} alt="" height="32"/>
                                        ) : (
                                          _.get(paymentMethods, 'card.brand', '') === 'Diners Club' ? (
                                              <img src={DinersClubIcon} alt="" height="32"/>
                                            ) : (
                                              _.get(paymentMethods, 'card.brand', '') === 'UnionPay' ? (
                                                  <img src={UnionPayIcon} alt="" height="32"/>
                                                ) : (
                                                  _.get(paymentMethods, 'card.brand', '') === 'Maestro' ? (
                                                      <img src={MaestroIcon} alt="" height="32"/>
                                                    ) : (
                                                      ''
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                          }

                          </span><span class="card-number">{_.get(paymentMethods, 'card.brand', '')}****{_.get(paymentMethods, 'card.last4', '')}</span></div>
                          <div>Expiration Date {_.get(paymentMethods, 'card.exp_month', '')}/{`${_.get(paymentMethods, 'card.exp_year', '')}`.toString().substr(-2)}</div>
                          {/* <div>Expire date 20/7<button className="btn btn-link w-120">Expire date 20/7</button></div> */}
                        </li>
                      </ul>
                      <div className="form-group">
                        <button type="button" className="btn btn-dark w-100" onClick={this.addNewCardDetails.bind(this)}>Add New Card</button>
                      </div>
                    </div>
                  ) : (
                    ''
                  )
                }
                {
                  cardBox ? (
                    <div>
                      <hr className="horizontal-line"/>
                      <div className="form-group">
                        <label className="form-label">Cardholder name</label>
                        <input
                          type="text"
                          name="cardholderName"
                          className="form-control"
                          onChange={this.handlePaymentDetailChange.bind(this)}
                          // onBlur={handleBlur}
                          value={this.state.cardholderName}
                          required
                        />
                        <div className="invalid-feedback">{errSup && errSup.cardholderName ? errSup.cardholderName : ""}</div>
                      </div>
                      <div className="form-group material-textfield">
                        <label className="form-label">Card Number</label>
                        <InputMask
                          maskChar={null}
                          mask="9999 9999 9999 9999"
                          name="cardNumber"
                          className="form-control"
                          onChange={this.handlePaymentDetailChange.bind(this)}
                          // onBlur={handleBlur}
                          value={this.state.cardNumber}
                          required
                        />
                        <button type="button" className="btn info-tooltip"><LockIcon/></button>
                        <div className="invalid-feedback">{errSup && errSup.cardNumber ? errSup.cardNumber : ""}</div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">MM/YY</label>
                            <InputMask
                              mask="99/99"
                              maskChar={null}
                              name="cardExpireDate"
                              className="form-control"
                              onChange={this.handlePaymentDetailChange.bind(this)}
                              // onBlur={handleBlur}
                              value={this.state.cardExpireDate}
                              required
                            />
                            <div className="invalid-feedback">{errSup && errSup.cardExpireDate ? errSup.cardExpireDate : ""}</div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <label className="form-label">CVV</label>
                            <InputMask
                              mask={this.state.cvvLength === 3 ? '999' : '9999'}
                              maskChar={null}
                              name="cvvCode"
                              className="form-control"
                              onChange={this.handlePaymentDetailChange.bind(this)}
                              // onBlur={handleBlur}
                              value={this.state.cvvCode}
                              required
                            />
                            <Tooltip placement="top" title="3-digit security code usually found on the back of
                                your card. American Express cards have a 4-digit
                                code located on the front.">
                              <button type="button" className="btn info-tooltip"><QuestionIcon/></button>
                            </Tooltip>
                            <div className="invalid-feedback">{errSup && errSup.cvvCode ? errSup.cvvCode : ""}</div>
                          </div>
                        </div>
                      </div>
                      {
                       this.state.removeCard ? (
                         <div className="form-group">
                           <button type="button" className="btn btn-dark w-100" onClick={this.removeCard.bind(this)}>Cancel</button>
                         </div>
                       ) : ('')
                      }
                    </div>
                  ) : ('')
                }
                  <div className='RadioWrapper'>
                   <label class="form-label">Shipping Method</label>
                    <ul className='RadioList'>
                      <li>
                      <p>
                        <input 
                        type="radio" 
                        id="shippingFeeCharge" 
                        name="radio-group"
                        shippingMethodType="byMail"
                        checked={this.state.shippingMethodType === "byMail" ? true : false }
                        onChange={this.handleChangeShippingMethod.bind(this)}
                        value={this.state.repairFee === 0 ? 0 : "$30.00" } />
                        <label for="shippingFeeCharge">
                          By mail
                          <h4>{this.state.repairFee === 0 ? (<span style={{ fontWeight: 'bold' }}>FREE</span>) : ("$30")} round trip, fully-insured shipping fee</h4>
                        </label>
                      </p>
                      </li>
                      <li>
                      <p>
                        <input 
                        type="radio" 
                        id="bleeckerStoreCharge" 
                        name="radio-group"
                        shippingMethodType="bleeckerStore"
                        checked={this.state.shippingMethodType === "bleeckerStore" ? true : false }
                        onChange={this.handleChangeShippingMethod.bind(this)}
                        value={this.state.repairFee === 0 ? 0 : "$15.00" } />
                        <label for="bleeckerStoreCharge">
                            Store dropoff: <br></br>
                            Ring Concierge Bleecker Street<br></br>
                            400 Bleecker Street<br></br>
                            New York, NY 10014<br></br>
                          <h4>{this.state.repairFee === 0 ? (<span style={{ fontWeight: 'bold' }}>FREE</span>) : ("$15")} fully insured return shipping fee</h4>
                          <h4>Upon repair completion, your piece will ship to the return address confirmed above.</h4>
                        </label>
                      </p>
                      </li>
                      <li>
                      <p>
                        <input 
                        type="radio" 
                        id="madisonStoreCharge" 
                        name="radio-group"
                        shippingMethodType="madisonStore"
                        checked={this.state.shippingMethodType === "madisonStore" ? true : false }
                        onChange={this.handleChangeShippingMethod.bind(this)}
                        value={this.state.repairFee === 0 ? 0 : "$15.00" } />
                        <label for="madisonStoreCharge">
                          Store dropoff: <br></br>
                          Ring Concierge Madison Avenue<br></br>
                          946 Madison Avenue<br></br>
                          New York, NY 10021<br></br>
                          <h4>{this.state.repairFee === 0 ? (<span style={{ fontWeight: 'bold' }}>FREE</span>) : ("$15")} fully insured return shipping fee</h4>
                          <h4>Upon repair completion, your piece will ship to the return address confirmed above.</h4>
                        </label>
                      </p>
                      </li>
                    </ul>
                   </div>
                    {_.includes(RepairReason,'Damaged chain') ? 
                      <div>
                        <div className="note-text text-left">
                          Due to the delicate nature of herringbone chains, kinks to the metal are irreparable. 
                        </div>
                        <br/>
                      </div>
                    :''} 
                    <div className="note-text text-left">
                      These costs are estimates based upon the average resize/repair and are subject to change upon reviewing your piece. We will notify you should your piece require additional costs. If you selected store dropoff, your piece will ship to the above return address upon completion.
                    </div>
                    <br/>
                    {_.includes(RepairReason,'Missing pavé stone (diamond or gemstone)','Missing diamond or gemstone') ?
                      <div>
                        <div className="note-text text-left">
                          Pieces with larger individual diamonds will incur additional costs. If your piece falls into this category, a member of our team will be in touch before proceeding.
                        </div>
                        <br/>
                      </div>
                    : '' }
                    <div className="note-text text-left">
                     <b>Please note: Outside of one year and up to 3 years from date of delivery, Ring Concierge proudly will take in any piece for inspection. Once the inspection is complete Ring Concierge will contact you for next steps regarding your repair. For any damages deemed a manufacturer defect or quality related issue, Ring Concierge will repair at no cost to you. For any damages deemed normal wear and tear, a fee will be determined based on the assessment by our Jewelers. Please note that the costs associated with your initial repair requests are approximations and final pricing will be confirmed once your inspection is complete.</b>
                    </div>
                    <br/>
                    <div className="form-group">
                      <label className="label-info-text">Summary</label>
                      <ul className="summary-info">
                         {/*<li>
                           <span>Quantity</span> <span>{this.state.quantity}</span>
                         </li>*/}
                        <li>
                          <span>Repair Fee </span> <span>${parseFloat(parseFloat(this.state.repairFee)).toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
                        </li>
                        <li>
                          <span>{this.state.shippingMethodText}</span> <span>${parseFloat(this.state.shippingMethodFee).toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
                        </li>
                        <li>
                          <span>Tax </span> <span>${parseFloat(parseFloat(this.state.taxFee)).toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
                        </li>
                        <li>
                          <span>Total </span> <span>${parseFloat(this.state.totalFee).toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
                        </li>
                      </ul>
                     </div>
                    <div>
                      <button 
                        type="button" 
                        className="btn btn-dark w-100" 
                        disabled={(invalidAddress || !confirmReturnAddress)}
                        onClick={this.submit.bind(this)}
                      >
                        Submit
                      </button>
                    </div>
                <div className="chat-with-us">
                  Have questions? <span onClick={this.openChat.bind(this)}>Chat with us</span>
                </div>
              </div>
            </div>
          </div>

          <ReactModal
            isOpen={this.state.editAddressModal}
            onRequestClose={this.closeModal.bind(this)}
            ariaHideApp={false}
            contentLabel="Edit Shippping Address"
            portalClassName="react-modal"
            overlayClassName="modal"
            className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
          >
            <div className="modal-content">
              <div className="react-modal-header">
                <h5 className="react-modal-title">Edit Shippping Address</h5>
                <button type="button" className="btn react-modal-close" onClick={this.closeModal.bind(this)}><CloseIcon/></button>
              </div>
              <div className="react-modal-body">
                {this.state.errorMessage && this.state.errorMessage.length ? (
                  <div className="notice-attention">
                    <div>
                      <h6 className="notice-attention-heading">
                        There was {this.state.errorMessage.length} error on this form:
                      </h6>
                      {
                        _.map(this.state.errorMessage, message  => {
                          return(
                            <p className="notice-attention-subheading">
                              {message}
                            </p>
                          )
                        })
                      }
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">First Name <span className="text-danger">*</span></label>
                      <input
                        name="first_name"
                        value={_.get(shippingAddress,'first_name','')}
                        onChange={this.handleChangeAddress.bind(this)}
                        type="text"
                        className="form-control"
                        required
                      />
                      <div className="invalid-feedback">{errSup && errSup.first_name ? errSup.first_name : ""}</div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Last Name <span className="text-danger">*</span></label>
                      <input
                        name="last_name"
                        value={_.get(shippingAddress,'last_name','')}
                        onChange={this.handleChangeAddress.bind(this)}
                        type="text"
                        className="form-control"
                        required
                      />
                      <div className="invalid-feedback">{errSup && errSup.last_name ? errSup.last_name : ""}</div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">company </label>
                  <input
                    name="company"
                    value={_.get(shippingAddress, "company", "")}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Address <span className="text-danger">*</span></label>
                  <input
                    name="address1"
                    value={_.get(shippingAddress, "address1", "")}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                  <div className="invalid-feedback">{errSup && errSup.address ? errSup.address : ""}</div>
                </div>
                <div className="form-group">
                  <label className="form-label">Apartment, suite, etc. (optional) </label>
                  <input
                    name="address2"
                    value={_.get(shippingAddress, "address2", "")}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">City <span className="text-danger">*</span></label>
                  <input
                    name="city"
                    value={_.get(shippingAddress,'city','')}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Country/Region <span className="text-danger">*</span></label>
                      <CountryDropdown
                        name="country"
                        value={_.get(shippingAddress, "country", "")}
                        onChange={this.handleChangeCountry.bind(this, 'country')}
                        classes="form-control material-textfield-input"
                      />
                      <div className="invalid-feedback">{errSup && errSup.country ? errSup.country : ""}</div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">State/Provision <span className="text-danger">*</span></label>
                      <RegionDropdown
                        name="province"
                        country={_.get(shippingAddress, "country", "")}
                        value={_.get(shippingAddress, "province", "")}
                        onChange={this.handleChangeCountry.bind(this, 'province')}
                        classes="form-control material-textfield-input"
                      />
                      <div className="invalid-feedback">{errSup && errSup.state ? errSup.state : ""}</div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group material-textfield">
                      <label className="form-label">Zip <span className="text-danger">*</span></label>
                      <input
                        name="zip"
                        value={_.get(shippingAddress,'zip','')}
                        onChange={this.handleChangeAddress.bind(this)}
                        type="number"
                        className="form-control material-textfield-input"
                        required
                      />
                      <div className="invalid-feedback">{errSup && errSup.zipcode ? errSup.zipcode : ""}</div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="country-input-field">
                  <PhoneInput
                    name="phone"
                    defaultCountry={this.state.phoneNumberCountry}
                    international={true}
                    value={_.get(shippingAddress, "phone", "")}
                    onChange={this.handleChangePhone.bind(this, 'phone')}
                    classes="form-control"
                  />
                </div>
                </div>
                <button onClick={this.handleEditAddress.bind(this)} className="btn btn-dark w-100">
                  Save
                </button>
              </div>
            </div>
          </ReactModal>
          </>
        )}
      </>
    )
  }
}
