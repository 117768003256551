import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { BackIcon, CheckmarkIcon } from '../../../components/icons'
import _ from 'lodash' 
import Loader from '../../../components/loader'
import TopNavigation from '../../TopNavigation/component'
import Cookies from 'js-cookie'

export default class ExchangeConfirmationComponent extends PureComponent {

  static propTypes = {
    // PropTypes go here
  }
  constructor(props){
    super(props)
    this.state = {
      orderDetail: {},
      product: {},
      userName: '',
      isLoader: true
    }
  }

  async componentDidMount(){
    const data ={
      orderId: Cookies.get('orderId'),
      customerId: Cookies.get('customerId')
    }
    const { value } = await this.props.getOrderDetail(data)
    if(_.get(value,'status',false)){
      this.setState({
        orderDetail: _.get(value, 'data', {}),
        product: _.get(value, 'lItem', {}),
        userName: _.get(value, 'userName', {}),
        isLoader: false
      })
    } else{
      this.props.history.push('/no-page-found')
    }
  }

  openDownloadLink(trackings) {
    if(_.get(trackings,'printSlipLink','')) {
      window.open(trackings.printSlipLink, '_newtab')
    }
  }

  render() {
    const { orderDetail, userName, product, isLoader } = this.state
    // console.log(orderDetail,'orderDetail')
    const image = _.filter(_.get(orderDetail, "images", []), filter => {
      return String(filter.id) === String(product.id);
    })
    const title = _.get(product, "name", "").split("/")
    const exchangeId = _.filter(_.get(orderDetail, "requestId", []), filter => {
      return filter.reason === "Exchange"
    })

    const returnLabel = _.filter(_.get(orderDetail, "trackings", []), filter => {
      return filter.trackingStatus === "initiatereturn"
    })
    const ExchangeId = exchangeId[0]
    const ReturnLabel = returnLabel[returnLabel.length - 1]
    let disableLabel = false
    if(ReturnLabel && _.get(ReturnLabel,'printSlipLink','') === ''){
      disableLabel = true
    }
    return (
      <>
      <div>
        {isLoader ? (
            <Loader />
          ) : (
          <div>
          <TopNavigation userName={userName} orderDetail={orderDetail} {...this.props}/>
        <div className="mini-container-wrapper">
          <div className="mini-container">
            <div className="head-wrap head-wrap-space">
            <div className="head-wrap-back">
                <button type="button" className="btn btn-sm btn-back" >
                  <Link to={`/detail/${_.get(orderDetail, 'order.order_number', '')}`}>
                    <BackIcon/>
                  </Link>
                </button>
              </div>
              <div className="head-wrap-title">
                Request
              </div>
            </div>
            <div className="mini-container-inner mb-4">
              <div className="overview-wrap">
                <h4>Your Exchange Request has been submitted successfully!</h4>
                <h6 className="product-generated-id">Exchange ID: {_.get(ExchangeId,'Id','')}</h6>
                <CheckmarkIcon className="overview-icon"/>
                <p>We have sent you an email with this info to <br/>{_.get(orderDetail,'order.email','')}</p>
              </div>
            </div>
            <div className="label-heading">
              <h5 className="label-heading-title">How to send us Your Ring</h5>
            </div>
            <div className="mini-container-inner mb-4">
              {_.get(ReturnLabel,'printSlipLink','') !== '' ?    
                <div className="label-content-information">
                  <h5>Your Label is Ready to Print</h5>
                  <p>This label was also sent to your email</p>
                  <button disabled={disableLabel} className="btn btn-dark w-100" onClick={this.openDownloadLink.bind(this, ReturnLabel)} >Download Shipping Label</button>
                </div>
              :
                <div className="label-content-information">
                  <h5>Your Tracking Number</h5>
                  <p>{_.get(ReturnLabel,'trackingNumber','')}</p>
                </div>
              }
              <ul className="label-options-list">
                <li>
                  1. Find some secure packaging to place the ring box in. Ideally you can use the same Vow package we shipped it to you in.
                </li>
                <li>
                  2. Remove any pre-existing labels from the packaging, print the above return label and attach it to the package.
                </li>
                <li>
                  3. Drop off the package at your nearest FedEx location within 7 days of receiving this email.
                </li>
                <li>
                  4. Your credit is issued as soon as we receive your ring and ensure it is in it’s original state. Store credit is available to use immediately when it’s issued.
                </li>
              </ul>
            </div>

            <div className="label-heading">
              <h5 className="label-heading-title">Ring you are Exchanging </h5>
            </div>
            <div className="horizontal-product">
              <div className="product-thumbnail">
                {_.get(image, "[0].image", "/default.webp") !== "" && (
                  <img
                    src={_.get(image, "[0].image", "/default.webp")}
                    alt={""}
                  />
                )}
              </div>
              <div>
                <h4 className="product-name">{_.get(product,'title','')}</h4>
                <p className="product-shape-color">{title[1]}</p>
              </div>
            </div>

          </div>
        </div>  
        </div>  
        )}
        </div>  
      </>
    )
  }
}
