import React from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import Cookies from 'js-cookie'

import Login from './views/Login/container'
import NotPageFound from './views/NotPageFound/container'
import ProductItem from './views/ProductItem/container'
import SelectAction from './views/SelectAction/container'
import Repair from './views/Repair/container'
import RepairOverview from './views/Repair/RepairOverview/container'
import RepairConfirmation from './views/Repair/RepairConfirmation/container'
import Exchange from './views/Exchange/container'
import ExchangeOverview from './views/Exchange/ExchangeOverview/container'
import ExchangeConfirmation from './views/Exchange/ExchangeConfirmation/container'
import Return from './views/Return/container'
import ReturnOverview from './views/Return/ReturnOverview/container'
import ReturnConfirmation from './views/Return/ReturnConfirmation/container'
import LostRing from './views/LostRing/container'
import HTOKLabel from './views/HTOKLabel/container'

import RcfjResize from './views/RcfjResize/container'
import ResizeOverview from './views/RcfjResize/ResizeOverview/container'
import ResizeConfirmation from './views/RcfjResize/ResizeConfirmation/container'

import RcfjRepair from './views/RcfjRepair/container'
import RcfjRepairOverview from './views/RcfjRepair/RepairOverview/container'
import RcfjRepairConfirmation from './views/RcfjRepair/RepairConfirmation/container'

import RcfjCustomSize from './views/RcfjCustomSize/container'
import CustomSizeOverview from './views/RcfjCustomSize/CustomSizeOverview/container'
import CustomSizeConfirmation from './views/RcfjCustomSize/CustomSizeConfirmation/container'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'antd/dist/antd.css'
import './App.scss'


const PrivateRoute = ({ component, user, ...rest }) => {
  const orderId = Cookies.get('orderId')
  const customerId = Cookies.get('customerId')
  let isUser = false
  if(orderId && customerId){
    isUser = true
  }
  const isAuthed = isUser ? true : false
  return (
    <Route {...rest} exact
      render = {props => (
        isAuthed ? (<div>
          {
            React.createElement(component, props)
          }
        </div>)
        :
        (
          <Redirect
            to={{
              pathname: '/no-page-found',
              state: { from: props.location }
            }}
          />
        )
      )}
    />
  )
}

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      isAuthed: false
    }
  }

  componentDidMount() {
    // const cookies = new Cookies()
    // this.setState({ isLoading: false })
    // if (cookies.get('token')) {
    //   // this.checkUser();
    // } else {
    //   this.setState({ isLoading: false })
    // }
  }

  render() {
    // let { isAuthed, isLoading } = this.state
    return (
      <>
        {/* <Loader isLoading={isLoading} /> */}
        <div className="application-container">
          <Switch>
            <Route exact path="/" render={() =>
              <Redirect to="/login" />
            } />
            <Route exact path="/login" component={Login} {...this.props} />
            <Route exact path="/no-page-found" component={NotPageFound} {...this.props}/>
            <Route exact path="/get_help" component={LostRing} {...this.props} />
            <PrivateRoute exact path="/detail/:id" component={ProductItem} {...this.props} />
            <PrivateRoute exact path="/select-action/:id" component={SelectAction} {...this.props} />
            
            <PrivateRoute exact path="/repair/:id" component={Repair} {...this.props} />
            <PrivateRoute exact path="/repair/overview/:id" component={RepairOverview} {...this.props} />
            <PrivateRoute exact path="/repair/request-confirmation/:id" component={RepairConfirmation} {...this.props} />
            
            <PrivateRoute exact path="/exchange/:id" component={Exchange} {...this.props} />
            <PrivateRoute exact path="/exchange/overview/:id" component={ExchangeOverview} {...this.props} />
            <PrivateRoute exact path="/exchange/request-confirmation/:id" component={ExchangeConfirmation} {...this.props} />
            
            <PrivateRoute exact path="/return/:id" component={Return} {...this.props} />
            <PrivateRoute exact path="/return/overview/:id" component={ReturnOverview} {...this.props} />
            <PrivateRoute exact path="/return/request-confirmation/:id" component={ReturnConfirmation} {...this.props} />
            
            <PrivateRoute exact path="/lost-ring/:id" component={LostRing} {...this.props} />
            <PrivateRoute exact path="/htok-label/:id" component={HTOKLabel} {...this.props} />

            <PrivateRoute exact path="/rcfj-resize/:id" component={RcfjResize} {...this.props} />
            <PrivateRoute exact path="/rcfj-resize/overview/:id" component={ResizeOverview} {...this.props} />
            <PrivateRoute exact path="/rcfj-resize/request-confirmation/:id" component={ResizeConfirmation} {...this.props} />

            <PrivateRoute exact path="/rcfj-repair/:id" component={RcfjRepair} {...this.props} />
            <PrivateRoute exact path="/rcfj-repair/overview/:id" component={RcfjRepairOverview} {...this.props} />
            <PrivateRoute exact path="/rcfj-repair/request-confirmation/:id" component={RcfjRepairConfirmation} {...this.props} />

            <PrivateRoute exact path="/confirm-size/:id" component={RcfjCustomSize} {...this.props} />
            <PrivateRoute exact path="/confirm-size/overview/:id" component={CustomSizeOverview} {...this.props} />
            <PrivateRoute exact path="/confirm-size/request-confirmation/:id" component={CustomSizeConfirmation} {...this.props} />

          </Switch>
        </div>
      </>
    )
  }
}

export default withRouter(withCookies(App))
