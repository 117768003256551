import React, { PureComponent } from 'react'
import { Tooltip, message } from 'antd'
import _ from 'lodash'
// import Slider from 'react-slick'
import InputMask from "react-input-mask"
import ReactModal from 'react-modal'
import Cookies from 'js-cookie'
import Loader from '../../../components/loader'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import TopNavigation from '../../TopNavigation/component'
import { BackIcon, CloseIcon, QuestionIcon, LockIcon } from '../../../components/icons'
import { getOrderEmail } from '../../../constants/common'
import creditCardType from 'credit-card-type'
import Config from '../../../config/index'
import load from 'load-stripe'
import VisaIcon from "../../../images/Visa.svg"
import AmericanExpressIcon from "../../../images/AmericanExpress.svg"
import DinersClubIcon from "../../../images/DinersClub.svg"
import DiscoverIcon from "../../../images/Discover.svg"
import MaestroIcon from "../../../images/Maestro.svg"
import MasterCardIcon from "../../../images/MasterCard.svg"
import UnionPayIcon from "../../../images/UnionPay.svg"
import { getCode, getData } from 'country-list'
import { CountryDropdown, RegionDropdown } from "react-country-region-selector"
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

export default class CustomSizeOverviewComponent extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      exampleModal: false,
      orderDetail: '',
      userName: '',
      isLoader: true,
      shippingAddress: {},
      valAddress: {},
      quantity: 0,
      productId: 0,
      chatOpen: 0,
      RCFJOrder: '',
      count: true,
      newRingSize: '',
      cardholderName:'',
      cardExpireDate:'',
      cardNumber:'',
      cvvCode:'',
      cvvLength: 3,
      customerStripeId:'',
      cardBox:false,
      paymentMethods:{},
      addNewCard: false,
      removeCard: false,
      resizeFee: '',
      variantOptions: [],
      newPrice: 0, 
      amountOwned: 0, 
      refundAmount: 0,
      variantName: '',
      inventoryItemId: '',
      variantId:'',
      giftWrapProduct: [],
      errorMessage: [],
      phoneNumberCountry: 'US',
      selectedQuantity: 0,
      totalQuantity: 0
    }
  }
  
  closeModal() {
    const { valAddress } = this.state
    this.setState({ editAddressModal: false, errSup: {}, shippingAddress: valAddress, errorMessage: [] })
  }
  
  goBack(){
    const { orderDetail } = this.state
    const data = {
      newRingSize: this.state.newRingSize,
      RCFJOrder: this.state.RCFJOrder,
      variantOptions: this.state.variantOptions,
      variantName: this.state.variantName,
      inventoryItemId: this.state.inventoryItemId,
      variantId: this.state.variantId,
      selectedQuantity: this.state.selectedQuantity,
    }
    this.props.history.push({
      pathname : `/confirm-size/${_.get(orderDetail, 'order.order_number', '')}`,
      state : { detail : data }
    })
  }
  
  async componentDidMount(){
    const data ={
      orderId: Cookies.get('orderId'),
      customerId: Cookies.get('customerId')
    }
    let productId = Cookies.get('productId')
    let RCFJOrder = []
    const { value } = await this.props.getOrderDetail(data)
    if (_.get(value,'status',false)){
      let orderDetail = _.get(value, 'data', {})
      // confirm size request is under process
      const orderInProgress = Cookies.get('confirmSize')
      if ([true, 'true'].includes(orderInProgress)) {
        // remove confirm size form request cookies
        Cookies.remove('confirmSize')
        // redirect to order detail page
        this.props.history.push(`/detail/${_.get(orderDetail, 'order.order_number', '')}`)
      }
      if(!_.includes([null,undefined,'null',''], productId)){
        RCFJOrder = _.find(_.get(orderDetail, "order.line_items", []), filter => {
          return String(filter.id) === String(productId);
        })
      }
      let giftWrapProduct = []; let rcfjRemoveId = [];
      _.map(_.get(orderDetail, "order.line_items", []), (item,i) =>{
        _.map(_.get(item,'properties',[]), properties => {
          if(!_.includes(rcfjRemoveId, item.id) && _.includes(Config.AssociatedItemsKey,_.get(properties,'name',''))){
            giftWrapProduct.push(item)
            rcfjRemoveId.push(item.id)
          }
        })
      })
      this.setState({
        orderDetail: _.get(value, 'data', {}),
        userName: _.get(value, 'userName', {}),
        shippingAddress: _.get(value, 'data.order.shipping_address', {}) === null ? {} : _.get(value, 'data.order.shipping_address', {}),
        isLoader: false,
        paymentMethods: _.get(value, 'data.paymentMethods', {}),
        productId,
        RCFJOrder,
        giftWrapProduct,
        totalQuantity: _.get(RCFJOrder, 'originalQuantity', _.get(RCFJOrder, 'quantity', 0))
      })
    } else{
      this.props.history.push('/no-page-found')
    }
  }
  
  componentDidUpdate(prevProps, prevState){
    if(prevProps.location.state && this.state.count){
      let newRingSize = _.get(prevProps,'location.state.detail.newRingSize','')
      let variantOptions = _.get(prevProps,'location.state.detail.variantOptions',[])
      let RCFJOrder = _.get(prevProps,'location.state.detail.RCFJOrder',[])
      let variantName = _.get(prevProps,'location.state.detail.variantName','')
      let selectedQuantity = _.get(prevProps,'location.state.detail.selectedQuantity','')
      this.setState({
        variantOptions : _.get(prevProps,'location.state.detail.variantOptions',[]),
        newRingSize : _.get(prevProps,'location.state.detail.newRingSize',''),
        RCFJOrder : _.get(prevProps,'location.state.detail.RCFJOrder',''),
        variantName : _.get(prevProps,'location.state.detail.variantName',''),
        inventoryItemId : _.get(prevProps,'location.state.detail.inventoryItemId',''),
        variantId : _.get(prevProps,'location.state.detail.variantId',''),
        selectedQuantity : _.get(prevProps,'location.state.detail.selectedQuantity', 0),
        defaultSize : _.get(prevProps,'location.state.detail.defaultSize', {}),
        count: false
      })
      this.getTotalAmount(newRingSize, variantOptions, RCFJOrder, variantName, selectedQuantity)
    }
  }
  
  async submit(){
    let { orderDetail, cardholderName, cardNumber, cardExpireDate, cvvCode, customerStripeId, paymentMethods, addNewCard, cardBox, RCFJOrder, amountOwned, refundAmount, variantOptions, inventoryItemId, selectedQuantity, defaultSize, afterSaleDiscount, applyDefultDiscount } = this.state
    let err = {}
    let updatedRCFJOrder = _.cloneDeep(RCFJOrder)
    let objDetail = {
      orderId: _.get(orderDetail,'order.order_number',''),
      tags: ['RCFJ'],
      isOnlyRCFJ: true,
      action:'RCLoop',
      reason:'',
      confirmSize: true,
      newRingSize: this.state.newRingSize,
      amountOwned: amountOwned,
      selectedItems: [RCFJOrder],
      inventoryItemId,
      selectedRingSizeQty: _.get(RCFJOrder, 'quantity', 0),
      defaultSize,
      afterSaleDiscount,
      applyDefultDiscount
    }

    if(selectedQuantity !== _.get(RCFJOrder, 'quantity', 0)){
      objDetail.quantityChanged = true
      objDetail.selectedRingSizeQty = parseInt(selectedQuantity)
    }

    let newItem = ''
    if(RCFJOrder){
      let variantTitle = ''; let newVariantId = ''
      _.map(variantOptions, variant => {
        if(_.get(variant,'title','') === this.state.newRingSize ){
          variantTitle = _.get(variant,'title','')
          let name = _.get(RCFJOrder,'title','') + ' - ' + _.get(variant,'title','')
          newVariantId = _.get(variant,'id','')
          _.set(updatedRCFJOrder,'variant_id',_.get(variant,'id',''))
          _.set(updatedRCFJOrder,'variant_title',variantTitle)
          _.set(updatedRCFJOrder,'name',name)
          _.set(updatedRCFJOrder,'price',_.get(variant,'price',''))
          _.set(updatedRCFJOrder,'sku',_.get(variant,'sku',''))
        } 
      })
      objDetail.updatedRCFJItems = [updatedRCFJOrder]
      objDetail.newVariantId = newVariantId
      newItem = updatedRCFJOrder
      Cookies.remove('variantTitle')
      Cookies.set('variantTitle',variantTitle)
    }
    let associatedProduct = this.addAssociatedProduct([RCFJOrder])
    let associatedProperties = []
    let associatedLineItems = []
    if(associatedProduct.length && newItem){
      _.map(associatedProduct, pro =>{
        if(_.get(pro,'id','') !== _.get(RCFJOrder,'id','')){
          associatedLineItems.push(pro)
          associatedProperties.push({
            id: _.get(pro,'id',''),
            newItemId: _.get(newItem,'id',''),
            properties:[
              {name: 'sku', value: _.get(newItem,'sku','')},
              {name: 'associated_variant_id', value: `${_.get(newItem,'variant_id','')}`.replace('.0', '')}
            ]
          })
        }else if(_.get(pro,'id','') === _.get(RCFJOrder,'id','')){
          associatedProperties.push({
            removeItemProperty: true,
            id: _.get(pro,'id',''),
            newItemId: '',
            properties:_.get(pro,'properties',[])
          })
        }
      })
    }

    objDetail.associatedProperties = associatedProperties
    objDetail.associatedLineItems = associatedLineItems

    let fee = amountOwned
    if(amountOwned < 0){
      fee = refundAmount
    }
    
    this.setState({ isLoader: true }, () => Cookies.set('confirmSize', true))
    if(fee > 0 && amountOwned > 0){
      let paymentMethodsFields = {}
      let paymentData = {
        orderId: _.get(orderDetail,'order.order_number',''),
        id: _.get(orderDetail, 'order.customer.id', ''),
        email: getOrderEmail(orderDetail),
        isPaymentDone: _.get(orderDetail, 'order.customer.isPaymentDone', false)
      }
      if(addNewCard && cardBox){
        const err = this.validateCardDetails()
        if(Object.keys(err).length > 0) {
          this.setState({ errSup:err, isLoader: false })
        }else{
          cardExpireDate = cardExpireDate.split('/')
          const cardDetails = {
            "number": cardNumber,
            "exp_month": cardExpireDate[0],
            "exp_year":cardExpireDate[1],
            "cvc": cvvCode,
            "name": cardholderName
          }

          await load(`${process.env.REACT_APP_STRIPE_KEY}`).then(stripe => {
            stripe.card.createToken(cardDetails)
              .then(async (token, err) => {
                if(token){
                  const paymentMethodsFields = {
                    token: token.id,
                    cardId: token.card.id,
                    card: token.card,
                    object: token.object,
                  }
                  paymentData.paymentMethodsFields = paymentMethodsFields
                  const customerDetails = await this.props.addPaymentMethod(paymentData)
                  if(customerDetails && customerDetails.value && _.get(customerDetails.value, 'id', false)){
                    customerStripeId = _.get(customerDetails.value, 'id', '')
                    this.setState({ customerStripeId })
                    objDetail.customerStripeId = customerStripeId
                    objDetail.amount = fee
                    const { value } = await this.props.confirmSize(objDetail)
                    if (_.get(value, 'status', false)) {
                      message.error(_.get(value, 'message', ''), () => {
                        this.setState({ isLoader: false }, () => Cookies.remove('confirmSize'))
                      })
                      this.props.history.push(`/confirm-size/request-confirmation/${_.get(orderDetail, 'order.order_number', '')}`)
                    } else{
                      message.error(_.get(value, 'message', ''), () => {
                        this.setState({ isLoader: false }, () => Cookies.remove('confirmSize'))
                      })
                    }
                  }else{
                    this.setState({ isLoader: false, errSup: err }, () => Cookies.remove('confirmSize'))
                  }
                }
              }).catch(err => {
              message.error(_.get(err, 'message', ''), () => {
                this.setState({ errSup: err, isLoader: false }, () => Cookies.remove('confirmSize'))
              })
            })
          })
        }
      }else{
        paymentData.paymentMethodsFields = paymentMethodsFields
        paymentData.paymentMethodId = _.get(paymentMethods, '_id', null)
        const customerDetails = await this.props.addPaymentMethod(paymentData)
        if(customerDetails && customerDetails.value && _.get(customerDetails.value, 'id', false)){
          customerStripeId = _.get(customerDetails.value, 'id', '')
          this.setState({ customerStripeId })
          objDetail.customerStripeId = customerStripeId
          objDetail.amount = fee
          const { value } = await this.props.confirmSize(objDetail)
          if (_.get(value, 'status', false)) {
            message.error(_.get(value, 'message', ''), () => {
              this.setState({ isLoader: false }, () => Cookies.remove('confirmSize'))
            })
            this.props.history.push(`/confirm-size/request-confirmation/${_.get(orderDetail, 'order.order_number', '')}`)
          } else{
            message.error(_.get(value, 'message', ''), () => {
              this.setState({ isLoader: false }, () => Cookies.remove('confirmSize'))
            })
          }
        }else{
          this.setState({ errSup: err, isLoader: false }, () => Cookies.remove('confirmSize'))
        }
      }
    } else{
      if(refundAmount !== 0){
        objDetail.refundAmount = refundAmount
      }
      const { value } = await this.props.confirmSize(objDetail)
      if (_.get(value, 'status', false)) {
        message.error(_.get(value, 'message', ''), () => {
          this.setState({ isLoader: false }, () => Cookies.remove('confirmSize'))
        })
        this.props.history.push(`/confirm-size/request-confirmation/${_.get(orderDetail, 'order.order_number', '')}`)
      } else{
        message.error(_.get(value, 'message', ''), () => {
          this.setState({ isLoader: false }, () => Cookies.remove('confirmSize'))
        })
      }
    }
  }

  addAssociatedProduct(returnItemsSelectItems){
    const { giftWrapProduct } = this.state
    let refundItemList = returnItemsSelectItems
    _.map(returnItemsSelectItems, item =>{
      _.map(giftWrapProduct, pro =>{
        _.map(_.get(pro,'properties',[]), properties => {
          if(_.includes(Config.AssociatedItemsKey, _.get(properties,'name','')) && String(_.get(properties,'value','')) === String(_.get(item,'variant_id',''))){
            pro.quantities = _.get(item,'quantities',[])
            refundItemList.push(pro)
          }
        })
      })
    })
    return refundItemList
  }

  getTotalAmount(newRingSize, variantOptions, RCFJOrder, variantName, selectedQuantity){
    const { paymentMethods, orderDetail } = this.state
    let cardBox = false; let addNewCard = false
    // let cardBox = true
    let productPrice = _.get(RCFJOrder,'price',0) * parseInt(selectedQuantity)
    let newPrice = 0; let amountOwned = 0; let refundAmount = 0
    _.map(variantOptions, variant =>{
      if(_.get(variant,'title','') === newRingSize){
        newPrice = _.get(variant,'price','') * parseInt(selectedQuantity)
      }
    })
    amountOwned = newPrice - productPrice
    if(amountOwned < 0){
      // cardBox = false
      refundAmount = Math.abs(amountOwned)
    } else if(amountOwned === 0){
      // cardBox = false
    }
    cardBox = amountOwned > 0 ? true : false
    cardBox = _.get(paymentMethods, '_id', '') !== '' ? false : cardBox
    addNewCard = cardBox ? true : false
    // check item price is increase after sale
    if (`${_.get(orderDetail, 'orderTags', '')}`.indexOf('compare-at') !== -1) {
      cardBox = false
      // remove extra ammount from order line item when it have owned amount
      // newPrice = newPrice - amountOwned
      const applyDefultDiscount = amountOwned
      amountOwned = 0
      this.setState({ afterSaleDiscount: true, applyDefultDiscount })
    }
    this.setState({ newPrice, amountOwned, refundAmount, cardBox, addNewCard }) 
  }

  
  editShippingAddress(key) {
    if (key === 'editAddressModal') {
      const valAddress = _.cloneDeep(this.state.shippingAddress)
      this.setState({ [key]: true, valAddress })
      this.forceUpdate()
    }
  }
  handleChangeAddress(e) {
    const { shippingAddress } = this.state
    shippingAddress[e.target.name] = e.target.value
    this.setState({ shippingAddress })
    this.forceUpdate()
  }
  
  
  handlePaymentDetailChange(event){
    this.setState({ [event.target.name]:event.target.value })
    if (event.target.name === 'cardNumber') {
      this.handleCreditCardChange(event.target.value)
    }
    this.forceUpdate()
  }

  handleCreditCardChange = (cardNumber) => {
    var cardType = creditCardType(cardNumber)
    this.setState({
      cvvLength: _.get(cardType[0], 'code.size', 3),
    })
    this.forceUpdate()
  }
  
  
  validateCardDetails() {
    const err = {}
    const { cardholderName, cardNumber, cardExpireDate, cvvCode } = this.state
    if (cardNumber === '') {
      err.cardNumber = 'Card number is required'
    }
    if (cardholderName === '') {
      err.cardholderName = 'Card name is required'
    }
    if (cvvCode === '') {
      err.cvvCode = 'Cvv is required'
    }
    if (cardExpireDate === '') {
      err.cardExpireDate = 'Expiration date is required'
    }
    return err
  }

  handleChangeCountry = (name, value) => {
    const { shippingAddress } = this.state
    shippingAddress[name] = value
    if(name === 'country'){
      let countryshortForm = getCode(value)
      if(!countryshortForm){
        const countiesCodeName = getData()
        _.map(countiesCodeName, list => {
          if(_.get(list, 'name', '') && _.get(list, 'name', '').indexOf(value) !== -1){
            countryshortForm = _.get(list, 'code', '')
          }
        }) 
      }
      this.setState({ phoneNumberCountry: countryshortForm, shippingAddress })
    }
    this.forceUpdate()
  }

  handleChangePhone = (name, value) => {
    const { shippingAddress } = this.state
    shippingAddress[name] = value
    this.setState({ shippingAddress })
    this.forceUpdate()
  }
  
  handleEditAddress = async() => {
    const { shippingAddress, orderDetail } = this.state
    const err = {}
    if (!shippingAddress.first_name || shippingAddress.first_name === '' ) {
      err.first_name = 'First Name is required.'
    }
    if (!shippingAddress.last_name || shippingAddress.last_name === '' ) {
      err.last_name = 'Last Name is required.'
    }
    if (!shippingAddress.address1 || shippingAddress.address1 === '' ) {
      err.address = 'Address is required.'
    }
    if (!shippingAddress.city || shippingAddress.city === '' ) {
      err.city = 'City is required.'
    }
    if (!shippingAddress.province || shippingAddress.province === '' ) {
      err.state = 'State is required.'
    }
    if (!shippingAddress.province || shippingAddress.province === '' ) {
      err.state = 'State is required.'
    }
    if (!shippingAddress.country || shippingAddress.country === '' ) {
      err.state = 'Country is required.'
    }
    if(Object.keys(err).length === 0) {
      const obj = {
        id: _.get(orderDetail, '_id', ''),
        orderId: _.get(orderDetail, 'order.id', ''),
        shipping_address: shippingAddress,
        addressType: 'shippingAddress'
      }
      this.setState({ isLoader: true })
      const { value } = await this.props.updateShippingAddress(obj)
      if(_.get(value, 'status', false)) {
        this.componentDidMount()
        this.setState({ errSup: {}, editAddressModal: false, isLoader: false, shippingAddress: _.get(value, 'shippingAddress', '') })
      } else {
        this.setState({ isLoader: false, errorMessage: _.get(value, 'message.shipping_address', []) })
      }
    } else {
      this.setState({ errSup: err, isLoader: false })
    }
    // this.componentDidMount()
  }

  addNewCardDetails= () =>{
    this.setState({ cardBox: true, addNewCard: true, removeCard: true })
    this.forceUpdate()
  }

  removeCard = () =>{
    this.setState({ cardBox: false, addNewCard: false, removeCard: false})
    this.forceUpdate()
  }
  
  openChat(){
    const { chatOpen } = this.state
    if(chatOpen === 0){
      window.GorgiasChat.open()
      this.setState({ chatOpen: 1 })
    } else{
      window.GorgiasChat.close()
      this.setState({ chatOpen: 0 })
    }
  }

  render() {
    const { orderDetail, userName, isLoader, errSup, shippingAddress, productId, RCFJOrder, variantOptions, cardBox, newPrice, amountOwned, refundAmount, paymentMethods, selectedQuantity, totalQuantity, afterSaleDiscount, applyDefultDiscount } = this.state
    const image = _.filter(_.get(orderDetail, "images", []), filter => {
      return String(filter.id) === String(productId);
    })
    let actualItemPrice = 0
    let newItemPrice = newPrice
    let actualNewItemPrice = ''
    let prePrice = parseFloat(_.get(RCFJOrder,'pre_tax_price',0)) / parseInt(totalQuantity)
    if(!_.includes(['0.00',null,undefined], _.get(RCFJOrder, 'pre_tax_price', '')) && _.get(RCFJOrder,'discount_allocations',[]).length && !afterSaleDiscount){
      let discountPrice = 0
      _.map(_.get(RCFJOrder,'discount_allocations',[]), dis =>{
        discountPrice += parseFloat(_.get(dis,'amount',0))
      })
      actualItemPrice = parseFloat(_.get(RCFJOrder, 'price', 0)) * parseInt(selectedQuantity)
      actualNewItemPrice = newItemPrice
      newItemPrice = parseFloat(newItemPrice) - parseFloat(discountPrice)
      prePrice = parseFloat(_.get(RCFJOrder, 'pre_tax_price', 0)) / parseInt(totalQuantity)
    }
    let itemPrice = !_.includes(['0.00',null,undefined],_.get(RCFJOrder,'pre_tax_price','')) ? prePrice : _.get(RCFJOrder,'price','')
    const currentSizePrice = parseFloat(itemPrice) * parseInt(selectedQuantity)
    if (afterSaleDiscount && parseFloat(currentSizePrice) < parseFloat(newItemPrice)) {
      // actual item price is price when there is no discount apply or before sale price
      actualNewItemPrice =  newItemPrice
      // new item price should be same as it was before when order place in sale time
      newItemPrice = actualNewItemPrice - applyDefultDiscount
    }
    return (
      <>
        {isLoader ? (
          <Loader />
        ) : (
          <>
          <TopNavigation userName={userName} orderDetail={orderDetail} {...this.props}/>
          <div className="mini-container-wrapper confirm-size-wrapper">
            <div className="mini-container">
              <div className="head-wrap head-wrap-space">
                <div className="head-wrap-back">
                  <button
                    type="button"
                    className="btn btn-sm btn-back"
                    onClick={this.goBack.bind(this)}
                  >
                    <BackIcon />
                  </button>
                </div>
                <div className="head-wrap-title">Overview</div>
              </div>
              <div className="horizontal-product">
                <div className="product-thumbnail">
                  {_.get(image, "[0].image", "/default.webp") !== "" && (
                    <img src={_.get(image, "[0].image", "/default.webp")} alt={""} />
                  )}
                </div>
                <div>
                  <h4 className="product-name">{_.get(RCFJOrder, "title", "")}</h4>
                  <p className="product-shape-color">
                    {_.get(RCFJOrder, "variant_title", "")}
                  </p>
                </div>
              </div>
              <div className="mini-container-inner">
                <div className="form-group">
                  <label className="label-info-text">Tell us what size you need </label>
                  {_.map(variantOptions, (variant, i) => {
                    if (variant.title === this.state.newRingSize) {
                      return (
                        <div key={i}>
                          <p>
                            New Size: {_.get(variant, "title", "")}{" "}
                            <span className="amount-text">
                              ${_.get(variant, "price", "")}
                            </span>
                          </p>
                        </div>
                      );
                    }
                  })}
                </div>

                <div className="form-group">
                  <label className="label-info-text">Return Address</label>
                  <div className="address-info">
                    {_.get(shippingAddress, "first_name", "")}{" "}
                    {_.get(shippingAddress, "last_name", "")}
                    <br />
                    {_.get(shippingAddress, "address1", "")}{" "}
                    {_.get(shippingAddress, "address2", "")},{" "}
                    {_.get(shippingAddress, "city", "")},{" "}
                    {_.get(shippingAddress, "province_code", "")}{" "}
                    {_.get(shippingAddress, "zip", "")}
                    {/*<span onClick={this.editShippingAddress.bind(this, 'editAddressModal')} className="btn-edit-address">Edit Address</span>*/}
                  </div>
                </div>

                {!cardBox && this.state.amountOwned > 0 && !this.state.addNewCard ? (
                  <div className="">
                    <ul className="payment-card-list">
                      <li>
                        <div className="payment-card-info">
                          <span className="card-icon">
                            {_.get(paymentMethods, "card.brand", "") === "Visa" ? (
                              <img src={VisaIcon} alt="" height="32" />
                            ) : _.get(paymentMethods, "card.brand", "") ===
                              "MasterCard" ? (
                              <img src={MasterCardIcon} alt="" height="32" />
                            ) : _.get(paymentMethods, "card.brand", "") ===
                              "American Express" ? (
                              <img src={AmericanExpressIcon} alt="" height="32" />
                            ) : _.get(paymentMethods, "card.brand", "") === "Discover" ? (
                              <img src={DiscoverIcon} alt="" height="32" />
                            ) : _.get(paymentMethods, "card.brand", "") ===
                              "Diners Club" ? (
                              <img src={DinersClubIcon} alt="" height="32" />
                            ) : _.get(paymentMethods, "card.brand", "") === "UnionPay" ? (
                              <img src={UnionPayIcon} alt="" height="32" />
                            ) : _.get(paymentMethods, "card.brand", "") === "Maestro" ? (
                              <img src={MaestroIcon} alt="" height="32" />
                            ) : (
                              ""
                            )}
                          </span>
                          <span class="card-number">
                            {_.get(paymentMethods, "card.brand", "")}****
                            {_.get(paymentMethods, "card.last4", "")}
                          </span>
                        </div>
                        <div>
                          Expiration Date {_.get(paymentMethods, "card.exp_month", "")}/
                          {`${_.get(paymentMethods, "card.exp_year", "")}`
                            .toString()
                            .substr(-2)}
                        </div>
                        {/* <div>Expire date 20/7<button className="btn btn-link w-120">Expire date 20/7</button></div> */}
                      </li>
                    </ul>
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn btn-dark w-100"
                        onClick={this.addNewCardDetails.bind(this)}
                      >
                        Add New Card
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {cardBox ? (
                  <div>
                    <hr className="horizontal-line" />
                    <div className="form-group">
                      <label className="form-label">Cardholder name</label>
                      <input
                        type="text"
                        name="cardholderName"
                        className="form-control"
                        onChange={this.handlePaymentDetailChange.bind(this)}
                        // onBlur={handleBlur}
                        value={this.state.cardholderName}
                        required
                      />
                      <div className="invalid-feedback">
                        {errSup && errSup.cardholderName ? errSup.cardholderName : ""}
                      </div>
                    </div>
                    <div className="form-group material-textfield">
                      <label className="form-label">Card Number</label>
                      <InputMask
                        maskChar={null}
                        mask="9999 9999 9999 9999"
                        name="cardNumber"
                        className="form-control"
                        onChange={this.handlePaymentDetailChange.bind(this)}
                        // onBlur={handleBlur}
                        value={this.state.cardNumber}
                        required
                      />
                      <button type="button" className="btn info-tooltip">
                        <LockIcon />
                      </button>
                      <div className="invalid-feedback">
                        {errSup && errSup.cardNumber ? errSup.cardNumber : ""}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">MM/YY</label>
                          <InputMask
                            mask="99/99"
                            maskChar={null}
                            name="cardExpireDate"
                            className="form-control"
                            onChange={this.handlePaymentDetailChange.bind(this)}
                            // onBlur={handleBlur}
                            value={this.state.cardExpireDate}
                            required
                          />
                          <div className="invalid-feedback">
                            {errSup && errSup.cardExpireDate ? errSup.cardExpireDate : ""}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <label className="form-label">CVV</label>
                          <InputMask
                            mask={this.state.cvvLength === 3 ? "999" : "9999"}
                            maskChar={null}
                            name="cvvCode"
                            className="form-control"
                            onChange={this.handlePaymentDetailChange.bind(this)}
                            // onBlur={handleBlur}
                            value={this.state.cvvCode}
                            required
                          />
                          <Tooltip
                            placement="top"
                            title="3-digit security code usually found on the back of
                              your card. American Express cards have a 4-digit
                              code located on the front."
                          >
                            <button type="button" className="btn info-tooltip">
                              <QuestionIcon />
                            </button>
                          </Tooltip>
                          <div className="invalid-feedback">
                            {errSup && errSup.cvvCode ? errSup.cvvCode : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.removeCard ? (
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-dark w-100"
                          onClick={this.removeCard.bind(this)}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}

                <div className="form-group">
                  <label className="label-info-text">Summary</label>
                  <ul className="summary-info">
                    {selectedQuantity > 1 ? (
                      <li>
                        <span>Quantity</span> <span>{selectedQuantity}</span>
                      </li>
                    ) : (
                      ""
                    )}
                    <li>
                      <span>Current Price</span>
                      <span className="d-flex">
                        {actualItemPrice ? (
                          <p className="line-through mr-10">
                            $
                            {parseFloat(actualItemPrice).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </p>
                        ) : (
                          ""
                        )}
                        $
                        {parseFloat(
                          parseFloat(itemPrice) * parseInt(selectedQuantity)
                        ).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                      </span>
                    </li>
                    <li>
                      <span>New Size Price</span>
                      <span className="d-flex">
                        {actualNewItemPrice ? (
                          <p className="line-through mr-10">
                            $
                            {parseFloat(actualNewItemPrice).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </p>
                        ) : (
                          ""
                        )}
                        $
                        {parseFloat(newItemPrice).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </span>
                    </li>
                    {amountOwned <= 0 ? (
                      <li>
                        <span>{refundAmount === 0 ? "Amount Owed" : "Credit"}</span>{" "}
                        <span>
                          $
                          {parseFloat(refundAmount).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </span>
                      </li>
                    ) : (
                      <li>
                        <span>Amount Owed</span>{" "}
                        <span>
                          $
                          {parseFloat(amountOwned).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-dark w-100"
                    onClick={this.submit.bind(this)}
                  >
                    Submit
                  </button>
                </div>
                <div className="chat-with-us">
                  Have questions?{" "}
                  <span onClick={this.openChat.bind(this)}>Chat with us</span>
                </div>
              </div>
            </div>
          </div>
          <ReactModal
            isOpen={this.state.editAddressModal}
            onRequestClose={this.closeModal.bind(this)}
            ariaHideApp={false}
            contentLabel="Edit Shippping Address"
            portalClassName="react-modal"
            overlayClassName="modal"
            className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
          >
            <div className="modal-content">
              <div className="react-modal-header">
                <h5 className="react-modal-title">Edit Shippping Address</h5>
                <button type="button" className="btn react-modal-close" onClick={this.closeModal.bind(this)}><CloseIcon/></button>
              </div>
              <div className="react-modal-body">
                {this.state.errorMessage && this.state.errorMessage.length ? (
                  <div className="notice-attention">
                    <div>
                      <h6 className="notice-attention-heading">
                        There was {this.state.errorMessage.length} error on this form:
                      </h6>
                      {
                        _.map(this.state.errorMessage, message  => {
                          return(
                            <p className="notice-attention-subheading">
                              {message}
                            </p>
                          )
                        })
                      }
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">First Name <span className="text-danger">*</span></label>
                      <input
                        name="first_name"
                        value={_.get(shippingAddress,'first_name','')}
                        onChange={this.handleChangeAddress.bind(this)}
                        type="text"
                        className="form-control"
                        required
                      />
                      <div className="invalid-feedback">{errSup && errSup.first_name ? errSup.first_name : ""}</div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Last Name <span className="text-danger">*</span></label>
                      <input
                        name="last_name"
                        value={_.get(shippingAddress,'last_name','')}
                        onChange={this.handleChangeAddress.bind(this)}
                        type="text"
                        className="form-control"
                        required
                      />
                      <div className="invalid-feedback">{errSup && errSup.last_name ? errSup.last_name : ""}</div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">company </label>
                  <input
                    name="company"
                    value={_.get(shippingAddress, "company", "")}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Address <span className="text-danger">*</span></label>
                  <input
                    name="address1"
                    value={_.get(shippingAddress, "address1", "")}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                  <div className="invalid-feedback">{errSup && errSup.address ? errSup.address : ""}</div>
                </div>
                <div className="form-group">
                  <label className="form-label">Apartment, suite, etc. (optional) </label>
                  <input
                    name="address2"
                    value={_.get(shippingAddress, "address2", "")}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">City <span className="text-danger">*</span></label>
                  <input
                    name="city"
                    value={_.get(shippingAddress,'city','')}
                    onChange={this.handleChangeAddress.bind(this)}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Country/Region <span className="text-danger">*</span></label>
                      <CountryDropdown
                        name="country"
                        value={_.get(shippingAddress, "country", "")}
                        onChange={this.handleChangeCountry.bind(this, 'country')}
                        classes="form-control material-textfield-input"
                      />
                      <div className="invalid-feedback">{errSup && errSup.country ? errSup.country : ""}</div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">State/Provision <span className="text-danger">*</span></label>
                      <RegionDropdown
                        name="province"
                        country={_.get(shippingAddress, "country", "")}
                        value={_.get(shippingAddress, "province", "")}
                        onChange={this.handleChangeCountry.bind(this, 'province')}
                        classes="form-control material-textfield-input"
                      />
                      <div className="invalid-feedback">{errSup && errSup.state ? errSup.state : ""}</div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group material-textfield">
                      <label className="form-label">Zip <span className="text-danger">*</span></label>
                      <input
                        name="zip"
                        value={_.get(shippingAddress,'zip','')}
                        onChange={this.handleChangeAddress.bind(this)}
                        type="number"
                        className="form-control material-textfield-input"
                        required
                      />
                      <div className="invalid-feedback">{errSup && errSup.zipcode ? errSup.zipcode : ""}</div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                <div className="country-input-field">
                  <PhoneInput
                    name="phone"
                    defaultCountry={this.state.phoneNumberCountry}
                    international={true}
                    value={_.get(shippingAddress, "phone", "")}
                    onChange={this.handleChangePhone.bind(this, 'phone')}
                    classes="form-control"
                  />
                </div>
                </div>
                <button onClick={this.handleEditAddress.bind(this)} className="btn btn-dark w-100">
                  Save
                </button>
              </div>
            </div>
          </ReactModal>
      </>
        )}
      </>
    )
  }
}
