import React from 'react'

const CloseIcon = ({className}) => {
  return (
    <svg width={25} height={26} viewBox="0 0 25 26" fill="none" className={className}>
      <path
        d="M10.819 13.374L.33 2.896a1.127 1.127 0 010-1.595 1.13 1.13 0 011.596 0l10.488 10.478L22.903 1.301A1.127 1.127 0 1124.5 2.896L14.011 13.374 24.5 23.852c.441.44.441 1.154 0 1.594a1.13 1.13 0 01-1.596 0L12.415 14.968 1.927 25.446A1.13 1.13 0 01.33 23.852l10.488-10.478z"
        fill="#000"
      />
    </svg>
  )
}
const BackIcon = ({className}) => {
  return (
    <svg width={19} height={13} viewBox="0 0 19 13" fill="none" className={className}>
      <path
        d="M.254 6.988l5.004 5.383c.34.308.99.435 1.369.08.373-.352.361-1.034-.01-1.386l-3.519-3.78h14.964a.944.944 0 00.938-.95c0-.525-.42-.95-.938-.95H3.098l3.519-3.78c.32-.326.38-1.031.01-1.386S5.583-.022 5.258.298L.254 5.68A.924.924 0 000 6.334c.009.227.101.49.254.654z"
        fill="#000"
      />
    </svg>
  )
}
const CaretRightIcon = ({className}) => {
  return (
    <svg width={10} height={17} viewBox="0 0 10 17" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.39997 8.3999C9.39997 8.79025 9.26239 9.1812 8.98694 9.47877L3.00623 15.9529C2.45589 16.5489 1.56328 16.5489 1.01294 15.9529C0.46232 15.3574 0.46232 14.3906 1.01294 13.7951L5.99672 8.3999L1.01294 3.00466C0.46232 2.40923 0.46232 1.44237 1.01294 0.846936C1.56328 0.250891 2.45589 0.250891 3.00623 0.846936L8.98694 7.32104C9.26239 7.6186 9.39997 8.00956 9.39997 8.3999Z"
        fill="#B4B4B4"
      />
    </svg>
  )
}
const CheckmarkIcon = ({className}) => {
  return (
    <svg width={45} height={45} viewBox="0 0 45 45" fill="none" className={className}>
      <path
        d="M22.5 0C10.0798 0 0 10.0798 0 22.5C0 33.8212 8.36992 43.2011 19.2624 44.7711C19.3311 44.7807 19.401 44.7768 19.4682 44.7594C19.5354 44.742 19.5985 44.7116 19.6539 44.6698C19.7093 44.6281 19.756 44.5758 19.7912 44.516C19.8264 44.4563 19.8495 44.3901 19.8592 44.3214C19.8689 44.2527 19.8649 44.1827 19.8475 44.1156C19.8302 44.0484 19.7997 43.9853 19.758 43.9299C19.7162 43.8744 19.6639 43.8278 19.6042 43.7926C19.5444 43.7573 19.4782 43.7342 19.4095 43.7246C9.02423 42.2277 1.04651 33.3057 1.04651 22.5C1.04651 10.6454 10.6454 1.04651 22.5 1.04651C34.3546 1.04651 43.9535 10.6454 43.9535 22.5C43.9535 34.3547 34.3546 43.9535 22.5 43.9535C22.3699 43.966 22.2491 44.0265 22.1612 44.1233C22.0733 44.22 22.0246 44.346 22.0246 44.4767C22.0246 44.6075 22.0733 44.7335 22.1612 44.8302C22.2491 44.927 22.3699 44.9875 22.5 45C34.9202 45 45 34.9202 45 22.5C45 10.0798 34.9202 0 22.5 0ZM33.6028 14.1116C33.4662 14.117 33.337 14.1756 33.2431 14.2751L19.3605 28.5338L13.065 22.0749C12.9673 21.9761 12.8344 21.92 12.6954 21.9189C12.5564 21.9178 12.4226 21.9717 12.3232 22.0689C12.2238 22.1661 12.167 22.2987 12.165 22.4377C12.1631 22.5766 12.2162 22.7107 12.3129 22.8107L18.9844 29.6621C19.0332 29.7125 19.0916 29.7526 19.1562 29.78C19.2208 29.8074 19.2903 29.8215 19.3605 29.8215C19.4306 29.8215 19.5001 29.8074 19.5647 29.78C19.6293 29.7526 19.6878 29.7125 19.7366 29.6621L33.9953 15.0109C34.0722 14.9368 34.1246 14.8409 34.1455 14.7361C34.1663 14.6313 34.1546 14.5227 34.1118 14.4247C34.0691 14.3268 33.9974 14.2443 33.9064 14.1883C33.8154 14.1324 33.7095 14.1056 33.6028 14.1116Z"
        fill="#CB9F91"
      />
    </svg>
  )
}
const ShieldIcon = ({className}) => {
  return (
    <svg width={30} height={35} viewBox="0 0 30 35" fill="none" className={className}>
      <path
        d="M14.9872 34.4593C14.7177 34.4581 14.4504 34.41 14.1974 34.3171C11.9858 33.5272 4.52972 30.3995 1.74949 23.7806C-0.319888 18.852 -0.0987327 10.7799 0.169813 7.54154C0.169813 6.13562 1.54413 5.61433 2.176 5.37738C4.87725 4.31899 11.6541 1.55456 13.913 0.290814C14.2617 0.100007 14.6529 0 15.0504 0C15.4479 0 15.839 0.100007 16.1878 0.290814C18.4941 1.55456 25.1761 4.3032 27.8616 5.36158C28.3755 5.48049 28.8424 5.74988 29.2026 6.13536C29.5627 6.52083 29.7998 7.00489 29.8836 7.52574C30.0731 10.7641 30.2943 18.8362 28.2249 23.7648C25.4447 30.3837 18.036 33.4957 15.8402 34.3013C15.5688 34.4086 15.2791 34.4622 14.9872 34.4593ZM14.9872 1.58615C14.8551 1.58739 14.7252 1.61987 14.6081 1.68093C12.207 2.99206 5.50913 5.72491 2.6973 6.89387C1.86007 7.2098 1.6863 7.43096 1.67051 7.68371C1.49674 10.8431 1.27559 18.5993 3.25019 23.2277C5.77767 29.2463 12.7283 32.1371 14.766 32.8796C14.9286 32.943 15.109 32.943 15.2715 32.8796C17.3251 32.1371 24.2125 29.2463 26.74 23.2277C28.6672 18.5993 28.4619 10.8115 28.3197 7.68371C28.3197 7.43096 28.1301 7.2256 27.3087 6.89387C24.4653 5.7723 17.8306 3.03945 15.4453 1.72832C15.3097 1.63727 15.1505 1.58785 14.9872 1.58615Z"
        fill="#CB9F91"
      />
      <path
        d="M13.4075 21.9007C13.296 21.8905 13.1878 21.8571 13.0898 21.8027C12.9919 21.7483 12.9064 21.6741 12.8388 21.5848L9.67946 17.367C9.55377 17.1994 9.4998 16.9888 9.52943 16.7814C9.55905 16.5741 9.66984 16.3869 9.83742 16.2613C10.0064 16.1396 10.216 16.088 10.4221 16.1175C10.6283 16.1469 10.815 16.2551 10.9432 16.4192L13.5655 19.9261L21.3059 12.6438C21.3793 12.5698 21.4667 12.511 21.5629 12.4709C21.6592 12.4308 21.7624 12.4102 21.8667 12.4102C21.9709 12.4102 22.0742 12.4308 22.1704 12.4709C22.2667 12.511 22.354 12.5698 22.4275 12.6438C22.501 12.7159 22.5595 12.8019 22.5993 12.8968C22.6392 12.9918 22.6598 13.0937 22.6598 13.1967C22.6598 13.2997 22.6392 13.4016 22.5993 13.4965C22.5595 13.5915 22.501 13.6775 22.4275 13.7496L13.992 21.648C13.9168 21.7276 13.8263 21.7911 13.7258 21.8345C13.6253 21.878 13.517 21.9005 13.4075 21.9007Z"
        fill="#CB9F91"
      />
    </svg>
  )
}
const DeleteIcon = ({className}) => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" className={className}>
      <path
        d="M6.333 2.667a.917.917 0 01.917-.917h5.5a.916.916 0 01.917.917v.458h.916v-.917A1.375 1.375 0 0013.208.833H6.792a1.375 1.375 0 00-1.375 1.375v.917h.916v-.458zM18.708 4.042H1.292a.458.458 0 100 .916h1.833v12.834A1.375 1.375 0 004.5 19.167h11a1.375 1.375 0 001.375-1.375V4.958h1.833a.459.459 0 000-.916zM7.25 14.583a.459.459 0 01-.917 0V8.167a.458.458 0 01.917 0v6.416zm3.208.917a.458.458 0 01-.916 0V7.25a.458.458 0 11.916 0v8.25zm3.209-.917a.459.459 0 01-.917 0V8.167a.458.458 0 01.917 0v6.416z"
        fill="#DF0000"
      />
    </svg>
  )
}
const QuestionIcon = () => {
  return (
    <svg width={21} height={21} viewBox="0 0 21 21" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.365 0C4.648 0 0 4.648 0 10.365 0 16.082 4.648 20.73 10.365 20.73c5.717 0 10.365-4.648 10.365-10.365C20.73 4.648 16.082 0 10.365 0zm0 16.65a1.118 1.118 0 01-1.113-1.114c0-.61.502-1.112 1.113-1.112s1.113.502 1.113 1.112c0 .611-.48 1.113-1.113 1.113zm.895-5.39c0-.153.087-.284.196-.327 1.68-.546 2.662-2.204 2.357-3.972-.24-1.396-1.397-2.575-2.815-2.815a3.42 3.42 0 00-2.88.764 3.487 3.487 0 00-1.244 2.684c0 .48.392.873.872.873s.873-.393.873-.873c0-.502.219-1.004.655-1.353a1.78 1.78 0 011.44-.37c.698.13 1.266.697 1.397 1.396.174 1.003-.459 1.745-1.179 1.985-.85.284-1.418 1.091-1.418 2.008v1.113c0 .48.393.873.873.873s.873-.393.873-.873V11.26z"
        fill="#9B9B9B"
      />
    </svg>
  )
}
const LockIcon = () => {
  return (
    <svg width={17} height={25} viewBox="0 0 17 25" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.796 9.862h-.7V5.607A5.612 5.612 0 008.49 0a5.613 5.613 0 00-5.606 5.607v4.255h-.7A2.183 2.183 0 000 12.046v10.127c0 1.205.977 2.183 2.183 2.183h12.614a2.184 2.184 0 002.183-2.183V12.046a2.183 2.183 0 00-2.184-2.184zm-9.95-4.256A3.65 3.65 0 018.49 1.961a3.649 3.649 0 013.644 3.645v4.256H4.845V5.606zm4.761 14.118v-2.726a1.983 1.983 0 10-2.234 0v2.726a1.117 1.117 0 102.234 0z"
        fill="#9B9B9B"
      />
    </svg>
  )
}

export {
  BackIcon,
  LockIcon,
  QuestionIcon,
  CaretRightIcon,
  CheckmarkIcon,
  CloseIcon,
  DeleteIcon,
  ShieldIcon
}
