import React, { PureComponent } from 'react'
import './styles.scss'
import _ from 'lodash'
import Cookies from 'js-cookie'
import { BackIcon, ShieldIcon } from '../../components/icons'
import Loader from '../../components/loader'
import TopNavigation from '../TopNavigation/component'

export default class LostRingComponent extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      orderDetail: {},
      userName: '',
      isLoader: true,
      chatOpen: 0
    }
  }

  componentDidMount = async() => {
    const Id = this.props.location.search
    if(Id !== ''){
      const id_1 = Id.split("&")
      const customerId = id_1[0].split("=")
      const orderId = id_1[1].split("=")
      Cookies.set('customerId',customerId[1])
      Cookies.set('orderId',orderId[1])
      this.props.history.push(`/detail/${orderId[1]}`)
    }
    const data ={
      orderId: Cookies.get('orderId'),
      customerId: Cookies.get('customerId')
    }
    const { value } = await this.props.getOrderDetail(data)
    if(_.get(value,'status',false)){
      this.setState({
        orderDetail: _.get(value, 'data', {}),
        userName: _.get(value, 'userName', {}),
        isLoader: false
      })
    } else{
      this.props.history.push('/no-page-found')
    }
  }

  openChat(){
    const { chatOpen } = this.state
    if(chatOpen === 0){
      window.GorgiasChat.open()
      this.setState({ chatOpen: 1 })
    } else{
      window.GorgiasChat.close()
      this.setState({ chatOpen: 0 })
    }
  }

  render() {
    const { orderDetail, userName, isLoader } = this.state
    return (
      <>
        <div>
        {isLoader ? (
            <Loader />
          ) : (
          <div>
          <TopNavigation userName={userName} orderDetail={orderDetail} {...this.props}/>
          <div className="mini-container-wrapper">
            <div className="mini-container">
              <div className="head-wrap head-wrap-space">
                <div className="head-wrap-back">
                  <button type="button" className="btn btn-sm btn-back" onClick={()=> this.props.history.goBack()}>
                    <BackIcon/>
                  </button>
                </div>
                {/* <div className="head-wrap-title">
                  Ring Lost
                </div> */}
              </div>
              <div className="mini-container-inner">
                <div className="overview-wrap">
                  <ShieldIcon className="overview-icon mt-0"/>
                  <p>Oh no! We're so sorry to hear that. The first step would be to get in touch with your insurance provider. If you have homeowners insurance, most of those policies typically also cover jewelry. Some rental insurance policies may also cover your ring. </p>
                  <div className="chat-with-us">
                    Have questions? <span onClick={this.openChat.bind(this)}>Chat with us</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        )}
        </div>
      </>
    )
  }
}
