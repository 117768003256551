import React, { PureComponent } from 'react'
import { CloseIcon } from '../../components/icons'
// import PropTypes from 'prop-types'
import Cookies from 'js-cookie'

import './styles.scss'

export default class NotPageFoundComponent extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      chatOpen: 0,
    }
  }

  componentDidMount(){
    Cookies.remove('customerId')
    Cookies.remove('orderId')
  }

  openChat(){
    const { chatOpen } = this.state
    if(chatOpen === 0){
      window.GorgiasChat.open()
      this.setState({ chatOpen: 1 })
    } else{
      window.GorgiasChat.close()
      this.setState({ chatOpen: 0 })
    }
  }

  goToAccount(){
    Cookies.remove('customerId')
    Cookies.remove('orderId')
    window.open(`${process.env.REACT_APP_ACCOUNT_URL}`,"_self")
  }

  render() {
    return (
      <div className="wrapper-section pagenotfound__wrapper">
        <div className="wrapper-close">
          <button type="button" className="btn react-modal-close" onClick={this.goToAccount.bind(this)}><CloseIcon/></button>
        </div>
        <div className="not-page-found-section">
          <div className="not-page-found">
            {/*<h1 className="not-page-found-title">Page not found</h1>*/}
            <p className="not-page-found-subtitle"> 
              We're having some trouble locating your order! 
              Not to worry -- our Customer Concierge team can help look this up for you. 
              Please email <a href="mailto:info@ringconcierge.com" className="link">info@ringconcierge.com</a> and a representative will be able to help!
            </p>
           <div className="bottom__link">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="go-back-to-main" onClick={this.openChat.bind(this)}>Chat us</a>
              <a href={process.env.REACT_APP_ACCOUNT_URL} className="go-back-to-main" >Go back to site</a>
           </div>
          </div>
          {/*<div className="not-page-found">
            <p className="not-page-found-subtitle">Unfortunately we cannot find your order.</p>
            <a onClick={this.openChat.bind(this)} href="#" className="go-back-to-main" >Chat with someone from our team</a>
          </div>*/}
        </div>
      </div>
    )
  }
}
