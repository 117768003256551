import React, { PureComponent } from 'react'
import { BackIcon } from '../../components/icons'
import Moment from 'react-moment'
import './styles.scss'
import _ from 'lodash'
import Loader from '../../components/loader'
import TopNavigation from '../TopNavigation/component'
// import axios from 'axios'
import Cookies from 'js-cookie'
import config from '../../config/index'

const RCFJVendor = config.RCFJVendor
const AssociatedItemsKey = config.AssociatedItemsKey
const warrantyExcludeProductId = config.warrantyExcludeProductId
const warrantyExcludeProductTitle = config.warrantyExcludeProductTitle

export default class ProductItemComponent extends PureComponent {

  static propTypes = {
    // PropTypes go here
  }

  constructor(props){
    super(props)
    this.state = {
      orderDetail: {},
      product: {},
      userName: '',
      isLoader: true,
      RCFJItems: []
    }
  }

  componentDidMount = async() => {
    const data = {
      orderId: Cookies.get('orderId'),
      customerId: Cookies.get('customerId'),
      shopifyOrderId:Cookies.get('shopifyOrderId')
    }
    const { value } = await this.props.getOrderDetail(data)
    if(_.get(value,'status',false)){
      this.setState({
        orderDetail: _.get(value, 'data', {}),
        product: _.get(value, 'lItem', {}),
        userName: _.get(value, 'userName', {}),
        isLoader: false
      })
    } else{
      this.props.history.push('/no-page-found')
    }
    // axios.get(`${process.env.REACT_APP_SHOPIFY_API_URL}/pages/get-current-customer`)
    //   .then(function (response) {
    //     console.log(response,'response');
    //   })
    //   .catch(function (error) {
    //     console.log(error,'error');
    //   })
  }

  goToSelectAction(orderType, id){
    const { orderDetail } = this.state
    Cookies.remove('productId')
    Cookies.set('productId',id)
    if(_.get(orderDetail,'orderType','') === 'HTOK' && _.includes([null,undefined,''],id)){
      this.props.history.push(`/htok-label/${_.get(orderDetail, 'order.order_number', '')}`)
    } else {
      this.props.history.push(`/select-action/${_.get(orderDetail, 'order.order_number', '')}`)
    }
  }

  goToHtokLabel(){
    const { orderDetail } = this.state
    if(_.get(orderDetail,'fulfillmentStatus', '') === 'shipped'){
      this.props.history.push(`/htok-label/${_.get(orderDetail, 'order.order_number', '')}`)
    }
  }

  goToAccount(){
    Cookies.remove('customerId')
    Cookies.remove('orderId')
    Cookies.remove('productId')
    window.open(`${process.env.REACT_APP_ACCOUNT_URL}`,"_self")
  }

  render() {
    const { orderDetail, product, userName, isLoader } = this.state
    const isLeapOrder = _.includes(_.get(orderDetail, 'orderTags', []), 'Leap')
    const image = _.filter(_.get(orderDetail, "images", []), filter => {
      return String(filter.id) === String(product.id);
    })
    const title = _.get(product, "name", "").split("/")

    let warrantyDate = ''
    if(orderDetail && _.get(orderDetail,'orderType','') === 'VOW' && product){
      _.map(_.get(orderDetail,'trackings',[]), tracking => {
        if(_.includes(['outbound','shiporder','shipped'],_.get(tracking,'trackingStatus','')) && _.includes(_.get(tracking,'tags',[]), 'VOW')){
          warrantyDate = new Date(_.get(tracking,'createdAt','')) 
        }
      })
      if(warrantyDate === ''){
        _.map(_.get(orderDetail,'order.fulfillments',[]), fulfillment => {
          const vowItem = _.find(_.get(fulfillment,'line_items',[]), item => {
            return String(_.get(item,'id','')) === String(_.get(product,'id',''))
          })
          if(vowItem){
            warrantyDate = new Date(_.get(fulfillment,'created_at',''))
          }
        })
      }
      if(warrantyDate){
        warrantyDate.setFullYear(warrantyDate.getFullYear() + 1)
      }
    }

    let HTOKDisable = true
    if(_.includes(['shipped','delivered'],_.get(orderDetail,'fulfillmentStatus', ''))){
      HTOKDisable = false
    }
    
    let RCFJOrder = []; let rcfjRemoveId = [];

    if(_.get(orderDetail,'orderType','') === 'VOW' || _.get(orderDetail,'orderType','') === 'HTOK'){
      RCFJOrder = _.filter(_.get(orderDetail, 'order.line_items', []), items => {
        return _.includes(RCFJVendor, items.vendor)
      })
    } else if(_.get(orderDetail,'orderType','') === 'RCFJ'){
      RCFJOrder = _.get(orderDetail, 'order.line_items', [])
    }

    let cloneRCFJOrder = RCFJOrder
    _.map(cloneRCFJOrder, (item,i) =>{
      _.map(_.get(item,'properties',[]), properties => {
        if(!_.includes(rcfjRemoveId, item.id) && _.includes(AssociatedItemsKey, _.get(properties,'name',''))){
          rcfjRemoveId.push(item.id)
        }
      })
      if(!_.includes(rcfjRemoveId, item.id) && item.product_id == null){
        rcfjRemoveId.push(item.id)
      }
      if(!_.includes(rcfjRemoveId, item.id) && _.includes(['Custom Stone Count'],_.get(item, 'title',''))){
        rcfjRemoveId.push(item.id)
      }
      if(!_.includes(rcfjRemoveId, item.id) && (_.includes(warrantyExcludeProductTitle, _.get(item, 'title', '')) || _.includes(warrantyExcludeProductId, _.get(item, 'product_id', 0)))){
        rcfjRemoveId.push(item.id)
      }
    })
    RCFJOrder = _.filter(cloneRCFJOrder, item =>{
      return !_.includes(rcfjRemoveId, item.id)
    })
    // if after order update data is not synced with our db disable to perform more operation 
    const isSizeConfirmed = _.get(orderDetail, 'confirmSizeSynced', false)
    return (
      <>
        {isLoader ? (
            <Loader />
          ) : (
          <>
            <TopNavigation userName={userName} orderDetail={orderDetail} {...this.props}/>
            <div className="mini-container-wrapper">
              <div className="mini-container">
                {isSizeConfirmed ? (
                  <div className="alert alert-secondary">
                    Order size confirmation is under progress. Please refresh after some time.  
                  </div>
                ) : (
                  ''
                )}
                <div className="head-wrap head-wrap-space">
                  <div className="head-wrap-back">
                    <button type="button" className="btn btn-sm btn-back" onClick={this.goToAccount.bind(this)}>
                      <BackIcon/>
                    </button>
                  </div>
                  <div className="head-wrap-title">
                    Select the product you need help with?
                  </div>
                </div>
                  {_.get(orderDetail,'orderType','') === 'VOW' ? 
                    <div className="product-item" onClick={this.goToSelectAction.bind(this, _.get(orderDetail,'orderType',''), null)}>
                      <div className="product-thumb">
                        {_.get(image, "[0].image", "/default.webp") !== "" && (
                          <img
                            src={_.get(image, "[0].image", "/default.webp")}
                            alt={""}
                          />
                        )}
                      </div>
                      <div className="product-item-info">
                        <h4 className="diamond-title">{_.get(product,'title','')}</h4>
                        <p className="diamond-color">{_.get(product,'variant_title','')}</p>
                        <p className="diamond-color">{title[1]}</p>
                        {/* warrantyDate ?
                          <div className="warranty-label">
                            Your VOW warranty is active until <Moment format="MMMM D, YYYY">{warrantyDate}</Moment>
                          </div>
                        :'' */}
                        <div className="warranty-label">
                          This product has a lifetime warranty.
                        </div>
                      </div>
                    </div>
                  : ''}
                 {_.get(orderDetail,'orderType','') === 'HTOK' ? 
                  <div className={HTOKDisable === true ? 'product-item disabled' : 'product-item'} onClick={this.goToSelectAction.bind(this, _.get(orderDetail,'orderType',''), null)}>
                    <div className="product-thumb">
                      {_.get(image, "[0].image", "/default.webp") !== "" && (
                        <img
                          src={_.get(image, "[0].image", "/default.webp")}
                          alt={""}
                        />
                      )}
                    </div>
                    <div className="product-item-info">
                      <h4 className="diamond-title">{_.get(product,'title','')}</h4>
                    </div>
                  </div>
                : ''}
                {RCFJOrder.length !== 0 || _.get(orderDetail,'orderType','') === 'RCFJ' ?
                  <div>
                    {RCFJOrder && _.map(RCFJOrder, (item,i) => {
                      let productDetail = _.find(_.get(orderDetail, "products", []), filter => {
                        return String(filter.id) === String(item.id);
                      })
                      let productType = _.get(productDetail,'productType','')
                      let img = _.filter(_.get(orderDetail, "images", []), filter => {
                        return String(filter.id) === String(item.id);
                      })
                      let rcfj_fulfilmentstatus = _.find(_.get(orderDetail, "fulfillmentStatusRCFJ", []), filter => {
                        return String(filter.id) === String(_.get(item, 'id', ''))
                      })
                      let variant_1 = !_.includes([null,undefined,''],_.get(item,'variant_title','')) ? `${_.get(item,'variant_title','')}`.split(' / ') : [] 
                      let showPrice = false
                      _.map(variant_1, list => {
                        if(list.toLowerCase().indexOf('i don\'t know my size') !== -1){
                          showPrice = false
                        }
                      })
                      let RCFJDisabled = false
                      let invoiceDetails = _.get(orderDetail,'invoices',[])
                      
                      let isItemInvoice = false
                      if(_.size(invoiceDetails) > 0 && _.includes(_.get(invoiceDetails[0], 'itemIds', []), String(item.id))){
                        isItemInvoice = true
                      } else if(_.size(invoiceDetails) > 0 && _.includes(_.get(invoiceDetails[0], 'itemIds', []), item.id)){
                        isItemInvoice = true
                      }
                      if(isItemInvoice && !_.get(invoiceDetails[0], 'paid', false)){
                        // RCFJDisabled = true
                      }
                      let warrantyDate = ''
                      let fulfillmentsList = _.filter(_.get(orderDetail, 'order.fulfillments', []), fulfillment => {
                        // for leap order shipment status is null because they fulfill directly from store.
                        return (_.includes(['delivered'], _.get(fulfillment, 'shipment_status', '')) || (_.includes([null], _.get(fulfillment, 'shipment_status', '')) && isLeapOrder ))
                      })
                      _.map(fulfillmentsList, fulfillment => {
                        const fulfillmentLineItems = _.get(fulfillment, 'line_items')
                        let lineItem = _.find(fulfillmentLineItems, v => {
                          return String(v.id) === String(item.id)
                        })
                        if(lineItem){
                          warrantyDate = new Date(_.get(fulfillment, 'updated_at', ''))
                        }
                      })
                      if(warrantyDate === '' && _.get(rcfj_fulfilmentstatus, "fulfillmentStatus", "") === 'delivered'){
                        warrantyDate = _.get(rcfj_fulfilmentstatus, "deliveredDate", "") !== null ? new Date(_.get(rcfj_fulfilmentstatus, "deliveredDate", "")) : ""
                      }
                      const property = _.find(_.get(item,'properties', []), property => {
                        return ['size', 'Size'].includes(property.name) 
                      })
                      // disable buttons to click
                      if (isSizeConfirmed) RCFJDisabled = true
                      // set warranty to 3 years from the date when its delivered
                      warrantyDate =  warrantyDate ? warrantyDate.setDate(warrantyDate.getDate() + 1095) : ''
                      // check order have tag dont give credit
                      // if (
                      //   `${_.get(item, 'variant_title', '')}`.toLowerCase().indexOf('i don\'t know my size') !== -1 &&
                      //   `${_.get(orderDetail, 'orderTags', '')}`.toLowerCase().indexOf('t_give_credit') !== -1 &&
                      //   `${_.get(orderDetail, 'orderTags', '')}`.toLowerCase().indexOf('idkms') !== -1
                      // ) RCFJDisabled = true
                      return(
                        <div className={RCFJDisabled === true ? 'product-item disabled' : 'product-item'} key={i} onClick={this.goToSelectAction.bind(this, 'RCFJ', item.id)}>
                          <div className="product-thumb">
                            {_.get(img, "[0].image", "/default.webp") !== "" && (
                              <img
                                src={_.get(img, "[0].image", "/default.webp")}
                                alt={""}
                              />
                            )}
                          </div>
                          <div className="product-item-info">
                            <h4 className="diamond-title">{_.get(item,'title','')}</h4>
                            <p className="diamond-color">{_.get(item,'variant_title','')}</p>
                            {property ? (
                                <p>
                                    Size:- {_.get(property, 'value', '')}
                                </p>
                              ) : (
                                ''
                              )}
                            {showPrice ?
                              <div className="warranty-label">
                                $30 Round-trip fully insured shipping
                              </div>
                            : '' }
                            {["Wedding Bands", "Mens Rings"].includes(productType) ? <div className="warranty-label">This product has a lifetime warranty.</div> :
                            warrantyDate ?
                              <div className="warranty-label">
                                Your limited warranty is active until <Moment format="MMMM D, YYYY">{warrantyDate}</Moment>
                              </div>
                            : ''}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                : ''}
              </div>
            </div>
          </>
        )}
      </>
    )
  }
}
