import _ from 'lodash'

const getOrderEmail = function (orderDetail){
  const email = _.get(orderDetail, 'order.customer.email', '') ?
    _.get(orderDetail, 'order.customer.email', '') : 
    _.get(orderDetail, 'order.email', '') 
  return email
}

export {
  getOrderEmail
};