 /* eslint-disable react/jsx-no-target-blank */
import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Cookies from 'js-cookie'
import TopNavigation from '../TopNavigation/component'
import { BackIcon } from '../../components/icons'
import Loader from '../../components/loader'
import config from '../../config/index'
import './styles.scss'

const resizingChainCharges = config.resizingChainCharges
const sizeChart = config.sizeChart

export default class RcfjResizeComponent extends PureComponent {

  constructor(props){
    super(props)
    this.state = {
      orderDetail: '',
      userName: '',
      isLoader: true,
      rcfjResize: '',
      RCFJOrder: '',
      quantity: 0,
      originalQty: 0,
      count: true,
      currentRingSize: '',
      newRingSize: '',
      necklace: false,
      quantityDropOption: [],
      actualOptions: []
    }
  }

  async componentDidMount(){
    const checkValidation = Cookies.get('checkValidation')
    if(checkValidation){
      const data ={
        orderId: Cookies.get('orderId'),
        customerId: Cookies.get('customerId')
      }
      let productId = Cookies.get('productId')
      let RCFJOrder = ''; let necklace = false
      let quantityDropOption = []; let itemQunt = ''
      let currentRingSize = ''
      let actualOptions = []
      const { value } = await this.props.getOrderDetail(data)
      if(_.get(value,'status',false)){
        if(!_.includes([null, undefined, 'null', ''], productId)){
          let orderDetail = _.get(value, 'data', {})
          RCFJOrder = _.find(_.get(orderDetail, "order.line_items", []), filter => {
            return String(filter.id) === String(productId);
          })
          let rcfj_fulfilmentstatus = _.find(_.get(orderDetail, "fulfillmentStatusRCFJ", []), filter => {
            return String(filter.id) === String(productId);
          });
          if(rcfj_fulfilmentstatus){
            let quantities = []; let Rejectquant = ''
            quantities = _.find(_.get(rcfj_fulfilmentstatus, "quantities", []), filter => {
              return (filter.type === 'initiatereturn' || filter.type ===  'initiaterepair')
            });
            Rejectquant = _.find(_.get(rcfj_fulfilmentstatus, "quantities", []), filter => {
              return filter.type === 'rejectreturn'
            });
            let perviousQunt = _.get(quantities,'quantity',0)
            if(!_.includes([undefined,null,''], Rejectquant)){
              perviousQunt = perviousQunt + _.get(Rejectquant,'quantity',0)
            }
            itemQunt = RCFJOrder.quantity - perviousQunt
            // itemQunt = perviousQunt ===  RCFJOrder.quantity ? RCFJOrder.quantity : itemQunt
            for (let i = 1; i <= Math.abs(itemQunt); i++) {  quantityDropOption.push(i) }
            // check old repair received
            if (itemQunt === 0 && ['repairreceived'].includes(_.get(rcfj_fulfilmentstatus, 'fulfillmentStatus', ''))) {
              // check the quantity need to repair last time
              const totalQuantity = _.find(_.get(rcfj_fulfilmentstatus, "quantities", []), filter => {
                return filter.type ===  'initiaterepair'
              })
              itemQunt = _.get(totalQuantity, 'quantity', 0)
              for (let i = 1; i <= itemQunt; i++) { quantityDropOption.push(i) }
            }
          } else{
            itemQunt = RCFJOrder.quantity
            for (let i = 1; i <= itemQunt; i++) { quantityDropOption.push(i) }
          }
          let productID = _.get(RCFJOrder,'product_id','')
          const productDetail = await this.props.fetchProductDetails(productID)
          if(_.includes(['Necklaces','Bracelets','Anklets'],_.get(productDetail,'value.data.product.product_type',''))){
            necklace = true
          }
          // let RCFJItems = _.get(value, 'data.RCFJItems', [])
          // let variant_1 = _.get(RCFJOrder,'variant_title','').split(' / ')
          // if(confirmSize === _.get(variant_1,'[1]','') && RCFJItems && RCFJItems.length !== 0){
          //   RCFJOrder = _.get(RCFJItems,'[0]','')
          // }
          let sizeList = []
          let variantOptions = _.get(productDetail,'value.data.product.variants',[])
          _.map(variantOptions, variant =>{
            if( String(_.get(variant,'id','')) === String(_.get(RCFJOrder,'variant_id',''))){
              sizeList.push(
                _.get(variant,'option1',''),
                _.get(variant,'option2',''),
                _.get(variant,'option3','')
              )
            }
          })
          if(_.includes(['Rings'],_.get(productDetail,'value.data.product.product_type','')) && sizeList && sizeList.length){
            _.map(sizeList, option=>{
              if(!isNaN(option) && Number(parseFloat(option))){
                currentRingSize = option
              }
            })
          }
          actualOptions = _.find(_.get(productDetail,'value.data.product.options',[]), option => _.get(option,'name','') === 'Size' )
        }
        this.setState({ 
          orderDetail: _.get(value, 'data', {}), 
          userName: _.get(value, 'userName', ''), 
          RCFJOrder,
          productId,
          quantity: itemQunt,
          originalQty: itemQunt,
          quantityDropOption,
          isLoader: false, 
          necklace,
          currentRingSize,
          actualOptions: _.get(actualOptions,'values',[])
        })
      } else{
        this.props.history.push('/no-page-found')
      }
    } else{
      this.props.history.push('/no-page-found')
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.location.state && this.state.count){
      this.setState({
        rcfjResize : _.get(prevProps,'location.state.detail.rcfjResize',''),
        quantity : _.get(prevProps,'location.state.detail.quantity',0),
        originalQty : _.get(prevProps,'location.state.detail.originalQty',0),
        RCFJOrder : _.get(prevProps,'location.state.detail.RCFJOrder',''),
        currentRingSize : _.get(prevProps,'location.state.detail.currentRingSize',''),
        newRingSize : _.get(prevProps,'location.state.detail.newRingSize',''),
        necklace : _.get(prevProps,'location.state.detail.necklace',''),
        count:false
      })
    }
  }

  selectResize(value){
    if(value === 'I don’t know my size'){
      // Cookies.remove('customerId')
      // Cookies.remove('orderId')
      // Cookies.remove('productId')
      this.setState({ rcfjResize: '' })
      window.open(`${process.env.REACT_APP_I_DONT_KNOW_SIZE_URL}`,"_newtab")
    } else{
      this.setState({ rcfjResize: value })
    }
  }

  handleOnChange(name, e){
    this.setState({ [name]: e.target.value })
    if(name === 'currentRingSize'){
      this.setState({ newRingSize: '' })
    }
  }

  continueWithRequest(){
    const { orderDetail, rcfjResize, } = this.state
    const data = {
      rcfjResize: rcfjResize,
      quantity: this.state.quantity,
      originalQty: this.state.originalQty,
      RCFJOrder: this.state.RCFJOrder,
      currentRingSize: this.state.currentRingSize,
      newRingSize: this.state.newRingSize,
      necklace: this.state.necklace
    }
    this.props.history.push({
      pathname : `/rcfj-resize/overview/${_.get(orderDetail, 'order.order_number', '')}`,
      state : { detail : data }
    })
  }

  render() {
    const { orderDetail, userName, isLoader, RCFJOrder, productId, quantityDropOption } = this.state
    let image = []
    if(productId){
      image = _.filter(_.get(orderDetail, "images", []), filter => {
        return String(filter.id) === String(productId);
      })
    }
    let currentSize = parseFloat(this.state.currentRingSize)
    let RingSizeOption = []
    _.map(sizeChart, size => {
      RingSizeOption.push(currentSize + size)
    })
    return (
      <>
        {isLoader ? (
          <Loader />
        ) : (
          <>
            <TopNavigation userName={userName} orderDetail={orderDetail} {...this.props}/>
            <div className="mini-container-wrapper">
              <div className="mini-container">
                <div className="head-wrap head-wrap-space">
                  <div className="head-wrap-back">
                    <button type="button" className="btn btn-sm btn-back">
                      <Link to={`/select-action/${_.get(orderDetail, 'order.order_number', '')}`}>
                        <BackIcon/>
                      </Link>
                    </button>
                  </div>
                  <div className="head-wrap-title">
                    Resize
                  </div>
                </div>
                <div className="horizontal-product">
                  <div className="product-thumbnail">
                    {_.get(image, "[0].image", "/default.webp") !== "" && (
                      <img
                        src={_.get(image, "[0].image", "/default.webp")}
                        alt={""}
                      />
                    )}
                  </div>
                  <div>
                    <h4 className="product-name">{_.get(RCFJOrder,'title','')}</h4>
                    <p className="product-shape-color">{_.get(RCFJOrder,'variant_title','')}</p>
                  </div>
                </div>
                {this.state.necklace ? 
                  <div className="mini-container-inner">
                    <div className="form-group">
                      <label className="form-label">Select your Adjustment:</label>
                      <select
                        type="select"
                        className="form-control custom-select"
                        name="newRingSize"
                        value={this.state.newRingSize}
                        onChange={this.handleOnChange.bind(this,'newRingSize')}
                      >
                        <option value="">Select</option>
                        {_.map(resizingChainCharges, (option,i) => {
                          return(
                            <option key={i} value={option.value} >{option.key}</option>
                          )
                        })}
                      </select>
                    </div>
                    {RCFJOrder !== '' && this.state.originalQty !== 1 ? 
                      <div className="form-group">
                        <label className="form-label">Select your Quantity:</label>
                        <select
                          type="select"
                          className="form-control custom-select"
                          name="quantity"
                          value={this.state.quantity}
                          onChange={this.handleOnChange.bind(this, 'quantity')}
                        >
                          {_.map(quantityDropOption, (option,i) => {
                            return(
                                <option key={i} value={option} >{option}</option>
                              )
                          })
                          }
                        </select>
                      </div>
                    :''}
                    <div className="form-group">
                      <div className="note-text">
                        Please note:  In order to maintain the integrity of each piece, rings can only be resized once. If you are unsure of your ring size, please purchase our ring sizer <a href={process.env.REACT_APP_I_DONT_KNOW_SIZE_URL} className="link">here</a>. Depending on the style of ring, there may be limitations on how much the piece can be sized. <br/><br/>
                        If we are unable to complete the service requested, our team will be in touch and will ship your piece back to you and refund you the cost of your resize less shipping. <br/><br/>

                      </div>
                    </div>
                    <button
                      disabled={
                        this.state.newRingSize !== '' ? false : true
                      }
                      type="button"
                      className="btn btn-dark w-100"
                      onClick={this.continueWithRequest.bind(this)}
                    >
                      Continue with  REQUEST
                    </button>
                  </div>
                :
                  <div className="mini-container-inner">
                    {/* <h5 className="section-title">Why would you like to Return your ring?</h5> */}
                    <ul className="select-options-list">
                      <li>
                        <label className="custom-checkbox custom-redio">
                          I don’t know my size
                          <input
                            type="checkbox"
                            className="custom-checkbox--input"
                            name="rcfjResize"
                            onChange={this.selectResize.bind(this, 'I don’t know my size')}
                            checked={false}
                            required
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="custom-checkbox custom-redio">
                          I know the size I need
                          <input
                            type="checkbox"
                            className="custom-checkbox--input"
                            name="rcfjResize"
                            value={this.state.rcfjResize}
                            checked={'I know the size I need' === this.state.rcfjResize ? true : false}
                            onChange={this.selectResize.bind(this, 'I know the size I need')}
                            required
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                    <div className="current-size">Current size: {currentSize}</div>
                    {this.state.rcfjResize === 'I know the size I need' ?
                      <div>
                        <div className="form-group">
                          <label className="form-label">Select your New Size:</label>
                          <select
                            type="select"
                            className="form-control custom-select"
                            name="newRingSize"
                            value={this.state.newRingSize}
                            onChange={this.handleOnChange.bind(this,'newRingSize')}
                          >
                            <option value="">Select</option>
                            {_.map(RingSizeOption, (option,i) => {
                              // if(_.includes(actualOptions, String(option)) ){
                                return(
                                  <option key={i} value={option} >{option}</option>
                                )
                              // }
                            })}
                          </select>
                        </div>
                        {RCFJOrder !== '' && this.state.originalQty > 1 ? 
                          <div className="form-group">
                            <label className="form-label">Select your Quantity:</label>
                            <select
                              type="select"
                              className="form-control custom-select"
                              name="quantity"
                              value={this.state.quantity}
                              onChange={this.handleOnChange.bind(this, 'quantity')}
                            >
                              {_.map(quantityDropOption, (option,i) => {
                                return(
                                    <option key={i} value={option} >{option}</option>
                                  )
                              })
                              }
                            </select>
                          </div>
                        :''}
                      </div>
                    :''}
                    <div className="form-group">
                      <div className="note-text">
                        Please note:  In order to maintain the integrity of each piece, rings can only be resized once. If you are unsure of your ring size, please purchase our ring sizer <a href={process.env.REACT_APP_I_DONT_KNOW_SIZE_URL} className="link" target="_blank">here</a>. Depending on the style of ring, there may be limitations on how much the piece can be sized. <br/><br/>
                        If we are unable to complete the service requested, our team will be in touch and will ship your piece back to you and refund you the cost of your resize less shipping. <br/><br/>

                      </div>
                    </div>
                    <button
                      disabled={
                        this.state.rcfjResize !== '' &&
                        this.state.newRingSize !== '' && 
                        this.state.quantity > 0 ? false : true
                      }
                      type="button"
                      className="btn btn-dark w-100"
                      onClick={this.continueWithRequest.bind(this)}
                    >
                      Continue with  REQUEST
                    </button>
                  </div>
                }
              </div>
            </div>
          </>
        )}
      </>
    )
  }
}
