import { connect } from 'react-redux'
import { getOrderDetail, fetchProductDetails } from "../../store/order/duck"
import RcfjResizeComponent from './component'

const RcfjResizeContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
  	getOrderDetail,
  	fetchProductDetails
  }
)(RcfjResizeComponent)

export default RcfjResizeContainer
