import 'rxjs'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { reducer as formReducer } from 'redux-form'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import { createEpicMiddleware, combineEpics } from 'redux-observable'
import app from './app'
// import order from './company/duck'

// Bundling Epics
const rootEpic = combineEpics()

// Creating Bundled Epic
const epicMiddleware = createEpicMiddleware()

// Define Middleware
// const middleware = [thunk, promise, epicMiddleware]

// Define Reducers
const reducers = combineReducers({
  app,
  form: formReducer
})

// Create Store
const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  applyMiddleware(thunk, promise, epicMiddleware)
)
epicMiddleware.run(rootEpic)
export default store
