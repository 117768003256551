import { connect } from 'react-redux'
import { login } from '../../store/order/duck'
import LoginComponent from './component'

const LoginContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
  	login
  }
)(LoginComponent)

export default LoginContainer
