export default {
	Repair:{
		'needadifferentringsize': 'Need a different ring size',
		'ringismisshapenorbent': 'Ring is misshapen or bent',
		'diamondisloose': 'Diamond is loose',
		'missingaccentdiamond': 'Missing accent diamond',
		'missingcenterdiamond': 'Missing center diamond',
		'missingmeleediamondsfromthehalo': 'Missing melee diamonds from the halo',
		'missingmeleediamondsfromtheband': 'Missing melee diamonds from the band',
		'other': 'Other'
	},
	Exchange:{
		'iwantanewsetting': 'I want a new setting',
		'iwantanewdiamond': 'I want a new diamond',
		'iwantanewdesignandnewdiamond': 'I want a new design and new diamond',
		'ineedadifferentringsize': 'I need a different ring size',
		'other': 'Other'
	},
	Return:{
		'idon’tlovetheringdesign': 'I don’t love the ring design',
		'idon’tlovethediamond': 'I don’t love the diamond',
		'iboughtaringelsewhere': 'I bought a ring else where',
		'inolongerneedaring': 'I no longer need a ring',
		'ineedadifferentringsize': 'I need a different ring size'
		// 'other': 'Other'
	},
	RingSize:[
   	'3',
   	'3.25',
   	'3.5',
   	'3.75',
   	'4',
   	'4.25',
   	'4.5',
   	'4.75',
   	'5',
    '5.25',
    '5.5',
    '5.75',
    '6',
    '6.25',
    '6.5',
    '6.75',
    '7',
    '7.25',
    '7.5',
    '7.75',
    '8',
    '8.25',
    '8.5',
    '8.75',
    '9',
    '9.25',
    '9.5',
    '9.75',
    '10',
		'10.25',
    '10.5',
    '10.75',
    '11',
	],
  repairCharges:[
    {"key":"Need a different ring size","charge":0, "charge1":125, "value": 0},
    {"key":"Missing accent diamond","charge":0, "charge1":100, "value": 0},
    {"key":"Missing side stone","charge":250, "charge1":0, "value": 0},
    {"key":"Ring is misshapen or bent","charge":100, "charge1":0, "value": 0},
    {"key":"Damaged prongs","charge":100, "charge1":0, "value": 0},
    {"key":"Polishing","charge":100, "charge1":0, "value": 0},
    {"key":"Refinishing","charge":100, "charge1":0},
    {"key":"Diamond is loose","charge":0, "charge1":100, "value": 0},
    {"key":"Loose diamond in band","charge":100, "charge1":0, "value": 0}
  ],
  RCFJRepairOption: [
    {value:'Ring misshapen or bent', key:'ring-misshapen-or-bent', isChecked:false, disabled:false},
    {value:'Missing pavé stone (diamond or gemstone)', key:'missing-pave-stone', isChecked:false, disabled:false},
    {value:'Missing diamond or gemstone', key:'missing-diamond-or-gemstone', isChecked:false, disabled:false},
    {value:'Stone is loose, tigten prongs', key:'stone-is-loose-tigten-prongs', isChecked:false, disabled:false},
    {value:'Polish (minor scratches)', key:'polish-minor-scratches', isChecked:false, disabled:false},
    // {value:'Damaged chain', key:'damaged-chain', isChecked:false, disabled:false},
    {value:'Broken chain/clasp', key:'broken-clasp', isChecked:false, disabled:false},
    {value:'Other', key:'other', isChecked:false, disabled:false}
  ],
  RCFJRepairCharges: [
    {key:'Ring misshapen or bent', charge:30, value:0},
    {key:'Missing pavé stone (diamond or gemstone)', charge:30, value:0},
    {key:'Missing diamond or gemstone', charge:50, value:0},
    {key:'Stone is loose, tigten prongs', charge:20, value:0},
    {key:'Polish (minor scratches)',charge:15, value:0 },
    // {key:'Damaged chain',charge:15, value:0 },
    {key: 'Broken chain/clasp', charge: 40, value: 0 }
  ],
  resizingRingCharges:[
    {"key":"Up ¼ size","charge":'45' },
    {"key":"Up ½ size","charge":'45' },
    {"key":"Up ¼ size","charge":'45' },
    {"key":"Up full size","charge":'45' },
    {"key":"Down ¼ size","charge":'45' },
    {"key":"Down ½ size","charge":'45' },
    {"key":"Down ¼ size","charge":'45' },
    {"key":"Down full size","charge":'45' }
  ],
  // resizingChainCharges:[
  //   {"key":"Add ½ inch","charge":'12.50' },
  //   {"key":"Add full inch","charge":'25' },
  //   {"key":"Remove ½ inch","charge":'12.50' },
  //   {"key":"Remove full inch","charge":'25' }
  // ],
  // resizingChainCharges:[
  //   {"key":"Add 1.5 - $12.50", "value":"Add 1.5", "charge":12.50 },
  //   {"key":"Reduce 1.5 - $25.00", "value":"Reduce 1.5", "charge":25 },
  //   {"key":"Add 2 - $12.50", "value":"Add 2", "charge":12.50 },
  //   {"key":"Reduce 2 - $25.00", "value":"Reduce 2", "charge":25 }
  // ],
  resizingChainCharges:[
    {"key":"Add ½ inch - $35", "value":"Add ½ inch", "charge":35 },
    {"key":"Add 1 inch - $40", "value":"Add 1 inch", "charge":40 },
    {"key":"Add 1 ½ inches - $45", "value":"Add 1 ½ inches", "charge":45 },
    {"key":"Add 2 inches - $50", "value":"Add 2 inches", "charge":50 },
    {"key":"Remove ½ inch - $20", "value":"Remove ½ inch", "charge":20 },
    {"key":"Remove 1 inch - $20", "value":"Remove 1 inch", "charge":20 },
    {"key":"Remove 1 ½ inches - $20", "value":"Remove 1 1/2 inches", "charge":20 },
    {"key":"Remove 2 inches - $20", "value":"Remove 2 inches", "charge":20 },
  ],
  resizingAndRepairRingOptions: [
    { key: 'Up ¼ size', value: 'upQuarterSize', upQuarterCharge: 45 },
    { key: 'Up ½ size', value: 'upHalfSize', upHalfSizeCharge: 45 },
    { key: 'Up ¼ size', value: 'upQuarterSize', upQuarterCharge: 45 },
    { key: 'Up full size', value: 'upFullSize', upFullSizeCharge: 45 },
    { key: 'Down ¼ size', value: 'downQuarterSize', upQuarterCharge: 45 },
    { key: 'Down ½ size', value: 'downHalfSize', downHalfSizeCharge: 45 },
    { key: 'Down ¼ size', value: 'downQuarterSize', upQuarterCharge: 45 },
    { key: 'Down full size', value: 'downFullSize', downFullSizeCharge: 45 },
  ],
  resizingAndRepairChainOptions: [
    { key: 'Add ½ inch', value: 'addHalfInch', charge: 12.5 },
    { key: 'Add full inch', value: 'addFullInch', charge: 25 },
    { key: 'Remove ½ inch', value: 'removeHalfInch', charge: 12.5 },
    { key: 'Remove full inch', value: 'removeFullInch', charge: 25 },
  ],
  HowManyMissing:[
    '1','2','3','4','5','6','7','8','9','10',
    '11','12','13','14','15','16','17','18','19','20'
  ],
  confirmSize:"I don't know my size; send me a Ring Sizing Gauge!",
  RCFJVendor: [
    'Ring Concierge',
    'Kelly Bello',
    'Ring Concierge Fine Jewelry',
    'Ring Concierge Mens'
  ],
  RingSizerSku:[
    'RC-RINGSIZINGGAUGE',
    'idkmysize-ringsizer',
    'vow-ringsizer'
  ],
  AssociatedItemsKey: [
    'associated_variant_id',
    '_associated_variant_id',
    '_sccl_associated_variant_id',
    'complimentary_associated_variant_id',
    '_complimentary_associated_variant_id',
    '_stone_associated_variant_id',
    'stone_associated_variant_id',
    '_engrave_associated_var_id'
  ],
  sizeChart: [ -2, -1.75, -1.5, -1.25, -1, -0.75, -0.5, -0.25, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2 ],
  warrantyExcludeProductTitle: [
    "Gift Wrap",
    "Gift Wrap",
    "Gift Wrapping",
    "Silk Mask + 14k Gold-Plated Mask Chain",
    "Custom Stone Count",
    "Item Customization",
    "Custom Chain Length",
    "Custom Engraving",
    "Ring Concierge Foam Jewelry Cleaner",
    "Natural Jewelry Cleaner",
    "Ring Sizer - I Don't Know Size",
    "Vow Ring Sizer",
    "Ring Sizer",
    "Ring Sizer",
    "CCL3",
    "CCL2",
    "CCL1"
  ],
  warrantyExcludeProductId: [
    4831832277080,
    421444714536,
    4847977332824,
    6681162514520,
    4906792484952,
    4836725686360,
    4741923635288,
    2076127297624,
    4870618415192,
    4757201551448,
    4736249233496,
    6543572205656,
    6543571255384,
    4925668786264,
    8083500998,
    9815397702,
    8813347014
  ]
}
