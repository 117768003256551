import React, { PureComponent } from 'react'
import { CheckmarkIcon } from '../../../components/icons'
import _ from 'lodash' 
import Loader from '../../../components/loader'
import TopNavigation from '../../TopNavigation/component'
import Cookies from 'js-cookie'

export default class ReturnOverviewComponent extends PureComponent {

  static propTypes = {
    // PropTypes go here
  }

  constructor(props){
    super(props)
    this.state = {
      orderDetail: {},
      userName: '',
      isLoader: true
    }
  }

  async componentDidMount(){
    const data ={
      orderId: Cookies.get('orderId'),
      customerId: Cookies.get('customerId')
    }
    const { value } = await this.props.getOrderDetail(data)
    if(_.get(value,'status',false)){
      this.setState({
        orderDetail: _.get(value, 'data', {}),
        userName: _.get(value, 'userName', {}),
        isLoader: false
      })
    } else{
      this.props.history.push('/no-page-found')
    }
  }

  render() {
    const { orderDetail, userName, isLoader } = this.state
    return (
      <>
      <div>
      {isLoader ? (
          <Loader />
        ) : (
        <div>
      <TopNavigation userName={userName} orderDetail={orderDetail} {...this.props}/>
      <div className="mini-container-wrapper">
        <div className="mini-container">
          <div className="head-wrap head-wrap-space">
            <div className="head-wrap-title">
              Return Request
            </div>
          </div>
          <div className="mini-container-inner">
            <div className="overview-wrap">
              <h4>Your Return Request has been submitted successfully!</h4>
              <CheckmarkIcon className="overview-icon"/>
              <p>A customer service representative will reach out with next steps within the next 24 hours.</p>
            </div>
          </div>
        </div>
      </div>
      </div>
      )}
      </div>
      </>
    )
  }
}
