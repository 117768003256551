import React from 'react'

class Loader extends React.Component {
  render() {
    return (
      <div className="loader__section">
        <div className="loader"  aria-label="Loading"></div>
      </div>
    )
  }
}
export default Loader
