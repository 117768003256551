import { connect } from 'react-redux'
import { getOrderDetail } from "../../../store/order/duck"
import ExchangeConfirmationComponent from './component'

const ExchangeConfirmationContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
  	getOrderDetail
  }
)(ExchangeConfirmationComponent)

export default ExchangeConfirmationContainer
