import React, { PureComponent } from 'react'
import { CloseIcon } from '../../components/icons'
// import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import Cookies from 'js-cookie'
import './styles.scss'
import _ from 'lodash'

export default class TopNavigationComponent extends PureComponent {

  static propTypes = {
    // PropTypes go here
  }

  componentDidMount(){
    const orderDetail = this.props.orderDetail
    document.title = `${_.get(orderDetail,'order.order_number', )} | Help`
  }

  constructor(props){
    super(props)
    this.state = {
    }
  }

  closeWindow(){
    Cookies.remove('customerId')
    Cookies.remove('orderId')
    window.open(`${process.env.REACT_APP_ACCOUNT_URL}`,"_self")
  }

  render() {
    const orderDetail = this.props.orderDetail
    return (
      <>
        <header className="head-section">
          <div className="head-section-title">
            {this.props.userName} | Order {_.get(orderDetail,'order.order_number', )} | <Moment format="MMMM D, YYYY">{_.get(orderDetail,'order.created_at', )}</Moment>
          </div>
          <div className="head-section-close">
            <button className="btn btn-sm btn-close" onClick={this.closeWindow.bind(this)}>
              <CloseIcon className="close-icon"/>
            </button>
          </div>
        </header>
      </>
    )
  }
}
