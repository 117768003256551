import { connect } from 'react-redux'
import { getOrderDetail } from "../../store/order/duck"
import ExchangeComponent from './component'

const ExchangeContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
  	getOrderDetail
  }
)(ExchangeComponent)

export default ExchangeContainer
