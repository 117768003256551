import { connect } from 'react-redux'
import { getOrderDetail } from "../../store/order/duck"
import LostRingComponent from './component'

const LostRingContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
  	getOrderDetail
  }
)(LostRingComponent)

export default LostRingContainer
