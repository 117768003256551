import { connect } from 'react-redux'
import { getOrderDetail, getPrintSlipShip, getMergeSlipLabel } from "../../../store/order/duck"
import ResizeConfirmationComponent from './component'

const ResizeConfirmationContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
  	getOrderDetail,
  	getPrintSlipShip,
  	getMergeSlipLabel
  }
)(ResizeConfirmationComponent)

export default ResizeConfirmationContainer
