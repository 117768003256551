import { connect } from 'react-redux'
import { getOrderDetail, repairRequest, generateId, updateShippingAddress, calculaterefund } from "../../../store/order/duck"
import ExchangeOverviewComponent from './component'

const ExchangeOverviewContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
  	getOrderDetail,
  	repairRequest,
  	generateId,
  	updateShippingAddress,
  	calculaterefund
  }
)(ExchangeOverviewComponent)

export default ExchangeOverviewContainer
