import { connect } from 'react-redux'
import { getOrderDetail } from "../../store/order/duck"
import ProductItemComponent from './component'

const ProductItemContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
  	getOrderDetail
  }
)(ProductItemComponent)

export default ProductItemContainer
