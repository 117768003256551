import { connect } from 'react-redux'
import { getOrderDetail, checkAddressValidation, updateShippingAddress, generateId, repairRequest, addPaymentMethod, addPayment, calculateTax } from "../../../store/order/duck"
import ResizeOverviewComponent from './component'

const ResizeOverviewContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
  	getOrderDetail,
  	updateShippingAddress,
  	generateId,
  	repairRequest,
  	addPaymentMethod,
  	addPayment,
    calculateTax,
    checkAddressValidation
  }
)(ResizeOverviewComponent)

export default ResizeOverviewContainer
