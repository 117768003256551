import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { CookiesProvider } from "react-cookie";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker';
import store from './store';

const history = createBrowserHistory();


ReactDOM.render(
  <Provider store={store} >
    <CookiesProvider>
      <Router history={history}>
        <App />
      </Router>
    </CookiesProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
